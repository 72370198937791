import request from '@/utils/request'

const url = '/c/colorBookBrand';

// 获取信息
export function colorBookBrandListApi() {
  return request({
    url: `${url}/brandList`,
    method: 'get'
  })
}

// 获取列表
export function queryColorBookBrandApi(data) {
  if (!data) {
    data = { pageSize: 100000 }
  }
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

// 获取信息
export function getColorBookBrandApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get',
  })
}

// 添加
export function addOrUpdateColorBookBrandApi(data) {
  return request({
    url: `${url}/addOrUpdate`,
    method: 'put',
    data
  })
}


// 删除
export function deleteColorBookBrandApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete',
  })
}

