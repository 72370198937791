<template>
  <div class="basic-component">
    <div class="wrapper-top">
      <img v-if="logoImg" :src="logoImg" />
      <div>
        <p>您好，欢迎使用ColorX</p>
        <p>我们致力于为喷涂商、粉末涂料商提供全流程解决方案</p>
      </div>
    </div>
    <div class="wrapper-bottom" v-if="true">
      <div v-if="isInfoAuth('quickSearch')">
        <router-link :to="{ path: '/search/product' }">
          <img src="../../../../assets/index/1.png" />
        </router-link>
      </div>
      <div v-if="isInfoAuth('productLibrary')">
        <router-link :to="{ path: '/product' }"
          ><img src="../../../../assets/index/2.png"
        /></router-link>
      </div>
      <div class="add-per" v-if="isInfoAuth('addProduct')">
        <!-- 生产产品库 -->
        <!-- <router-link :to="{ path: '/product' , query:{'params':'proAdd'}}"><img src="../../../../assets/index/3.png" /></router-link> -->
        <a href="javascirpt:void(0)">
          <img src="../../../../assets/index/3.png" />
          <!-- <img class="mask" src="../../../../assets/index/add-per-bg.png" /> -->
          <div class="mask">
            <router-link
              :to="{ path: '/product', query: { params: 'proAdd' } }"
            >
              <el-button>添加生产产品</el-button>
            </router-link>
            <router-link
              :to="{ path: '/productorder', query: { params: 'proAdd' } }"
            >
              <el-button>添加实验产品</el-button>
            </router-link>
          </div>
        </a>
      </div>
      <div v-if="isInfoAuth('attributeManage')">
        <!-- 色彩比较 -->
        <router-link :to="{ path: '/colorcompre' }"
          ><img src="../../../../assets/index/4.png"
        /></router-link>
      </div>
      <template v-if="list.length <= 4">
        <div v-if="isInfoAuth('addAttribute')">
          <!-- 新增添加属性值 -->
          <router-link
            :to="{ path: '/productattrvalue', query: { params: 'valAdd' } }"
            ><img src="../../../../assets/index/7.png"
          /></router-link>
        </div>
        <div v-if="isInfoAuth('addProductProduct')">
          <!-- 添加生产产品 -->
          <router-link :to="{ path: '/product', query: { params: 'proAdd' } }"
            ><img src="../../../../assets/index/8.png"
          /></router-link>
        </div>
        <div v-if="isInfoAuth('addProductOrder')">
          <!-- 添加实验产品 -->
          <router-link
            :to="{ path: '/productorder', query: { params: 'proAdd' } }"
            ><img src="../../../../assets/index/9.png"
          /></router-link>
        </div>
        <div v-if="isInfoAuth('addEmployee')" style="position: relative">
          <!-- 添加员工 -->
          <router-link :to="{ path: '/person', query: { params: 'proPer' } }">
            <img src="../../../../assets/index/5.png" />
          </router-link>
        </div>
        <div v-if="isInfoAuth('materialLibrary')">
          <!-- 材料库 -->
          <router-link :to="{ path: '/material' }"
            ><img src="../../../../assets/index/6.png"
          /></router-link>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
// /导航类型(addEmployee 添加员工,quickSearch快捷搜索  ,productLibrary 产品库 ,materialLibrary 材料库 ,addProduct 添加产品,attributeManage 属性值管理)
import { getNavigationListApi } from '@/api/modules/resource'
export default {
  computed: {
    logoImg() {
      const brandLogo = localStorage.getItem('brandLogo')
      if (brandLogo === 'null') {
        return null
      }
      return brandLogo
    },
    isInfoAuth(key) {
      return (key) => (this.list || '[]').indexOf(key) !== -1 || false
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.selectNavigationList()
  },
  methods: {
    async selectNavigationList() {
      this.list = []
      const response = await getNavigationListApi()
      if (response && response.length > 0) {
        this.list = response
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-top {
  height: 112px;
  background: #fff;
  background-image: url('../../../../assets/index/bg.png');
  background-position-x: right;
  background-repeat: no-repeat;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 29px;
    margin: 0 20px 0 30px;
    // margin-right: 100px;
  }
  div {
    padding-left: 30px;
    flex: 1;
    p {
      &:nth-child(1) {
        color: #333333;
        font-weight: bold;
        font-size: 20px;
      }
      &:nth-child(2) {
        color: #999999;
        font-size: 14px;
      }
    }
  }
}
.wrapper-bottom {
  height: 142px;
  display: flex;
  // justify-content: space-around;
  justify-content: space-between;
  margin-top: 22px;
  div {
    height: 142px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
}
.add-per {
  > a {
    position: relative;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    border-radius: 13px;
    // display: block;
    transition: all 0.3;
    bottom: 4px;
    opacity: 0;
    button {
      width: 128px;
      height: 40px;
      background: #07cadc;
      opacity: 1;
      border-radius: 3px;
      border: 0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 8px;
    }
  }
  &:hover {
    .mask {
      // display: block;
      opacity: 1;
    }
  }
}
</style>
