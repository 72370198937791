<template>
  <div @click="handleTabs($event)">
    <span
      v-for="item in list"
      :id="item.id"
      :class="id === item.id ? 'active' : ''"
      :key="item.id"
      >{{ item.factoryName }}</span
    >
  </div>
</template>

<script>
import { getFactoryListApi } from '@/api/modules/factory'
export default {
  data() {
    return {
      id: null,
      list: []
    }
  },
  mounted() {
    this.selectFactoryList()
  },
  methods: {
    handleTabs(e) {
      if (!e.target.id) {
        return false
      }
      this.id = e.target.id
      this.$emit('selectfactoryId', e.target.id)
    },
    async selectFactoryList() {
      const response = await getFactoryListApi()
      if (response && response.length > 0) {
        this.list = response
        this.id = response[0].id
        this.$emit('selectfactoryId', this.id)
      } else {
        this.list = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  span {
    font-size: 14px;
    color: #000;
    border: 1px solid #d9d9d9;
    height: 32px;
    line-height: 32px;
    display: block;
    padding: 0 10px;
    cursor: pointer;
    transition: all 0.5;
    &.active {
      border: 1px solid #1890ff;
      box-shadow: 0px 0px 6px rgba(24, 144, 255, 0.23);
      opacity: 1;
      border-radius: 1px 0px 0px 1px;
      font-weight: 500;
      color: #1890ff;
    }
  }
}
</style>
