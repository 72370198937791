<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="attributeName" label="属性名称">
        <el-input
          v-model="dataForm.attributeName"
          placeholder="属性名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="isListShow" label="列表展示">
        <el-select
          v-model="dataForm.isListShow"
          size="small"
          placeholder="列表展示"
          style="width: 120px"
          clearable
        >
          <el-option label="是" :value="true" size="small"> </el-option>
          <el-option label="否" :value="false" size="small"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="isSearch" label="支持搜索">
        <el-select
          v-model="dataForm.isSearch"
          size="small"
          placeholder="支持搜索"
          style="width: 120px"
          clearable
        >
          <el-option label="是" :value="true" size="small"> </el-option>
          <el-option label="否" :value="false" size="small"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="isAppletSearch" label="支持小程序搜索">
        <el-select
          v-model="dataForm.isAppletSearch"
          size="small"
          placeholder="支持搜索"
          style="width: 120px"
          clearable
        >
          <el-option label="是" :value="true" size="small"> </el-option>
          <el-option label="否" :value="false" size="small"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="isEnable" label="状态">
        <el-select
          v-model="dataForm.isEnable"
          size="small"
          placeholder="状态"
          style="width: 120px"
          clearable
        >
          <el-option label="启用" :value="true" size="small"> </el-option>
          <el-option label="禁用" :value="false" size="small"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small" type="info"
          >清空</el-button
        >
        <el-button
          v-if="isAuth('productAttribute:add')"
          type="primary"
          @click="addOrUpdateHandle()"
          size="small"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="attributeName"
        header-align="center"
        align="center"
        label="属性名称"
      >
      </el-table-column>
      <el-table-column
        prop="sortNumber"
        header-align="center"
        align="center"
        label="当前排序"
      >
      </el-table-column>
      <el-table-column
        prop="isValuation"
        header-align="center"
        align="center"
        label="是否计价"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isValuation" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="列表展示"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isShow" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isSearch"
        header-align="center"
        align="center"
        label="支持搜索"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isSearch" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isAppletSearch"
        header-align="center"
        align="center"
        label="支持小程序搜索"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isAppletSearch" type="success">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="cascadeLevel"
        header-align="center"
        align="center"
        label="级联级别"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.cascadeLevel == 1">一级</p>
          <p v-else-if="scope.row.cascadeLevel == 2">二级</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="relationattributeName"
        header-align="center"
        align="center"
        label="已关联属性"
      >
      </el-table-column>
      <el-table-column
        prop="operationType"
        header-align="center"
        align="center"
        label="操作类型"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.operationType == 1">单值选择框</p>
          <p v-else-if="scope.row.operationType == 2">多值选择框</p>
          <p v-else-if="scope.row.operationType == 3">多级选择框</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="isEnable"
        header-align="center"
        align="center"
        label="状态"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isEnable" type="success">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
        width="150"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('productAttribute:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            v-if="isAuth('productAttribute:update') && !scope.row.isEnable"
            type="text"
            size="small"
            style="color: #67c23a"
            @click="updateStatusHandle(scope.row.id, true)"
            >启用</el-button
          >
          <el-button
            v-if="isAuth('productAttribute:update') && scope.row.isEnable"
            type="text"
            size="small"
            style="color: #e6a23c"
            @click="updateStatusHandle(scope.row.id, false)"
            >禁用</el-button
          >
          <el-button
            v-if="isAuth('productAttribute:delete')"
            type="text"
            size="small"
            style="color: #f56c6c"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="dataForm.pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  queryProductAttributeApi,
  deleteProductAttributeApi,
  updateProductAttributeStatusApi,
} from "@/api/modules/productAttribute";
import AddOrUpdate from "./add-or-update";

export default {
  data() {
    return {
      dataForm: {
        attributeName: "",
        isListShow: "",
        isSupportSearch: "",
        isEnable: "",
        appletShow: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryProductAttributeApi(this.dataForm).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false;
        }
      );
    },

    // 修改
    updateStatusHandle(id, isEnable) {
      let msg = "启用";
      if (!isEnable) {
        msg = "禁用";
      }
      this.$confirm(`是否${msg}当前属性?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updateProductAttributeStatusApi(id).then(() => {
          this.$baseMessage(`${msg}成功`, "success");
          this.getDataList();
        });
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`是否删除当前属性?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProductAttributeApi(id).then(() => {
          this.$baseMessage("删除成功", "success");
          this.getDataList();
        });
      });
    },
  },
};
</script>
