<template>
  <div class="app-container">
    <el-row :gutter="12">
      <el-col :span="12">
        <el-tabs
          v-model="activeNameDiscount"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="7日" name="first"></el-tab-pane>
          <el-tab-pane label="30日" name="second"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activeDiscount" style="width: 100%; height: 500px"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-tabs v-model="activeName" type="card" @tab-click="handlePieClick">
          <el-tab-pane label="今日" name="first"></el-tab-pane>
          <el-tab-pane label="近7天" name="second"></el-tab-pane>
          <el-tab-pane label="近30天" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <div id="activePie" style="width: 100%; height: 500px"></div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="12">
      <el-col :span="12">
        <el-tabs
          v-model="attrActive"
          type="card"
          @tab-click="handleAttrActiveClick"
        >
          <el-tab-pane label="今日" name="first"></el-tab-pane>
          <el-tab-pane label="近7天" name="second"></el-tab-pane>
          <el-tab-pane label="近30天" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <el-select
            v-model="operation"
            size="small"
            placeholder="搜索结果"
            @change="findProductAttrDir"
          >
            <el-option
              v-for="item in operationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              size="small"
            ></el-option>
          </el-select>
          <div
            id="attrsNoDate"
            style="
              width: 100%;
              height: 500px;
              text-align: center;
              padding-top: 250px;
              display: none;
            "
          >
            <span>暂无数据</span>
          </div>
          <div id="attrs" style="width: 100%; height: 500px"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-tabs
          v-model="colorSeriesActive"
          type="card"
          @tab-click="handleColorSeriesActiveClick"
        >
          <el-tab-pane label="今日" name="first"></el-tab-pane>
          <el-tab-pane label="近7天" name="second"></el-tab-pane>
          <el-tab-pane label="近30天" name="third"></el-tab-pane>
        </el-tabs>
        <el-card shadow="never">
          <el-select
            v-model="productAttr"
            size="small"
            placeholder="产品属性"
            @change="findProductAttrValueDir"
          >
            <el-option label="全部" :value="0">全部</el-option>
            <el-option
              v-for="item in productAttrList"
              :key="item.productAttrId"
              :label="item.label"
              :value="item.value"
              size="small"
            ></el-option>
          </el-select>
          <div
            id="colorSeriesNoDate"
            style="
              width: 100%;
              height: 500px;
              text-align: center;
              padding-top: 250px;
              display: none;
            "
          >
            <span>暂无数据</span>
          </div>
          <div id="colorSeries" style="width: 100%; height: 500px"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAttrAndValueListApi } from '@/api/modules/productAttribute'
import {
  getSearchStatisticsApi,
  getSearchCountApi,
  querySearchCommonPowder,
  querySearchMetalPowder,
  findColorSeries,
  findUserSearch,
  getSearchAttributeStatisticsApi,
  getSearchAttributeValueStatisticsApi
} from '@/api/modules/searchHistory'

export default {
  data() {
    return {
      dataList: [],
      productAttrList: [],
      operationList: [
        { label: '全部', value: 0 },
        { label: '完全匹配', value: 2 },
        { label: '配色可参考', value: 4 },
        { label: '不匹配', value: 3 }
      ],
      operation: 0,
      productAttr: 0,
      productAttrName: '全部',
      activeNameDiscount: 'first',
      activeName: 'first',
      commandPowderActive: 'first',
      metalPowderActive: 'first',
      colorSeriesActive: 'first',
      attrActive: 'first',
      userPepleActive: 'first',
      activeNameDiscountType: '1',
      activeNamePie: '0',
      commandPowderActiveType: '1',
      metalPowderActiveType: '1',
      colorSeriesActiveType: '1',
      attrActiveType: '1',
      userPepleActiveType: '1',
      searchPieHistoryVo: '',
      querySearchCommonPowder: '',
      querySearchMetalPowder: '',
      findColorSeries: '',
      attrSeries: '',
      findUserSearch: ''
    }
  },
  created() {},
  mounted() {
    this.getDataList()
    this.getDataPie()
    this.findProductAttrDir()
    this.selectProductAttrList()
    this.findProductAttrValueDir()
    // this.getCommandPowder()
    // this.getMetalPowder()
    // this.getUserSearch()
    // this.getCommandPowderOrMetalPowder()
  },
  methods: {
    selectProductAttrList() {
      this.productAttrList = []
      getAttrAndValueListApi({ isSearch: true }).then((response) => {
        if (response && response.length > 0) {
          this.productAttrList = response
        }
      })
    },
    initActiveDiscount() {
      var activeDiscount = this.$echarts.init(
        document.getElementById('activeDiscount')
      )
      var searchDate = new Array()
      var searchCount = new Array()
      var searchAccuracy = new Array()
      var searchNonOperation = new Array()
      var searchReference = new Array()
      for (var i = 0; i < this.dataList.length; i++) {
        searchDate[i] = this.dataList[i].date
        searchCount[i] = this.dataList[i].totalCount
        searchAccuracy[i] = this.dataList[i].matchCount
        searchNonOperation[i] = this.dataList[i].dontMatchCount
        searchReference[i] = this.dataList[i].mayReferCount
      }
      let option = null
      option = {
        title: {
          left: 'center',
          text:
            this.activeNameDiscount == 'first' ? '7日搜索统计' : '30日搜索统计'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['搜索总数', '搜索匹配数', '搜索不匹配数', '匹配可参考数'],
          y: 'bottom' //可设定图例在上、下、居中
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: searchDate
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '搜索总数',
            type: 'line',

            data: searchCount
          },
          {
            name: '搜索匹配数',
            type: 'line',

            data: searchAccuracy
          },
          {
            name: '搜索不匹配数',
            type: 'line',

            data: searchNonOperation
          },
          {
            name: '匹配可参考数',
            type: 'line',

            data: searchReference
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === 'object') {
        activeDiscount.setOption(option, true)
      }
    },

    initActivePie() {
      var activePie = this.$echarts.init(document.getElementById('activePie'))
      let option = null
      var titleName = ''
      if (this.activeName === 'first') {
        titleName = '搜索历史今日统计'
      } else if (this.activeName === 'second') {
        titleName = '搜索历史近7日统计'
      } else if (this.activeName === 'third') {
        titleName = '搜索历史近30日统计'
      }
      option = {
        title: {
          text: titleName,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '操作',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              //   {
              //     value: this.searchPieHistoryVo.searchNonOperation,
              //     name: '未处理个数'
              //   },
              {
                value: this.searchPieHistoryVo.dontMatchCount,
                name: '不匹配个数'
              },
              {
                value: this.searchPieHistoryVo.matchCount,
                name: '完全匹配个数'
              },
              {
                value: this.searchPieHistoryVo.mayReferCount,
                name: '配色可参考个数'
              }
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                label: {
                  show: true,
                  formatter: '{b} : {c} ({d}%)'
                },
                labelLine: { show: true }
              }
            }
          }
        ]
      }

      if (option && typeof option === 'object') {
        activePie.setOption(option, true)
      }
    },

    //金属粉和普通粉结合统计图（产品搜索准确率）
    initCommandPowderOrMetalPowder() {
      var command = new Array()
      var metal = new Array()
      /*if (this.querySearchCommonPowder!=null&&this.querySearchCommonPowder.length>0
                &&this.querySearchMetalPowder!=null&&this.querySearchMetalPowder.length>0 ) {*/
      var commmandParam = {}
      var commmandParam1 = {}
      console.log(this.querySearchCommonPowder)
      commmandParam.value =
        Number(this.querySearchCommonPowder.searchNonOperation) +
        Number(this.querySearchCommonPowder.unMateOperation) +
        Number(this.querySearchCommonPowder.mateOperation) +
        Number(this.querySearchCommonPowder.colorOperation)
      commmandParam.name = '普通粉'

      commmandParam1.value =
        Number(this.querySearchMetalPowder.searchNonOperation) +
        Number(this.querySearchMetalPowder.unMateOperation) +
        Number(this.querySearchMetalPowder.mateOperation) +
        Number(this.querySearchMetalPowder.colorOperation)
      commmandParam1.name = '金属粉'

      command[0] = commmandParam //普通粉
      command[1] = commmandParam1 //金属粉

      var metalParams = {} //普通粉
      var metalParams1 = {}
      var metalParams2 = {}
      var metalParams3 = {}
      metalParams.value = this.querySearchCommonPowder.searchNonOperation
      metalParams.name = '普通粉未处理个数'

      metalParams1.value = this.querySearchCommonPowder.unMateOperation
      metalParams1.name = '普通粉不匹配个数'

      metalParams2.value = this.querySearchCommonPowder.mateOperation
      metalParams2.name = '普通粉完全匹配个数'

      metalParams3.value = this.querySearchCommonPowder.colorOperation
      metalParams3.name = '普通粉配色可参考个数'
      metal[0] = metalParams
      metal[1] = metalParams1
      metal[2] = metalParams2
      metal[3] = metalParams3

      var metalParams4 = {} //金属粉
      var metalParams5 = {}
      var metalParams6 = {}
      var metalParams7 = {}
      metalParams4.value = this.querySearchMetalPowder.searchNonOperation
      metalParams4.name = '金属粉未处理个数'
      metalParams5.value = this.querySearchMetalPowder.unMateOperation
      metalParams5.name = '金属粉不匹配个数'
      metalParams6.value = this.querySearchMetalPowder.mateOperation
      metalParams6.name = '金属粉完全匹配个数'
      metalParams7.value = this.querySearchMetalPowder.colorOperation
      metalParams7.name = '金属粉配色可参考个数'
      metal[4] = metalParams4
      metal[5] = metalParams5
      metal[6] = metalParams6
      metal[7] = metalParams7
      /* }*/

      var CommandPowderOrMetalPowder = this.$echarts.init(
        document.getElementById('commandPowderOrMetalPowder')
      )
      let option = null
      console.log(command)
      console.log(metal)
      option = {
        title: {
          text: '产品搜索准确率',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: '',
            type: 'pie',

            radius: ['0%', '40%'],

            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: command
          },
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],

            data: metal
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === 'object') {
        CommandPowderOrMetalPowder.setOption(option, true)
      }
    },

    initCommandPowder() {
      var activePie = this.$echarts.init(
        document.getElementById('commandPowder')
      )
      let option = null
      var titleName = ''
      if (this.commandPowderActive === 'first') {
        titleName = '普通粉搜色准确率今日统计'
      } else if (this.commandPowderActive === 'second') {
        titleName = '普通粉搜色准确率近7日统计'
      } else if (this.commandPowderActive === 'third') {
        titleName = '普通粉搜色准确率近30日统计'
      }
      option = {
        title: {
          text: titleName,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '操作',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: this.querySearchCommonPowder.searchNonOperation,
                name: '未操作个数'
              },
              {
                value: this.querySearchCommonPowder.unMateOperation,
                name: '不匹配个数'
              },
              {
                value: this.querySearchCommonPowder.mateOperation,
                name: '完全匹配个数'
              },
              {
                value: this.querySearchCommonPowder.colorOperation,
                name: '配色可参考个数'
              }
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                label: {
                  show: true,
                  formatter: '{b} : {c} ({d}%)'
                },
                labelLine: { show: true }
              }
            }
          }
        ]
      }

      if (option && typeof option === 'object') {
        activePie.setOption(option, true)
      }
    },

    initMetalPowder() {
      var activePie = this.$echarts.init(document.getElementById('metalPowder'))
      let option = null
      var titleName = ''
      if (this.metalPowderActive === 'first') {
        titleName = '金属粉搜色准确率今日统计'
      } else if (this.metalPowderActive === 'second') {
        titleName = '金属粉搜色准确率近7日统计'
      } else if (this.metalPowderActive === 'third') {
        titleName = '金属粉搜色准确率近30日统计'
      }
      option = {
        title: {
          text: titleName,
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '操作',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: this.querySearchMetalPowder.searchNonOperation,
                name: '未操作个数'
              },
              {
                value: this.querySearchMetalPowder.unMateOperation,
                name: '不匹配个数'
              },
              {
                value: this.querySearchMetalPowder.mateOperation,
                name: '完全匹配个数'
              },
              {
                value: this.querySearchMetalPowder.colorOperation,
                name: '配色可参考个数'
              }
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                label: {
                  show: true,
                  formatter: '{b} : {c} ({d}%)'
                },
                labelLine: { show: true }
              }
            }
          }
        ]
      }

      if (option && typeof option === 'object') {
        activePie.setOption(option, true)
      }
    },

    initColorSeries() {
      if (this.findColorSeries == null || this.findColorSeries.length == 0) {
        document.getElementById('colorSeriesNoDate').style.display = ''
        document.getElementById('colorSeries').style.display = 'none'
        return
      } else {
        document.getElementById('colorSeriesNoDate').style.display = 'none'
        document.getElementById('colorSeries').style.display = ''
      }
      var allPie = new Array()
      var allPieDetail = new Array()
      if (this.findColorSeries != null && this.findColorSeries.length > 0) {
        for (var i = 0; i < this.findColorSeries.length; i++) {
          var param = {}
          param.value = this.findColorSeries[i].searchCount
          param.name = this.findColorSeries[i].attributeName
          allPie[i] = param
          for (var y = 0; y < this.findColorSeries[i].list.length; y++) {
            var par = {}
            par.value = this.findColorSeries[i].list[y].searchCount
            par.name = this.findColorSeries[i].list[y].operationName
            allPieDetail[allPieDetail.length] = par
          }
        }
      }
      var activeDiscount = this.$echarts.init(
        document.getElementById('colorSeries')
      )
      let option = null
      option = {
        title: {
          text: this.productAttrName + '值搜索分布',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: this.productAttrName,
            type: 'pie',
            radius: ['20%', '40%'],
            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: allPie
          },
          {
            name: this.productAttrName,
            type: 'pie',
            radius: ['40%', '60%'],

            data: allPieDetail
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === 'object') {
        activeDiscount.setOption(option, true)
      }
    },

    initAttrs() {
      if (this.attrSeries == null || this.attrSeries.length == 0) {
        document.getElementById('attrsNoDate').style.display = ''
        document.getElementById('attrs').style.display = 'none'
        return
      } else {
        document.getElementById('attrsNoDate').style.display = 'none'
        document.getElementById('attrs').style.display = ''
      }
      var allPie = new Array()
      var allPieDetail = new Array()
      if (this.attrSeries != null && this.attrSeries.length > 0) {
        for (var i = 0; i < this.attrSeries.length; i++) {
          var param = {}
          param.value = this.attrSeries[i].searchCount
          param.name = this.attrSeries[i].attributeName
          allPie[i] = param
          for (var y = 0; y < this.attrSeries[i].list.length; y++) {
            var par = {}
            par.value = this.attrSeries[i].list[y].searchCount
            par.name = this.attrSeries[i].list[y].operationName
            allPieDetail[allPieDetail.length] = par
          }
        }
      }
      var activeDiscount = this.$echarts.init(document.getElementById('attrs'))
      let option = null
      option = {
        title: {
          text: '属性搜索分布',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: '属性',
            type: 'pie',

            radius: ['0%', '60%'],

            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: allPie
          },
          {
            name: '属性',
            type: 'pie',
            radius: ['40%', '60%'],

            data: allPieDetail
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === 'object') {
        activeDiscount.setOption(option, true)
      }
    },

    //打样员统计
    prooferStatistics() {
      if (this.findUserSearch == null || this.findUserSearch.length == 0) {
        //document.getElementById('userPeple').innerHTML="暂无数据";
        return
      }
      var proofer = this.$echarts.init(document.getElementById('userPeple'))
      var proofNum = new Array()
      var sampleNum = new Array()
      var userName = new Array()
      for (var i = 0; i < this.findUserSearch.length; i++) {
        userName[i] = this.findUserSearch[i].userName
        sampleNum[i] = this.findUserSearch[i].sampleCount
        proofNum[i] = this.findUserSearch[i].modelCount
      }
      let option = null
      option = {
        title: {
          text: '打样员订单查色统计',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['打样订单', '样板样粉'],
          y: 'bottom' //可设定图例在上、下、居中
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        xAxis: {
          type: 'category',
          data: userName
        },
        series: [
          {
            name: '打样订单',
            type: 'bar',
            data: sampleNum,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'right', //在上方显示
                  textStyle: {
                    //数值样式
                    color: 'black',
                    fontSize: 12
                  }
                }
              }
            }
          },
          {
            name: '样板样粉',
            type: 'bar',
            data: proofNum,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: 'right', //在上方显示
                  textStyle: {
                    //数值样式
                    color: 'black',
                    fontSize: 12
                  }
                }
              }
            }
          }
        ]
      }
      if (option && typeof option === 'object') {
        proofer.setOption(option, true)
      }
    },
    // 获取数据列表
    getDataList() {
      getSearchStatisticsApi(this.activeNameDiscountType).then((response) => {
        if (response && response.length > 0) {
          this.dataList = response
          this.initActiveDiscount()
        } else {
          this.dataList = []
        }
      })
    },
    // 获取数据列表
    getDataPie() {
      getSearchCountApi(this.activeNamePie).then((response) => {
        if (response) {
          this.searchPieHistoryVo = response
          this.initActivePie()
        } else {
        }
      })
    },

    getCommandPowder() {
      var params = {
        type: this.commandPowderActiveType
      }
      querySearchCommonPowder(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.querySearchCommonPowder = data.querySearchCommonPowder
          this.initCommandPowder()
        } else {
        }
      })
    },

    //获取产品分类统计数据
    getCommandPowderOrMetalPowder() {
      var params = {
        type: this.commandPowderActiveType
      }
      querySearchCommonPowder(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.querySearchCommonPowder = data.querySearchCommonPowder
          //  this.initCommandPowderOrMetalPowder();
          querySearchMetalPowder(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.querySearchMetalPowder = data.querySearchMetalPowder
              this.initCommandPowderOrMetalPowder()
            }
          })
        }
      })

      // this.initCommandPowderOrMetalPowder();
    },

    getMetalPowder() {
      var params = {
        type: this.metalPowderActiveType
      }
      querySearchMetalPowder(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.querySearchMetalPowder = data.querySearchMetalPowder
          this.initMetalPowder()
        } else {
        }
      })
    },
    findProductAttrValueDir(data) {
      var objVal = '全部'
      this.productAttrList.forEach((val) => {
        if (val.value == data) {
          objVal = val.label
        }
      })
      this.productAttrName = objVal
      getSearchAttributeValueStatisticsApi(
        this.colorSeriesActiveType,
        this.productAttr
      ).then((response) => {
        if (response && response.length > 0) {
          this.findColorSeries = response
        } else {
          this.findColorSeries = []
        }
        this.initColorSeries()
      })
    },

    findProductAttrDir() {
      getSearchAttributeStatisticsApi(this.attrActiveType, this.operation).then(
        (response) => {
          if (response && response.length > 0) {
            this.attrSeries = response
          } else {
            this.attrSeries = []
          }
          this.initAttrs()
        }
      )
    },

    getUserSearch() {
      var params = {
        type: this.userPepleActiveType
      }
      findUserSearch(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.findUserSearch = data.findUserSearch
          this.prooferStatistics()
        } else {
        }
      })
    },
    handleClick(tab, event) {
      if (this.activeNameDiscount === 'first') {
        this.activeNameDiscountType = '2'
      } else if (this.activeNameDiscount === 'second') {
        this.activeNameDiscountType = '3'
      }
      this.getDataList()
    },
    handlePieClick(tab, event) {
      if (this.activeName === 'first') {
        this.activeNamePie = '0'
      } else if (this.activeName === 'second') {
        this.activeNamePie = '1'
      } else if (this.activeName === 'third') {
        this.activeNamePie = '2'
      }
      this.getDataPie()
    },
    handleColorSeriesActiveClick(tab, event) {
      if (this.colorSeriesActive === 'first') {
        this.colorSeriesActiveType = '0'
      } else if (this.colorSeriesActive === 'second') {
        this.colorSeriesActiveType = '1'
      } else if (this.colorSeriesActive === 'third') {
        this.colorSeriesActiveType = '2'
      }
      this.findProductAttrValueDir()
    },
    handleAttrActiveClick(tab, event) {
      if (this.attrActive === 'first') {
        this.attrActiveType = '0'
      } else if (this.attrActive === 'second') {
        this.attrActiveType = '1'
      } else if (this.attrActive === 'third') {
        this.attrActiveType = '2'
      }
      this.findProductAttrDir()
    }
  }
}
</script>

<style>
.el-tabs__nav.is-top {
  float: right;
}

.el-tabs__header.is-top {
  margin: 0;
}
</style>
