<template>
  <div class="app-container">
    <el-form :inline="true"  size="small" :model="dataForm" ref="dataForm"  @keyup.enter.native="restPage()">
      <el-form-item prop="deviceModelId">
        <el-select v-model="dataForm.deviceModelId" placeholder="设备型号">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in modelList"
                  :key="item.id"
                  :label="item.deviceModelName"
                  :value="item.id"
                  size="small"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="deviceNo">
        <el-input v-model="dataForm.deviceNo" placeholder="设备编号" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="deviceName">
        <el-input v-model="dataForm.deviceName" placeholder="设备名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item prop="state" >
        <el-select v-model="dataForm.state" placeholder="设备状态">
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in States"
                  :key="item.key"
                  :label="item.key"
                  :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')"  size="small" type="info">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        fixed="left"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="deviceNo"
        header-align="center"
        align="center"
        label="设备编号">
      </el-table-column>
      <el-table-column
        prop="deviceName"
        header-align="center"
        align="center"
        label="设备名称">
      </el-table-column>
      <el-table-column
        prop="picUrl"
        header-align="center"
        align="center"
        label="设备图片">
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceModelName"
        header-align="center"
        align="center"
        label="设备型号">
      </el-table-column>
      <el-table-column
        prop="factoryName"
        header-align="center"
        align="center"
        label="所属工厂">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="设备状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state=='0'">停用</span>
          <span v-if="scope.row.state=='1'">正常</span>
          <span v-if="scope.row.state=='2'">同步中</span>
          <span v-if="scope.row.state=='3'">同步完成</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <p>
          <el-button v-if="isAuth('device:giveuser')" type="text" size="small" style="color: green" @click="deviceUserAddHandle(scope.row.id)">设备授权</el-button>
          </p>
          <el-button v-if="isAuth('device:giveuser')" type="text" size="small" style="color: green" @click="deviceUserListHandle(scope.row.id)">授权列表</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!--添加授权-->
    <device-user-add  v-if="addOrUpdateVisible" ref="deviceUserAdd" @refreshDataList="getDataList"></device-user-add>
    <!--授权列表-->
    <device-user-list  v-if="addOrUpdateVisible" ref="deviceUserList" @refreshDataList="getDataList"></device-user-list>
  </div>
</template>

<script>
  import {queryDeviceApi, getModelListApi} from '@/api/modules/device';
  import DeviceUserAdd from './device-user-add';
  import DeviceUserList from './device-user-index';
  export default {
    data () {
      return {
        dataForm: {
          deviceNo: '',
          deviceName:'',
          daterange:[],
          state:''
        },
        States:[{
          value: '0',
          key: '停用'
        },{
          value: '1',
          key: '正常'
        },{
          value: '2',
          key: '同步中'
        },{
          value: '3',
          key: '同步完成'
        } ],
        modelList:[],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      DeviceUserAdd,
      DeviceUserList
    },
    created(){
      this.getDataList()
    },
    methods: {
      //重新查询
      restPage() {
        this.pageIndex = 1;
        this.getDataList();
      },
      reset(dataForm) {
       this.$refs.dataForm.resetFields();
        this.restPage();
      },
      // 获取数据列表
      getDataList () {
        this.getDataModelList(),
        this.dataListLoading = true
        var params = {
          page: this.pageIndex,
          limit: this.pageSize,
          deviceModelId:this.dataForm.deviceModelId,
          deviceNo: this.dataForm.deviceNo,
          deviceName: this.dataForm.deviceName,
          state:this.dataForm.state
        }
        queryDeviceApi(params).then(({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          }
          else {
            this.dataList = []
            this.totalPage = 0
          }
        })
        .finally(() => {
          this.dataListLoading = false;
        });
      },
      //获取下拉型号列表
      getDataModelList(){
        getModelListApi().then((response)=>{
            this.modelList = response;
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      //设备授权
      deviceUserAddHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.deviceUserAdd.init(id)
        })
      },
      //授权列表
      deviceUserListHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.deviceUserList.init(id)
        })
      }
    }
  }
</script>
