import request from "@/utils/request";

const url = "/c/productAttributeValue/";

/**
 * 分页获取产品属性列表信息
 * @returns
 */
export function queryProductAttributeValueApi(data) {
  return request({
    url: `${url}query`,
    method: "post",
    data,
  });
}

/**
 * 创建/编辑产品属性
 * @returns
 */
export function addOrUpdateProductAttributeValueApi(data) {
  return request({
    url: `${url}${!data.id ? "add" : "update"}`,
    method: "put",
    data,
  });
}

/**
 * 根据ID获取产品属性信息
 * @returns
 */
export function getProductAttributeValueApi(id) {
  return request({
    url: `${url}get/${id}`,
    method: "get",
  });
}

/**
 * 删除产品属性
 * @returns
 */
export function deleteProductAttributeValueApi(id) {
  return request({
    url: `${url}delete/${id}`,
    method: "delete",
  });
}

/**
 * 修改产品属性状态
 */
export function updateProductAttributeStatusApi(id) {
  return request({
    url: `${url}updateStatus/${id}`,
    method: "put",
  });
}

/**
 * 根据产品属性id获取关联通用属性值列表
 * @returns
 */
export function getGeneralProductAttributeValueApi(id) {
  return request({
    url: `${url}getGeneralList/${id}`,
    method: "get",
  });
}

/**
 * 获取光泽属性值信息
 * @param {*} glossValue
 * @returns
 */
export function getGlossInfoApi(glossValue) {
  return request({
    url: `${url}getGlossInfo/${glossValue}`,
    method: "get",
  });
}

/**
 * 获取光泽参考标准信息
 * @returns
 */
export function getGlossStandardApi() {
  return request({
    url: `${url}getGlossStandard`,
    method: "get",
  });
}
