<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item prop="colorCompareName">
        <el-input
          v-model="dataForm.colorCompareName"
          placeholder="记录名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <el-date-picker
          type="daterange"
          v-model="dataForm.daterange"
          range-separator="~"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :editable="false"
          start-placeholder="创建时间从"
          end-placeholder="创建时间到"
          style="width: 300px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.createUserId"
          filterable
          placeholder="人员名称"
          clearable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="colorCompareName"
        header-align="center"
        align="center"
        label="记录名称"
      >
      </el-table-column>
      <el-table-column
        prop="productNo"
        header-align="center"
        align="center"
        label="标样产品号"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="210"
        label="标样LAB值"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in setsplit(scope.row.prototypeLab)"
            :key="index"
          >
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="sampleQuantity"
        header-align="center"
        align="center"
        label="试样数量"
      >
      </el-table-column>
      <el-table-column
        prop="createNickName"
        header-align="center"
        align="center"
        label="创建人员"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        width="150"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        width="150"
        align="center"
        label="修改时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="viewRecordHandle(scope.row.id)"
            >详情</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { queryUserApi } from '@/api/modules/user'
import {
  queryColorCompareHistoryApi,
  deleteColorCompareHistoryApi
} from '@/api/modules/colorCompareHistory'
export default {
  data() {
    return {
      dataForm: {
        colorCompareName: '',
        daterange: [],
        createUserId: '',
        createNickName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      userList: []
    }
  },
  activated() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      queryUserApi({ pageSize: 10000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.userList = records
        } else {
          this.userList = []
        }
        this.getDataList()
      })
    },
    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.dataForm.createUserId = ''
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      var endDate = ''
      var startDate = ''
      if (this.dataForm.daterange && this.dataForm.daterange.length == 2) {
        startDate = this.dataForm.daterange[0] + ' 00:00:00'
        endDate = this.dataForm.daterange[1] + ' 23:59:59'
      }
      var params = {
        pageNo: this.pageIndex,
        limit: this.pageSize,
        colorCompareName: this.dataForm.colorCompareName,
        createUserId: this.dataForm.createUserId,
        createTimeFrom: startDate,
        createTimeTo: endDate
      }

      queryColorCompareHistoryApi(params).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records
          this.totalPage = totalRecords
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 详情
    viewRecordHandle(id) {
      this.$router.push({ path: '/colorCompare', query: { id: id } })
    },
    setsplit(val) {
      if (val) {
        let arr = val.split(';')
        return arr
      } else {
        return ''
      }
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定删除该条记录?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteColorCompareHistoryApi(id).then(() => {
          this.$baseMessage('删除成功', 'success')
          this.getDataList()
        })
      })
    }
  }
}
</script>
