import request from '@/utils/request'

const url = '/c/brand'

/**
 * 获取品牌套餐信息
 * @returns
 */
export function getComboInfoApi() {
  return request({
    url: `${url}/getComboInfo`,
    method: 'get'
  })
}
