<template>
  <el-dialog :title="!dataForm.id ? $t('common.buttons.createNew') : $t('common.buttons.edit')"
    :close-on-click-modal="false" :visible.sync="visible" width="500px">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="140px" v-loading="dataLoading" style="width: 100%" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item :label="$t('brand.brandName')" prop="brandName">
        <el-input v-model="dataForm.brandName" :placeholder="$t('brand.brandName')" size="small"></el-input>
      </el-form-item>
      <el-form-item :label="$t('common.table.comment')" prop="remarks">
        <el-input v-model="dataForm.remarks" type="textarea" size="small" :placeholder="$t('common.table.comment')"
          maxlength="100" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getColorBookBrandApi,
  addOrUpdateColorBookBrandApi
} from '@/api/modules/colorbookbrand'
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: undefined,
        brandName: '',
        isConvert: false,
        remarks: ''
      },
      dataRule: {
        brandName: [
          { required: true, message: this.$t('brand.brandName.tips'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.dataForm.id = id || undefined
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (id) {
          getColorBookBrandApi(id).then((response) => {
            this.dataForm = response
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          addOrUpdateColorBookBrandApi(this.dataForm)
            .then(() => {
              this.visible = false
              this.$emit('refreshDataList')
              this.$baseMessage(this.$t('physicalColors.prompt.action'), 'success')
            })
            .finally(() => {
              this.dataLoading = false
            })
        }
      })
    }
  }
}
</script>
