import request from "@/utils/request";

const url = "/c/colorCompareHistory";

// 获取信息
export function infoColorCompareHistoryApi(id) {
  return request({
    url: `${url}/getInfo/${id}`,
    method: "get"
  });
}

// 添加
export function saveColorCompareHistoryApi(data) {
    let requestUrl = `${url}/add`;
    if (data.id) {
      requestUrl = `${url}/update`;
    }
    return request({
      url: requestUrl,
      method: "put",
      data
    });
  }

  /**
 * 分页查询比色记录
 * @param {*} data
 * @returns
 */
export function queryColorCompareHistoryApi(data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

/**
 * 删除比色记录
 * @param {*} data
 * @returns
 */
export function deleteColorCompareHistoryApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}