<template>
  <el-dialog :title="!dataForm.id ? $t('common.buttons.createNew') : $t('common.buttons.edit')"
    :close-on-click-modal="false" :visible.sync="visible" width="500px">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="120px" v-loading="dataLoading" style="width: 100%" element-loading-text=""
      element-loading-spinner="el-icon-loading">

      <el-form-item :label="$t('physicalColors.attributes.code')" prop="categoryNo">
        <el-input v-model="dataForm.categoryNo" :placeholder="$t('physicalColors.attributes.code')"
          size="small"></el-input>
      </el-form-item>
      <el-form-item :label="$t('electronicColors.common.name')" prop="categoryName">
        <el-input v-model="dataForm.categoryName" :placeholder="$t('electronicColors.common.name')"
          size="small"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.brand')" prop="colorBookBrandId">
        <el-select v-model="dataForm.colorBookBrandId" size="small" :placeholder="$t('accounts.common.brand')">
          <el-option v-for="item in dictInfoList" :key="item.id" :label="item.brandName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('common.table.comment')" prop="remarks">
        <el-input v-model="dataForm.remarks" type="textarea" size="small" :placeholder="$t('common.table.comment')"
          maxlength="100" show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label="$t('electronicColors.categories.isRestricted')" size="mini" prop="isEnable">
        <el-radio-group v-model="dataForm.isRestricted">
          <el-radio :label="true">{{ $t('electronicColors.categories.public') }}</el-radio>
          <el-radio :label="false">{{ $t('electronicColors.categories.restricted') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getColorBookCategoryApi,
  addOrUpdateColorBookCategoryApi,
} from "@/api/modules/colorbookcategory";
import {
  queryColorBookBrandApi
} from "@/api/modules/colorbookbrand";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dictInfoList: [],
      dataForm: {
        id: undefined,
        categoryNo: "",
        categoryName: "",
        colorBookBrandId: "",
        remarks: "",
        isRestricted: true
      },
      dataRule: {
        categoryNo: [
          { required: true, message: this.$t('physicalColors.prompt.code'), trigger: "blur" },
        ],
        categoryName: [
          { required: true, message: this.$t('physicalColors.prompt.name'), trigger: "blur" },
        ],
        colorBookBrandId: [
          { required: true, message: this.$t('physicalColors.prompt.brand'), trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getDictInfo();
  },
  methods: {
    getDictInfo() {
      queryColorBookBrandApi().then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dictInfoList = records;
        } else {
          this.dictInfoList = [];
        }
      });
    },
    init(id) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getColorBookCategoryApi(id).then((response) => {
            this.dataForm = response;
            if (response.isRestricted === '1') {
              this.dataForm.isRestricted = true;
            } else {
              this.dataForm.isRestricted = false;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateColorBookCategoryApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage(this.$t('physicalColors.prompt.action'), "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
