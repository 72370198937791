<template>
  <sides-lip ref="SidesLip" @click="close()">
    <div slot="soltForm">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        size="small"
        ref="dataForm"
        label-width="120px"
        v-loading="dataLoading"
        style="width: 100%"
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="批次号码" prop="productOrder">
              <el-input
                v-model="dataForm.productOrder"
                placeholder="批次号码"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品编号" prop="productNo">
              <el-input
                v-model="dataForm.productNo"
                placeholder="产品编号"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="dataForm.productName"
                placeholder="产品名称"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>

            <el-form-item label="客户名称" prop="customerName">
              <el-input
                v-model="dataForm.customerName"
                placeholder="客户名称"
                size="small"
                style="width: 100%"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="产品状态" prop="state">
                        生产产品
                    </el-form-item> -->
          </div>
          <div style="width: 30%; margin-top: 0px; margin-left: 20px">
            <el-form-item label="" prop="picUrl" label-width="10px">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleUploadSuccess"
                :before-upload="beforeUpload"
              >
                <img
                  v-if="dataForm.picUrl"
                  :src="dataForm.picUrl"
                  class="avatar"
                />
                <div
                  v-else-if="dataForm.hex"
                  :style="{
                    backgroundColor: dataForm.hex,
                    height: '178px',
                    minWidth: '178px'
                  }"
                ></div>
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  title="点击上传"
                ></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 68%">
            <el-form-item label="生产工厂" prop="factoryId">
              <el-select
                v-model="dataForm.factoryId"
                size="small"
                placeholder="请选择工厂"
                style="width: 100%"
              >
                <el-option
                  v-for="item in factoryList"
                  :key="item.id"
                  :label="item.factoryName"
                  :value="item.id"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item>
              <template>
                <el-checkbox v-model="dataForm.isBase" size="small"
                  >设置为底粉</el-checkbox
                >
              </template>
            </el-form-item>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="测试L*a*b*">
              <div>
                <el-button
                  size="small"
                  type="primary"
                  class="mr10"
                  @click="syncDevice()"
                  :loading="loading"
                  >数据同步
                </el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <span slot="label"
                ><label style="color: #fa3d1e">*</label> L*a*b*</span
              >
              <el-row>
                <el-col :span="8">
                  <el-form-item prop="l">
                    <el-input
                      v-model="dataForm.l"
                      placeholder="L*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="a">
                    <el-input
                      v-model="dataForm.a"
                      placeholder="a*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="b">
                    <el-input
                      v-model="dataForm.b"
                      placeholder="b*"
                      class="w150 mr10"
                      size="small"
                      @input="getHex()"
                      clearable
                      maxlength="30"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看测量数据</span>
              <div>
                <ul class="productLAB">
                  <li>
                    <span class="title">多角度L*A*B*</span>
                  </li>
                  <li>
                    <span class="title">角度</span>
                    <span class="w100">L*</span>
                    <span class="w100">a*</span>
                    <span class="w100">b*</span>
                  </li>
                  <li>
                    <span class="title">25度</span>
                    <el-input
                      v-model="dataForm.l25"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.a25"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.b25"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                  <li>
                    <span class="title">45度</span>
                    <el-input
                      v-model="dataForm.l45"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.a45"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.b45"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                  <li>
                    <span class="title">75度</span>
                    <el-input
                      v-model="dataForm.l75"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.a75"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm.b75"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                  <li v-if="olabShow">
                    <span class="title">积分球L*A*B*</span>
                  </li>
                  <li v-if="olabShow">
                    <span class="title">角度</span>
                    <span v-for="x in productLAB.title" class="w100" :key="x">{{
                      x
                    }}</span>
                  </li>
                  <li v-if="olabShow">
                    <span class="title" style="line-height: 1.2">
                      D65/10°
                      <br />
                      <span class="ptitle">SPIN(包含镜面反射)</span>
                    </span>
                    <el-input
                      v-model="dataForm[`lo`]"
                      placeholder="L*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`ao`]"
                      placeholder="a*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="dataForm[`bo`]"
                      placeholder="b*"
                      class="w100"
                      :disabled="true"
                    ></el-input>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <div style="display: flex">
          <div style="width: 70%">
            <el-form-item label="光泽值" prop="glossValue">
              <el-input-number
                v-model="dataForm.glossValue"
                :max="999999"
                :precision="2"
                label="光泽值"
                size="small"
                @change="selectGlossInfo()"
                style="width: 100%"
              ></el-input-number>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="" prop="gloss" label-width="20px">
              <el-select
                v-model="dataForm.gloss"
                size="small"
                placeholder=""
                style="width: 100%"
                disabled="disabled"
              >
                <el-option
                  v-for="item in productGlossList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  size="small"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="">
          <el-collapse @change="handleChange">
            <el-collapse-item>
              <span class="collapse-title" slot="title">查看参考标准</span>
              <div style="display: flex">
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 0" :key="index" class="ziti">
                      {{ item.valueName }}
                    </p>
                  </template>
                </div>
                <div style="width: 60%">
                  <template v-for="(item, index) in glossList">
                    <p v-if="index % 2 == 1" :key="index" class="ziti">
                      {{ item.valueName }}
                    </p>
                  </template>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>

        <div class="ces-main">
          <search-form
            ref="form"
            size="mini"
            labelWidth="120px"
            :searchData="searchData"
            :searchForm="searchForm"
            :notIncluded="[]"
          ></search-form>
        </div>

        <el-form-item label="标准色号" prop="ralColorNo">
          <el-input
            v-model="dataForm.ralColorNo"
            placeholder="产品标准色号"
            size="small"
            style="width: 100%"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品说明" prop="description">
          <div style="margin: 5px 0"></div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="产品说明"
            v-model="dataForm.description"
            maxlength="150"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <device-boot v-if="deviceBootShow" ref="deviceBoot" />
    </div>
    <div slot="soltFooter">
      <span slot="footer">
        <el-button @click="close()" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="dataFormSubmit()"
          size="small"
          :disabled="dataLoading"
          >确定</el-button
        >
      </span>
    </div>
  </sides-lip>
</template>

<script>
import {
  getProductApi,
  addOrUpdateProductApi,
  getHex,
  checkProductNoApi
} from '@/api/modules/product'
import Tinymce from '@/components/Tinymce'
import { getToken } from '@/utils/auth'
import SidesLip from '@/components/SidesLip'
import SearchForm from '@/components/form.vue'
import { getFactoryListApi } from '@/api/modules/factory'
import { getAttrAndValueListApi } from '@/api/modules/productAttribute'
import {
  getProductAttrValue,
  getGlossInfoApi,
  getGlossStandardApi
} from '@/api/modules/productAttributeValue'
import { setDeviceState, syncDeviceApi } from '@/api/modules/device'

export default {
  components: {
    Tinymce,
    SidesLip,
    SearchForm,
    DeviceBoot: () => import('@/components/DeviceBoot')
  },
  data() {
    var validateProductNoOnly = (rule, value, callback) => {
      //校验产品编号唯一
      if (this.dataForm.productNo) {
        let params = {
          productNo: this.dataForm.productNo,
          id: this.dataForm.id
        }
        checkProductNoApi(params).then(() => {
          return callback()
        })
      } else {
        return callback()
      }
    }
    return {
      searchForm: [
        //这里是渲染查询表单的表头和类型的数据
      ],
      searchData: {
        //查询表单的对应的值
      },
      websock: null,
      biaoZhun: false,
      showLAB: false,
      loading: false,
      olabShow: false,
      device: {
        deviceId: ''
      },
      deviceBootShow: false,
      action: `/api/c/file/upload`,
      headers: { Authorization: getToken() },
      visible: false,
      dataLoading: false,
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      dataForm: {
        id: undefined,
        factoryId: undefined,
        productOrder: undefined,
        productNo: undefined,
        productName: undefined,
        customerName: undefined,
        picUrl: undefined,
        ralColorNo: undefined,
        gloss: undefined,
        glossValue: undefined,

        l: '',
        a: '',
        b: '',
        l25: undefined,
        a25: undefined,
        b25: undefined,
        l45: undefined,
        a45: undefined,
        b45: undefined,
        l75: undefined,
        a75: undefined,
        b75: undefined,
        isBase: false,
        remarks: '',
        state: 2,

        hex: ''
      },
      deviceList: [],
      performanceList: [],
      industryList: [],
      industryList_child: [],
      colorSeriesList: [],
      productCategoryList: [],
      otherPerformanceList: [],
      productSeriesList: [],
      productGlossList: [],
      productSurfaceList: [],
      productHierarchyList: [],
      factoryList: [],
      glossList: [],
      dataRule: {
        productNo: [
          { required: true, message: '产品编号不能为空', trigger: 'blur' },
          { validator: validateProductNoOnly, trigger: 'blur' }
        ],
        factoryId: [
          { required: true, message: '请选择工厂', trigger: 'change' }
        ],
        l: [{ required: true, message: 'L*测量不能为空', trigger: 'blur' }],
        a: [{ required: true, message: 'a*测量不能为空', trigger: 'blur' }],
        b: [{ required: true, message: 'b*测量不能为空', trigger: 'blur' }],
        productCategory: [
          { required: true, message: '产品分类不能为空', trigger: 'blur' }
        ],
        surface: [
          { required: true, message: '产品表面不能为空', trigger: 'blur' }
        ],
        glossValue: [
          { required: true, message: '光泽值不能为空', trigger: 'blur' }
        ]
      },
      productAttrId: '',
      attrValue: ''
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
    this.timerNum = 0
  },
  methods: {
    getProductAttrValue() {
      let params = {}
      getProductAttrValue(params).then(({ data }) => {
        if (data && data.code === 0) {
          for (var i = 0; i < data.list.length; i++) {
            if (data.list[i].productAttrType === '1') {
              //表面
              this.productSurfaceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '3') {
              //系列
              this.productSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '6') {
              //主题
              this.productHierarchyList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '2') {
              //色系
              this.colorSeriesList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '7') {
              //性能
              this.performanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '4') {
              //应用
              this.industryList = data.list[i].productAttrValue
              // if(this.dataForm.id != 0){
              //     this.industryList_child=data.list[i].childProductAttrValue;
              // } else {
              //     this.industryList_child=[];
              // }
            } else if (data.list[i].productAttrType === '8') {
              //功能
              this.otherPerformanceList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '5') {
              //分类
              this.productCategoryList = data.list[i].productAttrValue
            } else if (data.list[i].productAttrType === '9') {
              //光泽
              this.productGlossList = data.list[i].productAttrValue
            }
          }
        }
      })
    },
    getHex() {
      if (this.dataForm.picUrl) {
        return
      }
      var params = {
        lab: this.dataForm.l + ',' + this.dataForm.a + ',' + this.dataForm.b
      }
      getHex(params).then((response) => {
        if (response) {
          this.dataForm.hex = response
        }
      })
    },
    handleChange(val) {
      console.log(val)
    },
    //LAB值按钮隐藏显示
    isShowLAB() {
      this.showLAB = !this.showLAB
    },
    isBiaoZhun() {
      this.biaoZhun = !this.biaoZhun
    },

    //根据光泽测试值显示光泽范围名称
    selectGlossInfo() {
      if (this.dataForm.glossValue) {
        getGlossInfoApi(this.dataForm.glossValue).then((response) => {
          this.dataForm.gloss = response.valueName
          this.$set(
            this.searchData,
            '1-' + response.productAttributeId,
            response.id
          )
        })
      } else {
        this.dataForm.gloss = ''
      }
    },

    // 查询光泽参考标准
    getGlossStandard() {
      getGlossStandardApi().then((response) => {
        if (response && response.length > 0) {
          this.glossList = response
        } else {
          this.glossList = []
        }
      })
    },
    //设备引导
    handleDeviceBoot() {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init()
        })
      })
    },
    //设备同步
    syncDevice() {
      this.loading = true
      var id = this.$store.getters.userDevice.id
      console.log(id)
      if (null == id || '' == id) {
        this.loading = false
        this.handleDeviceBoot()
        return
      }
      syncDeviceApi(this.$store.getters.userDevice.id).then(() => {
        this.initWebSocket()
        this.timer = setInterval(() => {
          //如果已经同步就关闭定时任务
          this.$message.warning('数据同步中，请确认设备是否有待同步数据')
          this.timerNum = this.timerNum + 1
          if (this.timerNum == 5) {
            this.clearTimer() // 关闭定时器
            this.setDeviceState()
            this.loading = false
          }
        }, 5000)
      })
    },

    setDeviceState() {
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      setDeviceState(params).then(({ data }) => {})
    },

    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    //选择设备显示在页面
    handleCommand(command) {
      this.device = command
    },

    getAttrAndValueListInfo: function () {
      this.searchData = {}
      this.searchForm = []
      this.tableCols = []
      getAttrAndValueListApi({ isGlossProperties: false }).then((response) => {
        response.forEach((e) => {
          var obj = { type: '', label: '', width: '130px' }
          if (e.operationType == 1) {
            // 如果是单选
            obj.type = 'Select'
          }
          if (e.operationType == 2) {
            obj.type = 'SelectMultiple'
          }
          if (e.operationType == 3) {
            obj.type = 'Cascader'
          }
          obj.prop = e.operationType + '-' + e.value
          obj.value = e.value
          obj.valueList = e.valueList
          obj.label = e.label
          // this.$set(this.searchData, e.productAttrId, e.value);
          this.searchForm.push(obj)
        })
      })
    },

    init(id, isCopy, state) {
      this.getAttrAndValueListInfo()
      this.getGlossStandard()
      //进来就调用获取所有下拉列表
      this.isCopy = isCopy
      this.dataForm.id = id || undefined
      var titleMessage = this.dataForm.id ? '编辑' : '新增'
      titleMessage = this.isCopy ? '复制' : titleMessage
      this.dataForm.state = state
      this.$refs.SidesLip.init(titleMessage, '800px', 'block')
      this.getFactoryList()
      this.device.deviceId = this.$store.getters.userDevice.id
      this.loading = false
      this.visible = true
      this.$nextTick(() => {
        //清除缓存数据
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getProductApi(this.dataForm.id).then((response) => {
            this.dataForm = response
            // 获取属性
            this.searchData = response.searchData
            if (isCopy) {
              this.dataForm.productNo += '-复制'
              this.dataForm.id = undefined
            }
          })
        }
      })
    },
    close() {
      this.device = {}
      this.performanceList = []
      //清除缓存数据
      this.$refs['dataForm'].resetFields()
      this.$refs.SidesLip.sideClose()
    },

    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        var verification = this.$refs.form.verification()
        if (valid) {
          if (verification) {
            this.$message.warning(verification.label + '不能为空！')
          } else {
            this.dataLoading = true
            this.dataForm.searchData = this.searchData
            addOrUpdateProductApi(this.dataForm)
              .then(() => {
                this.$baseMessage('操作成功', 'success')
                this.device.deviceNo = ''
                this.$emit('refreshDataList')
                this.$refs.SidesLip.sideClose()
              })
              .finally(() => {
                this.dataLoading = false
              })
          }
        }
      })
    },
    //上传产品图处理
    handleUploadSuccess(res, file, type) {
      this.dataForm.picUrl = res.data
    },
    //上传前对图片大小及格式进行判断
    beforeUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/bmp' ||
        file.type === 'image/png'
      const isLt8M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        this.$message.error('上传图片格式不支持!')
      }
      if (!isLt8M) {
        this.$message.error('上传图片大小不能超过 8MB!')
      }
      return isJPG && isLt8M
    },
    //获取工厂列表
    getFactoryList() {
      getFactoryListApi().then((response) => {
        if (response && response.length > 0) {
          this.factoryList = response
          if (!this.dataForm.id) {
            this.dataForm.factoryId = this.factoryList[0].id
          }
        } else {
          this.factoryList = []
        }
      })
    },

    initWebSocket() {
      //初始化weosocket
      //ws地址
      if (this.websock) {
        this.websock.close()
      }
      var id = this.$store.getters.userDevice.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      console.log(e)
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      console.log(code)
      var msg = retData.msg
      console.log(msg)
      var data = retData.data
      console.log(data)
      if (code == 0) {
        this.dataForm.picUrl = data.picUrl
        this.dataForm.l = data.lAve
        this.dataForm.a = data.aAve
        this.dataForm.b = data.bAve
        this.dataForm.l25 = data.l1
        this.dataForm.a25 = data.a1
        this.dataForm.b25 = data.b1
        this.dataForm.l45 = data.l2
        this.dataForm.a45 = data.a2
        this.dataForm.b45 = data.b2
        this.dataForm.l75 = data.l3
        this.dataForm.a75 = data.a3
        this.dataForm.b75 = data.b3
        this.$message.success('同步成功')
        this.clearTimer() // 关闭定时器
        this.loading = false
        this.websock.close()
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error('WebSocket连接失败')
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      console.log('connection closed (' + e.code + ')')
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
}
</style>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 178px;
  text-align: center;
}

.ziti {
  font-size: 12px;
  margin: 0px;
  line-height: 25px;
}

.avatar {
  width: 178px;
  max-height: 178px;
  display: block;
}

.productLAB {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: -50px;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    .title {
      display: inline-block;
      width: 160px;
      text-align: center;
      color: #606266;
      font-size: 700;
    }
    .ptitle {
      display: inline-block;
      width: 160px;
      text-align: center;
      font-size: 10px;
    }
  }
}

.w100 {
  width: 100px;
}
.w150 {
  width: 115px;
}
.mr10 {
  margin-right: 5px;
}
.collapse-title {
  flex: 1 0 90%;
  color: #4ab7bd;
  font-weight: bolder;
  text-align: center;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
}

.el-form-item__label {
  width: 120px !important;
}
</style>
