<template>
    <el-dialog
            :title="'已授权列表'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-divider></el-divider>
        <el-form :model="dataForm"  size="small" ref="dataForm"
                 v-loading="dataLoading" style="width:100%;"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >

            <div style="border-bottom: 1px solid #cfcfcf;margin-bottom: 20px;" >
                <span class="one1">设备编号: {{dataForm.deviceNo}}</span>
                <span class="one1">所在地: {{dataForm.factoryName}}<el-divider direction="vertical"></el-divider>{{dataForm.laboratory}}</span>
                <span class="one1">负责人: {{dataForm.leadMan}}</span>
            </div>
            <el-table
                    :data="dataList"
                    border
                    size="small"
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column
                        prop="nickName"
                        header-align="center"
                        align="center"
                        label="用户昵称">
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        header-align="center"
                        align="center"
                        label="联系方式">
                </el-table-column>
                <el-table-column
                        prop="state"
                        header-align="center"
                        align="center"
                        label="授权">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state=='0'">已启用</span>
                        <span v-if="scope.row.state=='1'">已停用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        header-align="center"
                        align="center"
                        label="授权期限"
                        >
                    <template slot-scope="scope" >
                        <div v-if="scope.row.endDate">
                            <p><span  >开始：{{scope.row.startDate}}</span></p>
                            <span>结束：{{scope.row.endDate}}</span>
                        </div>
                        <div v-if="!scope.row.endDate">
                            <p>长期有效</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                            <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">撤销授权</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    size="small"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10,5]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-form>
        <span slot="footer" class="dialog-footer">
    </span>
    </el-dialog>
</template>

<script>
    import {getDeviceFactoryInfoApi, queryDeviceUserApi, deleteUserDeviceApi} from '@/api/modules/deviceuser'
    export default {
        data () {
            return {
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    deviceNo: '',
                    factoryName: '',
                    laboratory: '',
                    leadMan: ''
                },
                dataList: [],
                totalPage: 0,
                totalCount:0,
                pageSize: 10,
                dataListLoading: false,
                pageIndex: 1
            }
        },
        methods: {
            //重新查询
            restPage() {
                this.pageIndex = 1;
                this.getDataList();
            },
            reset(dataForm) {
                this.$refs.dataForm.resetFields();
                this.restPage();
            },
            init (id) {
                this.dataForm.id = id || 0
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields()
                    if (id) {
                        getDeviceFactoryInfoApi(id).then((response) => {
                            this.dataForm.deviceNo = response.deviceNo
                            this.dataForm.factoryName = response.factoryName
                            this.dataForm.laboratory = response.laboratory
                            this.dataForm.leadMan = response.leadMan
                        });
                    }
                    this.getDataList (id);
                })
            },
            //获取数据列表
            getDataList () {
                if (this.dataForm.id) {
                    let params = {
                        'deviceId': this.dataForm.id,
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                    }
                    queryDeviceUserApi(params).then(({ records, totalRecords }) => {
                        if (totalRecords > 0) {
                            this.dataList = records;
                            this.totalCount = totalRecords;
                        }
                        else {
                            this.dataList = [];
                            this.totalPage = 0;
                        }
                        })
                    .finally(() => {
                        this.dataListLoading = false;
                    });
                }
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getDataList()
            },
            //撤销授权
            deleteHandle (id) {
                this.$confirm(`是否确认撤销授权?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await deleteUserDeviceApi(id);
                    this.$baseMessage("撤销成功", "success");
                    this.getDataList();
                })
            }
        }
    }
</script>
<style scoped>
    .one1{
        display: inline-block;
        margin-right: 35px;
        padding: 0 0 10px 0;
    }
</style>