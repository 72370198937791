<template>
  <div class="app-container">
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="colorNo">
        <el-input v-model="dataForm.colorNo"
          :placeholder="$t('search.accurateColorSearch.electronicColors.enterColorCode')" size="small"
          clearable></el-input>
      </el-form-item>
      <!-- <el-form-item prop="colorName">
        <el-input v-model="dataForm.colorName" placeholder="请输入色号名称" size="small" clearable></el-input>
      </el-form-item> -->
      <el-form-item prop="colorBookBrandId">
        <el-select v-model="dataForm.colorBookBrandId" size="small" :placeholder="$t('accounts.common.brand')" clearable
          @change="getColorBookCategory()">
          <el-option v-for="item in colorBookBrandList" :key="item.id" :label="item.brandName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="colorBookCategoryId">
        <el-select v-model="dataForm.colorBookCategoryId" size="small"
          :placeholder="$t('attributes.ColorCodeClassification')" clearable>
          <el-option v-for="item in colorBookCategoryList" :key="item.id" :label="item.categoryName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{ $t('common.buttons.search') }}</el-button>
        <el-button @click="reset('dataForm')" size="small">{{ $t('common.buttons.reset') }}</el-button>
        <el-button v-if="isAuth('upload:file')" type="success" @click="uploadHandle()" :disabled="dataList.length < 0"
          size="small">{{
            $t('common.samples.import') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" style="width: 100%">
      <el-table-column prop="colorNo" header-align="center" align="center"
        :label="$t('electronicColors.common.colorCode')">
      </el-table-column>
      <!-- <el-table-column prop="colorName" header-align="center" align="center" label="色号名称">
      </el-table-column> -->
      <el-table-column prop="picUrl" header-align="center" align="center" :label="$t('common.table.image')">
        <template slot-scope="scope">
          <img :src="scope.row.picUrl" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="brandName" header-align="center" align="center" :label="$t('accounts.common.brand')">
      </el-table-column>
      <el-table-column prop="categoryName" header-align="center" align="center"
        :label="$t('attributes.ColorCodeClassification')">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" width="155"
        :label="$t('common.table.dateCreated')">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150"
        :label="$t('common.table.action')">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="viewdata(scope.row)" v-if="isAuth('colorbook:view')">{{
            $t('common.buttons.view') }}</el-button>
          <el-button v-if="isAuth('product:delete')" type="text" size="small" style="color: red"
            @click="deleteDataHandle(scope.row.id)">{{ $t('common.buttons.remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="dataForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
    <view-data v-if="viewdataVisible" ref="ViewData"></view-data>
  </div>

</template>

<script>
import ViewData from "./view-data";
import { queryColorBookApi, deleteColorBookDataApi } from "@/api/modules/colorbook";
import { colorBookCategoryListApi } from "@/api/modules/colorbookcategory";
import { colorBookBrandListApi } from "@/api/modules/colorbookbrand";
import Upload from "./oss-upload";
export default {
  data() {
    return {
      dataForm: {
        colorBookBrandId: "",
        colorName: "",
        colorNo: "",
        colorBookCategoryId: "",
        pageNo: 1,
        pageSize: 10,
      },
      colorBookBrandList: [],
      dataList: [],
      colorBookCategoryList: [],
      totalPage: 0,
      dataListLoading: false,
      uploadVisible: false,
      addOrUpdateVisible: false,
      viewdataVisible: false,
    };
  },
  mounted() {
    this.getBrandList();
    this.getDataList();
  },
  components: {
    Upload,
    ViewData,
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
  methods: {
    viewdata(item) {

      this.viewdataVisible = true
      this.$nextTick(() => {
        this.$refs.ViewData.init({
          id: item.id,
          labL: item.l,
          labA: item.a,
          labB: item.b,
          illuminant: '10 deg',
          observerAngle: 'D65',
          colorDifferenceFormula: '-1',
        })
      })

    },
    // 上传文件
    uploadHandle() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.init();
      });
    },
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    reset() {
      this.$refs.dataForm.resetFields();
      this.restPage();
    },
    getBrandList() {
      colorBookBrandListApi().then((records) => {
        if (records.length > 0) {
          this.colorBookBrandList = records;
        } else {
          this.colorBookBrandList = [];
        }
        this.getColorBookCategory();
      });
    },
    //分类下拉
    getColorBookCategory() {
      let id = this.dataForm.colorBookBrandId
        ? this.dataForm.colorBookBrandId
        : 0;
      colorBookCategoryListApi(id).then((records) => {
        if (records.length > 0) {
          this.colorBookCategoryList = records;
        } else {
          this.colorBookCategoryList = [];
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryColorBookApi(this.dataForm).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.dataList = records;
          this.totalPage = totalRecords;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },

    // 删除
    deleteDataHandle(id) {
      this.$confirm(this.$t('common.buttons.remove.tips'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.buttons.confirm'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        type: 'warning'
      }).then(() => {
        deleteColorBookDataApi(id).then(() => {
          this.$baseMessage(this.$t('common.tips.deletedSuccessfully'), "success");
          this.getAttrTableCols()
        })
      })
    },
  },
};
</script>
