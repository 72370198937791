var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"box-card-component"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.title === '产品概览')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.proOverview),function(item,index){return [_c('span',{key:index,staticClass:"pro",style:({ width: 100 / _vm.proOverview.length + '%' })},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-caret-right"})])]})],2),(_vm.productOverview)?_c('div',[_c('span',{staticClass:"pro-color",style:({ width: 100 / _vm.proOverview.length + '%' })},[_vm._v(_vm._s(_vm.productOverview.total))]),_c('span',{staticClass:"pro-color",style:({ width: 100 / _vm.proOverview.length + '%' })},[_vm._v(_vm._s(_vm.productOverview.production))]),_c('span',{staticClass:"pro-color",style:({ width: 100 / _vm.proOverview.length + '%' })},[_vm._v(_vm._s(_vm.productOverview.test))]),_c('span',{staticClass:"pro-color",style:({ width: 100 / _vm.proOverview.length + '%' })},[_vm._v(_vm._s(_vm.productOverview.process))])]):_vm._e()]):(_vm.title === '设备概览')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.devOverview),function(item,index){return [_c('span',{key:index,staticClass:"dev-center",style:({ width: 100 / _vm.devOverview.length + '%' })},[_vm._v(_vm._s(item))])]})],2),_c('div',[_vm._l((_vm.devList),function(item,index){return [_c('span',{key:index,staticClass:"dev-center",style:({ width: 100 / _vm.devOverview.length + '%' })},[_vm._v(_vm._s(item))])]})],2)]):(_vm.title === '搜索概览')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.serOverview),function(item,index){return [_c('span',{key:index,staticClass:"ser",style:({
            width: 100 / _vm.serOverview.length + '%',
            margin: '0px 0px 0px 10px'
          })},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-caret-right"})])]})],2),_c('div',[[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['searchCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['handleCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['noHandleCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['searchPersonCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['perCount'] : '0'))])]],2)]):(_vm.title === '搜索匹配率')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.serOverview_),function(item,index){return [_c('span',{key:index,staticClass:"ser",style:({
            width: 100 / _vm.serOverview_.length + '%',
            margin: '0px 0px 0px 10px'
          })},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-caret-right"})])]})],2),_c('div',[[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['matchingCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['referenceCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['notMatchingCount'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.serOverview_.length + '%' })},[_vm._v(_vm._s(_vm.serList ? _vm.serList['matchingRate'] : '0'))])]],2)]):(_vm.title === '员工概况')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.empOverview),function(item,index){return [_c('span',{key:index,staticClass:"ser",style:({
            width: 100 / _vm.empOverview.length + '%',
            margin: '0px 0px 0px 10px'
          })},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-caret-right"})])]})],2),_c('div',[[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.empOverview.length + '%' })},[_vm._v(_vm._s(_vm.employee ? _vm.employee['brandManager'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.empOverview.length + '%' })},[_vm._v(_vm._s(_vm.employee ? _vm.employee['factoryManager'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.empOverview.length + '%' })},[_vm._v(_vm._s(_vm.employee ? _vm.employee['inputEmployee'] : '0'))])]],2)]):(_vm.title === '产品新增')?_c('div',{staticClass:"main"},[_c('div',[_vm._l((_vm.proOverview2),function(item,index){return [_c('span',{key:index,staticClass:"ser",style:({
            width: 100 / _vm.proOverview2.length + '%',
            margin: '0px 0px 0px 10px'
          })},[_vm._v(_vm._s(item)),_c('i',{staticClass:"el-icon-caret-right"})])]})],2),_c('div',[[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.proOverview2.length + '%' })},[_vm._v(_vm._s(_vm.addList ? _vm.addList['today'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.proOverview2.length + '%' })},[_vm._v(_vm._s(_vm.addList ? _vm.addList['yesterday'] : '0'))])],[_c('span',{staticClass:"ser",style:({ width: 100 / _vm.proOverview2.length + '%' })},[_vm._v(_vm._s(_vm.addList ? _vm.addList['nowmonth'] : '0'))])]],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }