<template>
  <div class="ces-search">
    <el-form
      class="form"
      ref="refForm"
      size="small"
      inline
      :label-width="labelWidth"
    >
      <el-form-item
        v-for="item in searchForm"
        :label="item.label"
        :key="item.prop"
        class="formItem"
      >
        <!-- 输入框 -->
        <el-input
          v-if="item.type === 'Input'"
          v-model="searchData[item.prop]"
          size="small"
          :style="{ width: item.width }"
        ></el-input>
        <!-- 下拉框 -->
        <el-select
          v-if="item.type === 'Select'"
          clearable
          v-model="searchData[item.prop]"
          size="small"
          @change="
            searchForm[0].notIncluded
              ? setNotIncluded(item)
              : item.change && item.change(searchData[item.prop])
          "
          :style="{ width: item.width }"
          :disabled="viewdataVisible"
        >
          <el-option
            v-for="op in item.valueList"
            :label="op.label"
            :value="op.value"
            :key="op.value"
            size="small"
          ></el-option>
        </el-select>
        <!-- 下拉框 多选-->
        <el-select
          v-if="item.type === 'SelectMultiple'"
          multiple
          v-model="searchData[item.prop]"
          size="small"
          @change="
            searchForm[0].notIncluded
              ? setNotIncluded(item)
              : item.change && item.change(searchData[item.prop])
          "
          :style="{ width: item.width }"
          :disabled="viewdataVisible"
        >
          <el-option
            v-for="op in item.valueList"
            :label="op.label"
            :value="op.value"
            :key="op.value"
            size="small"
          ></el-option>
        </el-select>
        <!-- 单选 -->
        <el-radio-group
          v-if="item.type === 'Radio'"
          v-model="searchData[item.prop]"
          :disabled="viewdataVisible"
        >
          <el-radio
            v-for="ra in item.valueList"
            :label="ra.value"
            :key="ra.value"
            >{{ ra.label }}</el-radio
          >
        </el-radio-group>
        <!-- 单选按钮 -->
        <el-radio-group
          v-if="item.type === 'RadioButton'"
          v-model="searchData[item.prop]"
          @change="item.change && item.change(searchData[item.prop])"
          :disabled="viewdataVisible"
        >
          <el-radio-button
            v-for="ra in item.valueList"
            :label="ra.value"
            :key="ra.value"
            >{{ ra.label }}</el-radio-button
          >
        </el-radio-group>
        <!-- 复选框 -->
        <el-checkbox-group
          v-if="item.type === 'Checkbox'"
          v-model="searchData[item.prop]"
          :disabled="viewdataVisible"
        >
          <el-checkbox
            v-for="ch in item.valueList"
            :label="ch.value"
            :key="ch.value"
            >{{ ch.label }}</el-checkbox
          >
        </el-checkbox-group>

        <!--  级联 -->
        <el-cascader
          v-if="item.type === 'Cascader'"
          clearable
          :options="item.valueList"
          :label="item.valueList.label"
          size="small"
          v-model="searchData[item.prop]"
          :props="{ checkStrictly: true, children: 'childrenList' }"
          :disabled="viewdataVisible"
          @change="
            searchForm[0].notIncluded
              ? setNotIncluded(item)
              : item.change && item.change(searchData[item.prop])
          "
          :style="{ width: item.width }"
        ></el-cascader>
        <!-- 日期 -->
        <el-date-picker
          v-if="item.type === 'Date'"
          v-model="searchData[item.prop]"
          size="small"
        ></el-date-picker>
        <!-- 时间 -->
        <el-time-select
          v-if="item.type === 'Time'"
          v-model="searchData[item.prop]"
          type=""
          size="small"
        ></el-time-select>
        <!-- 日期时间 -->
        <el-date-picker
          v-if="item.type === 'DateTime'"
          type="datetime"
          v-model="searchData[item.prop]"
          :disabled="item.disable && item.disable(searchData[item.prop])"
          size="small"
        ></el-date-picker>
        <!-- 日期范围 -->
        <el-date-picker
          v-if="item.type === 'datetimerange'"
          type="datetimerange"
          v-model="searchData[item.prop]"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        ></el-date-picker>
        <!-- 滑块 -->
        <!-- <el-slider v-if="item.type==='Slider'" v-model="searchData[item.prop]"></el-slider> -->
        <!-- 开关 -->
        <el-switch
          v-if="item.type === 'Switch'"
          v-model="searchData[item.prop]"
        ></el-switch>
      </el-form-item>
    </el-form>
    <el-form class="formT" inline v-if="isHandle">
      <el-form-item v-for="(item, index) in searchHandle" :key="index">
        <el-button
          :type="item.type"
          :size="item.size || size"
          @click="item.handle()"
          >{{ item.label }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    isHandle: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: String,
      default: "50px",
    },
    size: {
      type: String,
      default: "mini",
    },
    searchForm: {
      type: Array,
      default: [],
    },
    searchHandle: {
      type: Array,
      default: () => [],
    },
    searchData: {
      type: Object,
      default: {},
    },
    notIncluded: {
      type: Array,
      default: [],
    },
    viewdataVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    setNotIncluded(item) {
      console.log(this.searchForm[0].notIncluded);
      if (this.searchForm[0].notIncluded) {
        var val = item.prop.slice(2);
        var num = this.notIncluded.indexOf(val);
        console.log(
          item.type,
          this.searchData[item.prop],
          this.searchData[item.prop].indexOf("0")
        );
        if (num > -1) {
          this.notIncluded.splice(num, 1);
        } else if (
          this.searchData[item.prop] === "0" ||
          (item.type != "Select" &&
            this.searchData[item.prop].indexOf("0") > -1)
        ) {
          this.notIncluded.push(val);
        }
      }
    },
    verification() {
      var verificationvalue = this.searchForm.find((item) => {
        if (item.isUniversal == "1") {
          if (this.searchData[item.prop]) {
            console.log();
          } else {
            return item;
          }
        }
      });
      return verificationvalue;
    },
  },
};
</script>
<style lang="scss">
.ces-search {
  display: flex;
  justify-content: space-between;
}
.formItem {
  position: relative;
  .formItemLabel {
    position: absolute;
    left: -60px;
    top: 0;
  }
}
.el-form-item__label {
  width: 120px !important;
}
</style>
