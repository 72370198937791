<template>
  <div class="app-container colorcompare">
    <div
      type="flex"
      style="
        text-align: center;
        align-items: center;
        height: 75px;
        font-size: 20px;
        font-weight: 550;
        color: #000000;
        -webkit-text-stroke: 1 #000000;
        text-stroke: 1 #000000;
      "
    >
      <div class="titleone">
        <img
          :src="require('@/icons/svg/colorlogo.png')"
          style="'width:84px; height: 27px;margin-top:-10px;'"
        />L*a*b*色差比较
      </div>
    </div>

    <div class="contentBox">
      <el-row
        type="flex"
        align="middle"
        style="
          text-align: center;
          border-bottom: 2px solid #d2d2d2;
          height: 56px;
        "
      >
        <el-col :span="2" style="font-weight: 550">参与比较</el-col>
        <el-col :span="3" class="titleText">样式名称</el-col>
        <el-col :span="7" class="titleText">查询方式</el-col>
        <el-col :span="2" class="titleText">LAB</el-col>
        <el-col
          :span="2"
          v-for="(x4, index) in proLab"
          :key="'labtitle' + index"
          class="titleText"
          >{{ x4 }}</el-col
        >
        <el-col :span="4" class="titleText">色彩预览</el-col>
      </el-row>
      <el-row
        v-for="(x, index) in colorCompareList"
        :key="'row' + index"
        style="border-bottom: 2px solid rgb(210, 210, 210); height: 200px"
      >
        <el-col :span="2" style="height: 100%; text-align: center">
          <el-row
            :style="{
              'margin-top':
                index != 0 && colorCompareList.length > 2 ? '40px' : '70px'
            }"
            ><el-checkbox
              v-model="colorCompareList[index].isChecked"
              size="medium"
              @change="isChecked(index)"
              :disabled="index == 0 ? true : false"
            ></el-checkbox
          ></el-row>
          <el-row
            v-if="index > 0 && colorCompareList.length > 2"
            style="margin-top: 20px"
          >
            <el-button @click="handleDelSample(index)">移除</el-button>
          </el-row>
        </el-col>
        <el-col
          :span="3"
          style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            line-height: 180px;
            text-align: center;
          "
        >
          <el-row class="inputkuang">
            <el-input
              v-model="x.itemName"
              :placeholder="x.itemName"
              style="width: 60%"
              size="small"
              @input="setItemName(index)"
            ></el-input></el-row
        ></el-col>
        <el-col :span="7" style="padding: 13px 8px; height: 100%">
          <el-row type="flex" align="middle" style="height: 40px">
            <el-col :span="24">
              <el-radio-group v-model="x.checkType">
                <el-radio :label="0">输入编号</el-radio>
                <el-radio :label="1">输入Lab值</el-radio>
                <el-radio :label="2">从设备获取</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row
            align="middle"
            style="
              height: 90px;
              justify-content: center;
              vertical-align: middle;
            "
          >
            <el-col :span="24" style="height: 90px">
              <div
                v-show="x.checkType == 0"
                class="inputkuangtwo"
                style="line-height: 90px; white-space: nowrap"
              >
                <el-autocomplete
                  v-model="x.productNo"
                  :fetch-suggestions="querySearch"
                  placeholder="产品号"
                  :trigger-on-focus="true"
                  size="small"
                  :debounce="10"
                  ref="mycolorNoType"
                  style="width: 70%"
                  @select="
                    (item) => {
                      handleSelect(item, index)
                    }
                  "
                  @input="inputValue = x.productNo"
                  @blur="inputValue = ''"
                  @focus="inputValue = x.productNo"
                ></el-autocomplete>
                <el-button
                  size="small"
                  type="primary"
                  @click="colorComparison()"
                  style="margin-left: 2px; padding: 9px 10px"
                  >查询</el-button
                >
              </div>
              <div v-show="x.checkType == 1" class="input12">
                <el-row v-for="(x4, index1) in proLab" :key="index1">
                  <el-col :span="4">
                    <el-input
                      :placeholder="x4"
                      size="small"
                      @input="change($event)"
                      :disabled="true"
                    ></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input
                      v-model="x['l' + labAngles[index1]]"
                      :placeholder="'L*'"
                      size="small"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input
                      v-model="x['a' + labAngles[index1]]"
                      :placeholder="'a*'"
                      size="small"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-input
                      v-model="x['b' + labAngles[index1]]"
                      :placeholder="'b*'"
                      size="small"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button
                      v-show="index1 == 1"
                      size="small"
                      type="primary"
                      @click="colorComparison()"
                      style="margin-left: 2px; padding: 9px 10px"
                      >查询
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <div class="errorMessage">{{ errorMessage[index] }}</div>
                </el-row>
              </div>
              <div v-show="x.checkType == 2" style="line-height: 90px">
                <el-button
                  size="small"
                  type="primary"
                  @click="syncDevice(index)"
                  style="margin-left: 2px; padding: 9px 10px"
                  :loading="loading"
                  >读取设备</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="2"
          style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          "
        >
          <el-row class="row-text">L*</el-row>
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2"
            >A*</el-row
          >
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2"
            >B*</el-row
          >
        </el-col>
        <el-col
          :span="2"
          v-for="(x4, index) in proLab"
          :key="'lab' + index"
          style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          "
        >
          <el-row class="row-text">{{ x['l' + labAngles[index]] }}</el-row>
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2">{{
            x['a' + labAngles[index]]
          }}</el-row>
          <el-row class="row-text" style="border-top: 2px solid #d2d2d2">{{
            x['b' + labAngles[index]]
          }}</el-row>
        </el-col>

        <el-col
          :span="4"
          style="
            border-left: 2px solid #d2d2d2;
            height: 100%;
            text-align: center;
          "
        >
          <div
            style="
              display: flex;
              justify-content: center;
              height: 100%;
              line-height: 180px;
            "
          >
            <div :style="{ backgroundColor: x.hex }" class="pic"></div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row
      v-if="colorCompareList.length < 51"
      style="text-align: right; margin-top: 10px"
    >
      <el-button @click="handleAddSample">新增试样</el-button>
    </el-row>

    <div
      type="flex"
      class="twotitle"
      style="
        text-align: center;
        height: 75px;
        font-size: 20px;
        font-weight: 550;
        color: #000000;
        -webkit-text-stroke: 1 #000000;
        text-stroke: 1 #000000;
      "
    >
      <div class="titletwo">
        <img
          :src="require('@/icons/svg/colorlogo.png')"
          style="'width:84px; height: 27px;'"
        />L*a*b*色差比较结果
      </div>
      <div
        v-if="testList && testList.length > 0"
        style="'font-size:14px;color: #6F73FF;'"
        class="daochu"
      >
        <el-button
          size="small"
          type="primary"
          @click="handleSave()"
          style="margin-right: 30px"
          :loading="hisloading"
          >保存</el-button
        >
        <el-button
          v-if="dataForm.id"
          size="small"
          type="primary"
          @click="handleSaveAs()"
          style="margin-right: 30px"
          :loading="hisloading"
          >另存为</el-button
        >
        <a @click="exportExcelHandle()">
          <div class="daochu_">
            <img
              :src="require('@/icons/svg/daochu.png')"
              style="'width:15px; height: 15px;'"
            />
            导出报告
          </div>
        </a>
      </div>
    </div>
    <div class="contentBoxtwo">
      <div
        v-for="(testItem, index1) in testList"
        :key="index1"
        style="border-bottom: 2px solid #d2d2d2"
      >
        <el-row
          type="flex"
          style="
            text-align: center;
            border-bottom: 2px solid #d2d2d2;
            height: 40px;
          "
        >
          <el-col
            :span="24"
            style="
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              height: 40px;
              line-height: 8px;
            "
          >
            <p>{{ testItem.itemName }}</p>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          align="middle"
          style="font-size: 14px; height: 132px; font-weight: 550"
        >
          <el-col
            :span="3"
            style="'border-right: 2px solid #D2D2D2;height:132px;'"
          >
            <el-row
              type="flex"
              style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 36px;
              "
            >
              <el-col :span="24" style="line-height: 9px">
                <p>综合</p>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              style="height: 98px; line-height: 48px; text-align: center"
            >
              <el-col
                :span="24"
                style="font-size: 24px; font-weight: 500; color: #6f73ff"
              >
                <p>{{ testItem.del2000 }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="7"
            v-for="(resultItem, index2) in testItem.compareResultList"
            :key="index2"
            style="padding: 0px; height: 132px; font-weight: 500"
          >
            <el-row
              type="flex"
              style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 36px;
                line-height: 9px;
              "
            >
              <el-col :span="24">
                <p>{{ resultItem.resultName }}</p>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                color: #6f73ff;
                height: 32px;
                line-height: 6px;
              "
            >
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>ΔL*</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>Δa*</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>Δb*</p>
              </el-col>
              <el-col :span="6">
                <p>ΔE*</p>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              style="
                text-align: center;
                border-bottom: 2px solid #d2d2d2;
                height: 32px;
                line-height: 6px;
              "
            >
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.deL }}</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.dea }}</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.deb }}</p>
              </el-col>
              <el-col :span="6">
                <p>{{ resultItem.dee }}</p>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              style="text-align: center; height: 32px; line-height: 6px"
            >
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.deLStr }}</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.deaStr }}</p>
              </el-col>
              <el-col
                :span="6"
                style="border-right: 2px solid #d2d2d2; height: 32px"
              >
                <p>{{ resultItem.debStr }}</p>
              </el-col>
              <el-col :span="6">
                <p>{{ resultItem.deeStr }}</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        title="请输入比色名称"
        width="30%"
      >
        <el-input
          v-model="dataForm.colorCompareName"
          placeholder=""
          size="small"
        >
        </el-input>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSaveColorCompareHistory()"
              >确定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
  </div>
</template>

<script>
import { queryProductApi } from '@/api/modules/product'
import {
  infoColorCompareHistoryApi,
  saveColorCompareHistoryApi
} from '@/api/modules/colorCompareHistory'
import { setDeviceState, syncDeviceApi } from '@/api/modules/device'
import { colorComparisonApi } from '@/api/modules/colorCompare'
import { axiosDownFile } from '@/utils/index'
export default {
  data() {
    return {
      websock: null,
      activeIndex: '',
      loading: false,
      copydata: [],
      deviceBootShow: false,
      hisloading: false,
      inputValue: '',
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      device: {
        deviceId: ''
      },
      colorCompareList: [],
      proLab: ['25°', '45°', '75°'],
      labAngles: ['25', '45', '75'],
      deviceList: [],
      testList: [],
      testShow: [true, false],
      is_query: false,
      dialogVisible: false, //输入比色名称弹框
      // 保存动作 1.新增 2.编辑 3.另存为
      saveAction: 1,
      errorMessage: [],
      dataForm: {
        id: '',
        colorCompareName: '' //比色名称
      },
      dataListSelections: []
    }
  },
  components: {
    DeviceBoot: () => import('@/components/DeviceBoot')
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '' && value !== null) {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = ''
      }
      return realVal
    }
  },
  activated() {
    var query = this.$route.query
    if (query && query.id) {
      var id = query.id
      this.getInfo(id)
    } else {
      this.dataForm = {
        id: '',
        colorCompareName: ''
      }
      this.testList = []
      this.colorCompareList = [
        {
          isChecked: true,
          itemName: '标样',
          checkType: 0,
          hex: '',
          productNo: '',
          l25: '',
          a25: '',
          b25: '',
          l25: '',
          a45: '',
          b45: '',
          l75: '',
          a75: '',
          b75: ''
        },
        {
          isChecked: true,
          itemName: '试样1',
          checkType: 0,
          hex: '',
          productNo: '',
          l25: '',
          a25: '',
          b25: '',
          l25: '',
          a45: '',
          b45: '',
          l75: '',
          a75: '',
          b75: ''
        }
      ]
    }
  },
  methods: {
    handleAddSample() {
      this.colorCompareList.push({
        isChecked: true,
        itemName: '试样' + this.colorCompareList.length,
        checkType: 0,
        hex: '',
        productNo: '',
        l25: '',
        a25: '',
        b25: '',
        l25: '',
        a45: '',
        b45: '',
        l75: '',
        a75: '',
        b75: ''
      })
    },
    handleDelSample(index) {
      this.$confirm(`是否确认删除此试样?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.colorCompareList.splice(index, 1)
      })
    },
    // 保存
    handleSaveColorCompareHistory() {
      let params = {
        colorCompareName: this.dataForm.colorCompareName,
        colorCompareList: this.colorCompareList
      }
      if (this.saveAction === 2) {
        this.$confirm(`是否覆盖原结果?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          params.id = this.dataForm.id
          this.saveColorCompareHistory(params)
        })
      } else {
        this.saveColorCompareHistory(params)
      }
    },
    saveColorCompareHistory(params) {
      saveColorCompareHistoryApi(params)
        .then((response) => {
          this.$baseMessage('操作成功', 'success')
          this.dialogVisible = false
          if (this.saveAction === 1 || this.saveAction === 3) {
            // 新增/另存为
            this.$router.push({
              path: '/colorCompare',
              query: { id: response }
            })
            this.dataForm.id = response
          }
        })
        .finally(() => {
          this.hisloading = false
        })
    },
    handleSave() {
      if (this.dataForm.id) {
        this.saveAction = 2
      } else {
        this.saveAction = 1
      }
      this.dialogVisible = true
    },
    handleSaveAs() {
      this.saveAction = 3
      this.dialogVisible = true
    },
    handleSelect(item, index) {
      this.colorCompareList[index].productId = item.id
    },
    querySearch(queryString, cb) {
      if (queryString && this.inputValue.length > 2) {
        setTimeout(() => {
          queryProductApi({ productNo: this.inputValue }).then(
            ({ records, totalRecords }) => {
              if (totalRecords > 0) {
                var productNos = []

                productNos = records.map((v) => {
                  let name = v.productNo
                  if (v.productOrder) {
                    name += '-' + v.productOrder
                  }
                  var obj = {
                    id: v.id,
                    value: name
                  }
                  return obj
                })

                if (productNos.length <= 0) {
                  return this.$message.error('没有匹配到相关产品')
                }

                cb(productNos)

                records.forEach((item) => {
                  if (item.searchData) {
                    Object.keys(item.searchData).forEach((key) => {
                      item[key] = item.searchData[key]
                    })
                  }
                })
              }
            }
          )
        }, 500)
      }
    },

    getInfo(id) {
      infoColorCompareHistoryApi(id).then((response) => {
        this.dataForm.id = id
        this.dataForm.colorCompareName = response.colorCompareName
        this.colorCompareList = response.colorCompareList
        this.$set(this.colorCompareList)
        this.getE00value()
      })
    },

    //清空input框数据
    clear(index) {
      //循环输入框的内容把数据清理掉
      this.pro[index].productNo = ''
      //遍历数据显示部分的数据，并清理掉
      this.$forceUpdate()
      // this.getE00value(index);
    },

    isChecked(index) {
      this.testShow[index - 1] = this.colorCompareList[index].isChecked
      this.getE00value()
    },

    setItemName(index) {
      if (index >= 1 && this.colorCompareList[index].isChecked) {
        this.testList[index - 1].itemName =
          this.colorCompareList[index].itemName
      }
    },
    //根据产品编号和LAB值进行计算（通过失去焦点事件触发）
    getE00value() {
      colorComparisonApi(this.colorCompareList).then((response) => {
        this.colorCompareList = response.colorCompareList
        if (response.colorCompareResults) {
          this.testList = response.colorCompareResults
        } else {
          this.testList = []
        }
        this.$forceUpdate()
        this.copydata = JSON.parse(JSON.stringify(this.colorCompareList))
      })
    },
    //设备引导
    handleDeviceBoot() {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init()
        })
      })
    },
    //设备同步
    syncDevice(index) {
      this.loading = true
      this.device.deviceId = this.$store.getters.userDevice.id
      if (!this.device.deviceId) {
        this.loading = false
        this.handleDeviceBoot()
        this.device.deviceId = this.$store.getters.userDevice.id
      }
      syncDeviceApi(this.$store.getters.userDevice.id).then(() => {
        this.initWebSocket()
        this.activeIndex = index
        this.timer = setInterval(() => {
          //如果已经同步就关闭定时任务
          this.timerNum = this.timerNum + 1
          console.log(this.timerNum)
          if (this.timerNum == 4) {
            this.clearTimer() // 关闭定时器
            this.loading = false
            this.colorCompareList[this.activeIndex].l25 = ''
            this.colorCompareList[this.activeIndex].a25 = ''
            this.colorCompareList[this.activeIndex].b25 = ''
            this.colorCompareList[this.activeIndex].l25 = ''
            this.colorCompareList[this.activeIndex].a45 = ''
            this.colorCompareList[this.activeIndex].b45 = ''
            this.colorCompareList[this.activeIndex].l75 = ''
            this.colorCompareList[this.activeIndex].a75 = ''
            this.colorCompareList[this.activeIndex].b75 = ''
            this.colorCompareList[this.activeIndex].hex = ''
            this.$message.error('数据同步失败')
            this.setDeviceState()
            this.getDataLAB(this.activeIndex)
          } else {
            this.$message.warning('数据同步中，请确认设备是否有待同步数据')
          }
        }, 5000)
      })
    },
    setDeviceState() {
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      setDeviceState(params).then(({ data }) => {})
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    change(e) {
      this.$forceUpdate()
    },
    //选择设备显示在页面
    handleCommand(command) {
      //this.$message('click on item ' + command);
      this.device = command
    },
    //根据设备读取按钮传的数据计算色差值
    getDataLAB(index) {
      this.getE00value()
    },
    colorComparison() {
      if (this.validateLABValues()) {
        this.getE00value()
      }

      this.$forceUpdate()
    },
    validateLABValues() {
      for (let index in this.colorCompareList) {
        let colorCompare = this.colorCompareList[index]

        // 先重置错误
        this.errorMessage[index] = null

        // 如果没有手动输入，不用校验了
        if (colorCompare.checkType != 1) {
          continue
        }

        // 校验L值
        for (let angle in this.labAngles) {
          // 校验LAB值
          var lVal = colorCompare['l' + this.labAngles[angle]]
          var aVal = colorCompare['a' + this.labAngles[angle]]
          var bVal = colorCompare['b' + this.labAngles[angle]]

          if (
            !(
              this.isNotEmpty(lVal) &&
              lVal >= 0 &&
              lVal <= 100 &&
              this.isNotEmpty(aVal) &&
              aVal >= -128 &&
              aVal <= 127 &&
              this.isNotEmpty(bVal) &&
              bVal >= -128 &&
              bVal <= 127
            )
          ) {
            this.errorMessage[index] =
              'LAB值不在规定范围内：L∈[0,100]，a∈[-128,127]，b∈[-128,127]'
          }
        }
      }

      var isValid = true

      this.errorMessage.forEach((message) => {
        if (message) {
          isValid = false
        }
      })

      return isValid
    },
    isNotEmpty(obj1) {
      if (obj1 == null || obj1 == undefined) {
        return false
      }
      let isObjectEmpty = false
      for (let obj in obj1) {
        if (obj1.hasOwnProperty(obj)) {
          isObjectEmpty = false
          break
        }
      }
      return !isObjectEmpty
    },
    //导出比色结果excel
    exportExcelHandle() {
      axiosDownFile(`api/c/colorCompare/exportExcel`, this.colorCompareList)
      return
    },
    initWebSocket() {
      //初始化weosocket
      //ws地址
      if (this.websock) {
        this.websock.close()
      }
      var id = this.$store.getters.userDevice.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      // this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      console.log('数据接受')
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      var data = retData.data
      if (code == '0') {
        this.colorCompareList[this.activeIndex].l25 = data.l1
        this.colorCompareList[this.activeIndex].a25 = data.a1
        this.colorCompareList[this.activeIndex].b25 = data.b1
        this.colorCompareList[this.activeIndex].l45 = data.l2
        this.colorCompareList[this.activeIndex].a45 = data.a2
        this.colorCompareList[this.activeIndex].b45 = data.b2
        this.colorCompareList[this.activeIndex].l75 = data.l3
        this.colorCompareList[this.activeIndex].a75 = data.a3
        this.colorCompareList[this.activeIndex].b75 = data.b3
        //在数据同步后赋值给dataForm时调用接口方法，把数据同步返回的数据给赋值到参数上
        this.getDataLAB(this.activeIndex)
        this.$message.success('同步成功')
        this.clearTimer() // 关闭定时器
        this.loading = false
        this.websock.close()
      } else {
        if (this.timerNum == 4) {
          this.colorCompareList[this.activeIndex].l25 = ''
          this.colorCompareList[this.activeIndex].a25 = ''
          this.colorCompareList[this.activeIndex].b25 = ''
          this.colorCompareList[this.activeIndex].l25 = ''
          this.colorCompareList[this.activeIndex].a45 = ''
          this.colorCompareList[this.activeIndex].b45 = ''
          this.colorCompareList[this.activeIndex].l75 = ''
          this.colorCompareList[this.activeIndex].a75 = ''
          this.colorCompareList[this.activeIndex].b75 = ''
          this.colorCompareList[this.activeIndex].hex = ''
          this.$message.error('数据同步失败')
          this.clearTimer() // 关闭定时器
          this.getDataLAB(this.activeIndex)
          this.loading = false
          this.websock.close()
        }
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error('WebSocket连接失败')
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      // console.log("connection closed (" + e.code + ")");
    }
  }
}
</script>
<style lang="css">
.errorMessage {
  font-weight: bold;
  padding: 4px;
  font-size: 8px;
  color: red;
  /* padding-bottom: 100px; */
}
</style>
<style lang="scss">
.colorcompare {
  .el-button--mini,
  .el-button--small {
    height: 32px;
    border-radius: 7px;
  }
  .el-radio {
    margin-right: 8px;
  }
  .el-button--primary {
    font-size: 14px;
    line-height: 14px;
  }
  element.style {
    margin-left: 5px;
  }
  .el-checkbox__inner {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .inputkuang {
    .el-input__inner {
      border: 2px solid #6f73ff;
      border-radius: 12px !important;
      color: #6f73ff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      height: 37px;
    }
  }
  .input12 {
    .el-input__inner {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 0px 3px;
    }
    input::-webkit-input-placeholder {
      color: rgba(86, 86, 86, 0.7) !important;
    }
  }
  .el-input__label {
    font-size: 16px;
  }
  .inputkuangtwo {
    .el-input__inner {
      border: 2px solid #b7b7b7;
      border-radius: 9px !important;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      height: 37px;
    }
    input::-webkit-input-placeholder {
      color: rgba(86, 86, 86, 0.7) !important;
    }
  }
  .el-checkbox__inner::after {
    height: 18px;
    width: 6px;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 3px;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    position: absolute;
    top: 3px;
    left: 10px;
  }
  .el-radio__inner {
    width: 15px;
    height: 15px;
  }
  .el-radio__label {
    font-size: 14px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: #ffffff;
    font-size: 16px;
  }
  .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #6f73ff;
  }
  .titleText {
    font-weight: 550;
    border-left: 2px solid #d2d2d2;
    height: 100%;
    line-height: 56px;
  }
}
</style>
<style lang="scss">
.colorcompare {
  margin: 0 20px;
  .titleone {
    position: relative;
    width: 230px;
    height: 75px;
    line-height: 75px;
    display: inline-block;
    text-align: right;
    > img {
      position: absolute;
      left: 0px;
      top: 35px;
    }
  }
  .twotitle {
    height: 75px;
    position: relative;
    .daochu {
      display: flex;
      height: 10px;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 32px;
      font-size: 14px;
      .daochu_ {
        position: relative;
        > img {
          position: absolute;
          left: -20px;
          top: 0px;
        }
      }
    }
    .titletwo {
      position: relative;
      width: 270px;
      height: 75px;
      line-height: 75px;
      display: inline-block;
      text-align: right;
      > img {
        position: absolute;
        left: 0px;
        top: 26px;
      }
    }
  }
  .contentBox {
    border: 2px solid #d2d2d2;
    border-bottom: 0;
    width: 100%;
    border-radius: 2px;
    .row-text {
      width: 100%;
      height: 60px;
      line-height: 60px;
    }
    .el-col-3 {
      > p {
        margin: 0 auto;
        color: #000000;
        font-size: 16px;
        font-weight: 550;
      }

      > span {
        color: #333333;
        font-size: 16px;
        display: block;
        line-height: 60px;
        font-weight: 500;
        text-align: left;
        padding: 0 15px;
      }

      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
    }

    .el-col-7 {
      border-left: 2px solid #d2d2d2;
      > div:nth-child(2) {
        margin: 10px;
      }
    }

    .li10 {
      //   padding: 30px 18px 20px;
      p {
        height: 60px;
        border-bottom: 2px solid #d2d2d2;
        font-size: 16px;
        color: #666666;
        line-height: 60px;
        margin: 0;
      }

      > p:nth-last-child(1) {
        border-bottom: 0;
      }
    }

    .tit {
      text-align: right;

      p {
        padding: 0 15px;
        color: #7285e0;
        margin: 0;
      }
    }

    .pic {
      margin: 13px auto;
      width: 80%;
      height: 85%;
      border-radius: 12px;
    }

    .itemNameClass {
      text-align: center;
    }
  }
  .contentBoxtwo {
    border: 2px solid #d2d2d2;
    border-bottom: 0px;
    width: 100%;
    border-radius: 2px;
    .el-col-3 {
      > p {
        color: #707070;
        font-size: 15px;
      }

      > span {
        color: #333333;
        font-size: 16px;
        display: block;
        line-height: 60px;
        text-align: left;
        padding: 0 15px;
      }
    }

    .el-col-7 {
      border-left: 2px solid #d2d2d2;
    }

    .li10 {
      //   padding: 30px 18px 20px;
      p {
        height: 60px;
        border-bottom: 2px solid #d2d2d2;
        font-size: 16px;
        color: #666666;
        line-height: 60px;
        margin: 0;
      }

      > p:nth-last-child(1) {
        border-bottom: 0;
      }
    }

    .tit {
      text-align: right;

      p {
        padding: 0 15px;
        color: #7285e0;
        margin: 0;
      }
    }

    .itemNameClass {
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
// .w100 {
//   width: 100px;
// }
// .mr10 {
//   margin-right: 10px;
// }
// .ml10 {
//   margin-left: 10px;
// }
// .li10 {
//   margin-top: 50px;
//   list-style: none;
//   /*overflow:hidden;
//     height:80px;*/
// }
// .li11 {
//   margin-top: 51px;
//   list-style: none;
// }
// .li11:nth-child(3) {
//   margin-top: 100px;
// }
// .li11:nth-child(4) {
//   margin-top: 130px;
// }
.b25 {
  height: 25px;
  //   margin-top: 137px;
  //   margin-left: 20px;
}

// .u1 {
//   margin-top: 110px;
// }

.el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
  text-align: center;
}
</style>
