<template>
  <el-dialog :title="$t('common.upload.file')" :close-on-click-modal="false" @close="closeHandle"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" label-width="25%" v-loading="dataLoading"
      style="width: 100%">
      <el-form-item :label="$t('colorbook.colorNumberBrand')" prop="colorBookBrandId">
        <el-select v-model="dataForm.colorBookBrandId" size="small" :placeholder="$t('accounts.common.brand')"
          @change="getColorBookCategory()">
          <el-option v-for="item in brandList" :key="item.id" :label="item.brandName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('attributes.ColorCodeClassification')" prop="colorBookCategoryId">
        <el-select v-model="dataForm.colorBookCategoryId" size="small"
          :placeholder="$t('attributes.ColorCodeClassification')">
          <el-option v-for="item in colorBookCategoryList" :key="item.id" :label="item.categoryName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('common.upload.file')">
        <el-upload drag :action="action" :http-request="uploadQtx" :before-upload="beforeUploadHandle" :multiple="true"
          :file-list="fileList" :show-file-list="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text"> {{ $t("common.upload.qtx.dragOrClickToUploadQtx")
            }}</div>
          <div class="el-upload__tip" slot="tip">
            {{ $t("common.upload.qtx.onlyQTXSupportedMaxSize") }}
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <p v-for="(item, index) in errorList" :key="index" style="color: #F56C6C">{{ item }}</p>
  </el-dialog>
</template>

<script>
import { importQtx as qtxImport } from "@/api/modules/colorbook";
import { queryColorBookCategoryApi } from "@/api/modules/colorbookcategory";
import { queryColorBookBrandApi } from "@/api/modules/colorbookbrand";
import { getToken } from '@/utils/auth';
export default {
  data() {
    return {
      action: `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}`,
      visible: false,
      dataLoading: false,
      successNum: 0,
      colorBookCategoryList: [],
      brandList: [],
      errorList: [],
      dataForm: {
        colorBookBrandId: "",
        colorBookCategoryId: "",
      },
      dataRule: {
        colorBookBrandId: [
          { required: true, message: this.$t('physicalColors.prompt.colorNumberBrand'), trigger: "change" },
        ]
      },
      fileList: [],
    };
  },
  methods: {
    async init(id) {
      await this.getBrandList();
      this.visible = true;
      this.getColorBookCategory();
    },
    // 上传之前
    beforeUploadHandle(file) {
      const isQtx =
        file.name.toString().indexOf(".qtx") > 0;
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isQtx) {
        this.$message.error(this.$t('common.upload.qtx.onlyQTXSupportedMaxSize'));
      }
      if (!isLt10M) {
        this.$message.error(this.$t('common.upload.qtx.onlyQTXSupportedMaxSize'));
      }
      const flag = isQtx && isLt10M;
      if (flag) {
        this.errorList = [];
      }
      return flag;
    },
    async getBrandList() {
      const { records, totalRecords } = await queryColorBookBrandApi();
      if (totalRecords > 0) {
        this.brandList = records;
      } else {
        this.brandList = [];
      }
      this.getColorBookCategory();
    },
    //分类下拉
    async getColorBookCategory() {
      this.dataForm.colorBookCategoryId = "";
      const params = {
        colorBookBrandId: this.dataForm.colorBookBrandId,
        pageSize: 100000,
      };
      const { records, totalRecords } = await queryColorBookCategoryApi(params);
      if (totalRecords > 0) {
        this.colorBookCategoryList = records;
      } else {
        this.colorBookCategoryList = [];
      }
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = [];
      this.$emit("refreshDataList");
    },
    //upload Qtx
    uploadQtx(item) {
      if (
        this.dataForm.colorBookBrandId == "" ||
        this.dataForm.colorBookBrandId == null ||
        this.dataForm.colorBookBrandId == undefined
      ) {
        this.$message.error(this.$t('physicalColors.prompt.colorNumberBrand'));
        return;
      }
      let importData = new FormData();
      importData.append("file", item.file);
      importData.append("brandId", this.dataForm.colorBookBrandId);
      importData.append("categoryId", (this.dataForm.colorBookCategoryId) ? this.dataForm.colorBookCategoryId : 0);
      console.log("222", importData)
      qtxImport(importData).then((response) => {
        if (response && response.length > 0) {
          this.errorList = response;
          this.$baseMessage("部分数据导入成功", "warning", 4000);
        } else {
          this.$baseMessage("数据导入成功", "success");
          this.visible = false;
          this.$emit("refreshDataList");
        }
      });
    },
  },
};
</script>
