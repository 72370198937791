<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()" size="small">
      <el-form-item prop="userName">
        <el-input v-model="dataForm.userName" :placeholder="$t('accounts.common.username')" clearable></el-input>
      </el-form-item>
      <el-form-item prop="nickName">
        <el-input v-model="dataForm.nickName" :placeholder="$t('accounts.users.fullName')" clearable></el-input>
      </el-form-item>
      <el-form-item prop="category">
        <el-input v-model="dataForm.category" :placeholder="$t('accounts.common.category')" clearable></el-input>
      </el-form-item>
      <el-form-item prop="roleIdList">
        <el-select v-model="dataForm.roleIdList" multiple :placeholder="$t('accounts.common.role')"
          style="width: 300px">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">{{ $t('common.buttons.search') }}</el-button>
        <el-button @click="reset('dataForm')" type="info">{{ $t('common.buttons.reset') }}</el-button>
        <el-button v-if="isAuth('user:add')" type="primary" @click="addOrUpdateHandle()">{{
          $t('common.buttons.createNew') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" tooltip-effect="dark" size="small"
      style="width: 100%">
      <el-table-column prop="userName" header-align="center" align="center" :label="$t('accounts.common.username')">
      </el-table-column>
      <el-table-column prop="nickName" header-align="center" align="center" :label="$t('accounts.users.fullName')">
      </el-table-column>
      <el-table-column prop="roleName" header-align="center" align="center" :label="$t('accounts.common.role')">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" :label="$t('login.forgotPassword.email')">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" :label="$t('user.tableLabel.phoneNumber')">
      </el-table-column>
      <el-table-column prop="category" header-align="center" align="center" :label="$t('accounts.common.category')">
      </el-table-column>
      <el-table-column prop="brandName" header-align="center" align="center" :label="$t('accounts.common.brand')">
      </el-table-column>
      <el-table-column prop="factoryName" header-align="center" align="center" :label="$t('accounts.common.factory')">
      </el-table-column>
      <el-table-column prop="isEnable" header-align="center" align="center" :label="$t('accounts.common.status')">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.isEnable">{{ $t('user.tableLabel.normal') }}</el-tag>
          <el-tag type="danger" v-else>{{ $t('user.tableLabel.disabled') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" width="180"
        :label="$t('common.table.dateCreated')">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150"
        :label="$t('common.table.action')">
        <template slot-scope="scope">
          <el-button v-if="isAuth('user:update')" type="text" size="small" style="color: #226F1E;"
            @click="addOrUpdateHandle(scope.row.id)">{{
              $t('common.buttons.edit') }}</el-button>
          <el-button v-if="isAuth('user:delete')" type="text" size="small" style="color: #f56c6c"
            @click="deleteHandle(scope.row.id)">{{ $t('common.buttons.remove') }}</el-button>
          <el-button v-if="isAuth('user:updatepwd')" type="text" size="small"
            @click="updatepasswordHandle(scope.row)">{{
              $t('accounts.common.changePassword') }}</el-button>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="restPage"></add-or-update>
    <update-password v-if="updatePasswordVisible" ref="updatePassword" @refreshDataList="getDataList"></update-password>
  </div>
</template>

<script>
import { queryRoleApi } from '@/api/modules/role'
import { queryUserApi, deleteUserApi } from '@/api/modules/user'
import AddOrUpdate from './components/user-add-or-update'
import UpdatePassword from './components/updatepassword'

export default {
  data() {
    return {
      dataForm: {
        loginname: '',
        nickName: '',
        category: '',
        pageNo: 1,
        pageSize: 10,
        roleIdList: [],
      },
      roleList: [],
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      updatePasswordVisible: false,
      addOrUpdateVisible: true
    }
  },
  components: {
    AddOrUpdate,
    UpdatePassword
  },
  created() {
    this.getDataList()
    this.getRoleListParams()
  },
  methods: {
    getRoleListParams() {
      queryRoleApi({ pageSize: 10000 }).then(({ records }) => {
        if (records && records.length > 0) {
          this.roleList = records
        }
      })
    },
    // 查询清空
    reset(dataForm) {
      this.$refs[dataForm].resetFields()
      this.restPage()
    },
    // 跳到第一页进行查询
    restPage() {
      this.dataForm.pageNo = 1
      this.getDataList()
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      queryUserApi(this.dataForm)
        .then(({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records
            this.totalPage = totalRecords
          } else {
            this.dataList = []
            this.totalPage = 0
          }
        })
        .finally(() => {
          this.dataListLoading = false
        })
    },
    //修改密码
    updatepasswordHandle(row) {
      this.updatePasswordVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassword.init(row)
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageNo = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('common.buttons.remove.tips'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.buttons.confirm'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        type: 'warning'
      }).then(() => {
        deleteUserApi(id).then(() => {
          this.$baseMessage(this.$t('common.tips.deletedSuccessfully'), "success");
          this.restPage()
        })
      })
    }
  },
  mounted() {
    this.getDataList()
    const params = this.$route.query.params
    if (params === 'proPer') {
      setTimeout(() => {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(undefined)
        })
      }, 1000)
    }
  }
}
</script>
