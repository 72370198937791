<template>
    <div class="content">
        <div class="content-1">
            <h2 class="title-1">隐私条款</h2>
            <div class="content-text1">
                非常感谢您选择和使用ESP色彩管理系统。
            </div>
            <div class="content-text1">
                为方便您登录、使用ESP色彩管理系统服务，以及ESP色彩管理系统为您提供更加个性化的用户体验和服务，您在使用ESP色彩管理系统服务时，我们可能会收集和使用您的相关信息。ESP色彩管理系统（以下简称“本软件”或“我们”）尊重并保护用户信息，并且将以高度勤勉和审慎的义务对待这些信息。我们希望通过本《ESP色彩管理系统
                隐私条款》（以下或简称“本条款”）向您说明，在您使用本软件提供的产品及服务时，我们如何收集、使用、存储和共享您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方法.
            </div>
            <div class="content-text1">
                在使用本软件服务前，请您务必仔细阅读并透彻理解本条款，在确认充分理解并同意后方使用相关产品和服务。一旦您以在线登录时勾选等任何方式确认接受本条款或使用本软件服务的，即视为您已充分阅读、理解并同意接受本条款的约束，本条款即在您与本软件之间产生法律效力
            </div>


            <h3 class="title-2">一、本条款的适用范围</h3>
            <div class="content-text1">
                1.1本条款适用于亿涂（苏州）互联网科技有限公司（以下简称“本软件”或“我们”）通过本软件官网以及本软件其他平台提供的产品和服务（服务包括向您提供页面浏览、网站登录服务，以及通过本软件网站向您提供的技术服务），本条款另有约定除外。
            </div>
            <div class="content-text1">
                1.2 在您获取、使用或购买本软件提供的任何产品或服务，并且 (i) 在您将您的个人信息提供给我们，并按照我们的处理目的而使用的情况下，或 (ii)
                在您委托我们处理您的个人信息的情况下，我们将根据本《本软件隐私条款》处理您的个人信息。
            </div>
            <div class="content-text1">
                1.3本条款策适用于您注册本软件账户所提交的所有信息、资料等，以及您使用本软件服务时提交的或本软件为了向您提供服务而收集的信息（客户数据除外），前述信息统称为“用户信息”。例如，名称、联系方式、邮箱等。
            </div>
            <div class="content-text1">
                1.4 客户数据是指您使用本软件产品及服务过程中以上传、传输、存储，或者以分析等任何方式处理的各类数据，包括但不限于您在日常经营活动中所收集、产生的用户的个人信息等。
                <div>1.4.1 您应确保客户数据是依法收集、使用（包括但不限于委托处理等）的，您不会也不曾以任何方式侵犯任何个人或实体的合法权利。</div>
                <div>1.4.2 您保证有权使用本软件产品及服务对客户数据进行存储、传输、分析和分发等任何处理，我们将按照您的指令、委托处理您的客户数据。</div>
                <div>1.4.3
                    您理解并同意，中国及其他国家、地区可能对数据存储、出境等有相关的规定，在您使用本软件产品及服务对客户数据进行存储、传输等操作前，您应当遵照相关地区适用的法律法规进行充分、必要的评估、审批，确保符合相关规定。
                </div>
            </div>
            <div class="content-text1">
                1.5 需要特别说明的是，作为本软件的用户，若您利用本软件的技术服务，为您的用户再行提供服务，因您的业务数据属于您所有，您应当另行与您的用户约定隐私保护条款。
            </div>

            <h3 class="title-2">二、我们如何收集您的信息</h3>
            <div class="content-text1">
                2.1 我们可能在以下几种情形下收集您的信息：
                <div>
                    2.1.1
                    您主动提交的信息，例如您或您授权的主体在本软件网站创建账户时，您须设置、确认您的登录密码，提供您在中华人民共和国境内手机号码以及邮箱。您提交的手机号码用于您注册、登录、绑定账户、接受验证码，并且作为您与本软件指定的联系方式之一，用来接受相关业务通知（如新品上线、服务变更等），并且本软件可通过此联系方式向您营销推广、宣传产品、发送业务通知（含账单）或与您进行业务沟通。
                </div>
                <div>
                    2.1.2 在您完成开通本软件账户后，我们提供具体服务，向您开具和寄送发票时，我们会收集您的发票收件地址，包括收件人姓名、详细地址、邮编、手机号码等信息。本软件可通过如上信息向您寄送发票。
                </div>
                <div>
                    2.1.3我们的员工或者合作伙伴代为提交、记录的信息，例如您委托本软件员工在本软件系统中填写、上传的您的相应信息。
                </div>
                <div>
                    2.1.4 我们在您使用我们的服务过程中以主动收集或产生相应的记录等方式收集的信息，例如在您登录本软件账号时后台记录下的登录时间，您使用特定本软件服务的操作记录等。
                </div>
                <div>
                    2.1.5在经过您同意后，我们可能会通过第三方SDK可直接收集您的个人信息。
                </div>
            </div>
            <div class="content-text1">
                2.2
                注册和管理账号涉及的信息与您管理、使用本软件账号、本软件服务等紧密相关（例如验证您的身份等），请您务必谨慎确认和维护；前述信息发生变化时，请及时进行更改，例如发生管理人员变动时，应提前对账号密码、联系人、联系电话等进行变更。
            </div>
            <div class="content-text1">
                2.3为提供持续服务和保证服务质量所需，在您使用本软件服务期间，我们将记录并保存您登录本软件账户和使用服务的相关信息（但不会包含进入该服务后的操作），包括但不限于：
                <div>
                    2.3.1 操作记录，我们会接收并记录您使用本软件服务的操作记录及相关信息，例如身份 ID、操作时间、操作的对象信息（资源 ID、工作区、所属产品等）等。
                </div>
                <div>
                    2.3.2 合同信息，您对于您购买的产品及服务采取线下交付或进行产品测试等，为履行合同所必需，您应提供联系人、联系方式、地址、开票信息、订单等必要信息。
                </div>
                <div>
                    2.3.3 其他信息，例如您基于使用本软件服务而产生的咨询记录、工单记录、报障记录和针对故障的排障过程（如通信或通话记录），本软件将通过记录、分析这些信息以便更及时响应您的帮助请求，以及用于改进服务。
                </div>
            </div>
            <div class="content-text1">
                2.4如果您需要加入本软件伙伴合作计划，需和本软件另行签署相关协议，为履行合同所必需，您应提供联系人、联系方式等必要信息。
            </div>
            <div class="content-text1">
                2.5我们在向您提供业务功能或具体服务时，我们会按照本条款以及服务条款的约定收集、使用、存储、对外提供及保护您的用户信息；超出本条款以及服务条款约定收集您的用户信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意后方收集您的信息；如您选择不提供前述信息，将会影响到您使用相应产品，但不会影响您使用本软件网站基本功能和其他产品。
            </div>

            <h3 class="title-2">三、我们如何使用收集的信息</h3>
            <div class="content-text1">
                为了向您提供优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能会出于如下目的，使用您提交以及我们收集的您的信息：
            </div>

            <div class="content-text1">
                3.1 向您提供服务。例如，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知；
            </div>
            <div class="content-text1">
                3.2 满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。
            </div>

            <div class="content-text1">
                3.3 服务优化和开发。例如，我们会根据本软件系统响应您的需求时所产生的信息，优化我们的服务。
            </div>
            <div class="content-text1">
                3.4
                提高您使用本软件、本软件关联公司和本软件的合作伙伴提供服务的安全性，确保操作环境安全与识别账号是否为异常状态。例如，我们会将您的信息用于安全防范、诈骗监测，以及判断您的账号安全等用途。例如，在处理相关纠纷、投诉时，为相关当事人提供有关信息以便更好的促进纠纷、投诉的处理、解决等。
            </div>
            <div class="content-text1">
                3.5
                向您推送相关业务消息（如产品消息、本软件动态等）和商业广告。例如，本软件可以通过您的手机号码、电子邮箱等联系方式以短信、电话、电子邮件等任何方式向您推送商业广告，宣传、推广本软件产品及服务或本软件平台上的其他服务。
            </div>
            <div class="content-text1">
                3.6 向您提供与您更加相关的服务。例如，基于您的信息统计、分析情况或者本软件服务的其他用户数据统计、分析情况，向您提供您可能感兴趣的类似功能或服务等。
            </div>
            <div class="content-text1">
                3.7 可能会邀请您参与关于本软件产品和服务的调查。
            </div>
            <div class="content-text1">
                3.8
                我们可能以本软件用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们可能将本软件用户使用某项或多项本软件服务的数据单独或与其他服务的用户使用数据进行整合、分析等，形成统计类用户报告、商业分析等信息（统称“统计信息”），在本软件内部或外部共享、发布、展示等，我们会确保统计信息不会涉及可以识别特定用户身份识别的信息。
            </div>
            <div class="content-text1">
                3.9 其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。
            </div>

            <h3 class="title-2">四、我们如何共享、转让、公开披露您的信息</h3>
            <div class="content-text1">
                4.1 我们可能将您的信息与我们的关联公司、第三方服务提供商、服务商及代理商等合作伙伴共享，仅用作下列用途：
                <div>4.1.1 提供“二、我们如何收集您的信息”中的相应功能或服务所必需，以及出于“三、我们如何使用收集的信息”中的部分所述目的所必需。</div>
                <div>4.1.2 履行我们在《本软件服务条款》或本条款中的义务、行使我们的权利。</div>
                <div>4.1.3
                    为提供更好的服务，我们可能委托第三方服务提供商、服务商及代理（统称“合作伙伴”）向您提供服务，包括但不限于客户服务、技术服务等，因此，为向您提供服务所必需，我们会与合作伙伴共享您的某些信息。例如，为完成身份验证等。
                </div>
                <div>
                    4.1.4 对于由我们与合作伙伴共同研发、提供的产品、服务，为实现您使用该产品、服务的目的，我们可能需要向合作伙伴共享您的部分信息。
                </div>
            </div>
            <div class="content-text1">
                如我们与上述任何第三方共享您的信息，我们仅会基于合法、正当、必要原则，在为提供服务所必需的范围内与其共享您的信息，并且我们将努力确保第三方在使用您的信息时遵守本条款及我们要求其遵守的其他适当的保密和安全措施，承诺不得将您的信息用于其他任何用途。
            </div>
            <div class="content-text1">
                4.2为保护本软件平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或本软件平台相关协议、规则披露关于您的用户信息。
            </div>
            <div class="content-text1">
                4.3随着我们的发展，本软件可能与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本条款的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
            </div>
            <div class="content-text1">
                4.4 根据相关法律等规定，在以下情形中，我们可以不经您同意而共享、转让、公开披露您的信息：
                <div>4.4.1 履行法律法规规定的义务相关的，例如执行有权机关的要求。</div>
                <div>4.4.2 与国家安全、国防安全直接相关的。</div>
                <div>4.4.3 与公共安全、公共卫生、重大公共利益直接<span style="color: red;">或间接</span>相关的。</div>
                <div>4.4.4 与刑事侦查、起诉、审判和判决执行等直接<span style="color: red;">或间接</span>相关的。</div>
                <div>4.4.5 出于维护用户或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的</div>
                <div>4.4.6 用户自行向社会公众公开的个人信息。</div>
                <div>4.4.7 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</div>
            </div>

            <h3 class="title-2">五、您如何管理您的信息</h3>
            <div class="content-text1">
                5.1
                您可以登录本软件网站查询、管理（修改、删除）使用本软件服务时而提交的基本用户信息。为保障您的账号安全、合法权益等，不同的信息查询、修改和删除可能有不同的要求，并且，基于技术逻辑、法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除（例如您无法更改已开通服务的账号
                ID）。对于本软件提供的产品或者服务所必需的用户信息，修改或删除后可能会影响到相应的产品或者服务的正常使用，本软件有权拒绝或停止提供受到影响的产品或者服务。
            </div>
            <div class="content-text1">
                5.2 对于您无法自行查阅的个人信息，您可以向我们提出查阅或复制请求，为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
            </div>
            <div class="content-text1">
                5.3 在以下情形中，您可以向我们提出更正或删除用户信息的请求：
                <div>5.3.1. 如果我们处理用户信息的行为违反法律法规；</div>
                <div>5.3.2. 如果我们收集、使用您的用户信息，却未征得您的明确同意；</div>
                <div>5.3.3. 如果我们处理个人信息的行为严重违反了与您的约定；</div>
                <div>5.3.4 如果我们收集、存储的您的信息有错误，且您无法自行更正的；</div>
                <div>5.3.5 如果针对您的信息的处理目的已实现、无法实现或者为实现处理目的不再必要的；</div>
                <div>5.3.6 如果我们已经停止向您提供相应的产品或者服务，或者保存期限已届满的；</div>
                <div>5.3.7 如果您撤回同意的。</div>
            </div>
            <div class="content-text1">
                为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
            </div>
            <div class="content-text1">
                5.4 您可以注销本软件账号。具体操作步骤如下：
                <div>5.4.1您可以联系客服（邮箱：<a style="color: blueviolet;">colorx_robot@aliyun.com</a>），协助您申请注销您的本软件账户。</div>
                <div>5.4.2注销成功后，该账号下所有服务、数据将被删除且无法恢复。在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除您的个人信息，或对其进行匿名化处理。</div>
            </div>

            <h3 class="title-2">六、我们如何使用 Cookie 及同类技术</h3>
            <div class="content-text1">
                6.1 我们可能会通过 Cookie 和其他相关技术收集和使用您的信息。我们使用 Cookie 的具体用途包括：
                <div>6.1.1 记住您的身份。例如：Cookie 有助于我们辨认您作为我们的用户的身份。</div>
                <div>6.1.2 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括但不限定制化页面、推荐等。</div>
            </div>
            <div class="content-text1">
                6.2 您可以通过浏览器设置拒绝或管理 Cookie。但请注意，如果停用 Cookie，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。
            </div>
            <div class="content-text1">
                6.3 通过 Cookie 记录的有关信息，将适用本条款。
            </div>


            <h3 class="title-2">七、我们如何保护和保存您的信息</h3>
            <div class="content-text1">
                7.1我们会按照相关法律法规的规定，将在中国大陆收集的用户信息存储于中国大陆境内。
            </div>
            <div class="content-text1">
                7.2我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本条款所述目的所需的期限内保留您的用户信息，除非受到法律法规、有关规定的允许或者为执行相关服务条款或本条款、处理投诉/纠纷，或者是保护他人的人身和财产安全或合法权益所合理必需的。超出上述用户信息保存期限后，我们会对您的个人信息进行删除或匿名化处理。
            </div>
            <div class="content-text1">
                7.3 我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。
                <div>7.3.1 我们使用各种安全技术以保障信息的安全。例如，我们将努力采取加密等安全保护措施，防止用户信息遭到未经授权的访问或修改、泄露、毁损或丢失。</div>
                <div>7.3.2 我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务。</div>
                <div>7.3.3
                    在不幸发生用户信息安全事件（泄露、丢失等），我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，并向您给出安全处理建议。难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。
                </div>
                <div>7.3.4 同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。</div>
            </div>
            <div class="content-text1">
                7.4
                为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识和措施。我们仅在因本软件过错直接导致您信息泄露的范围内承担责任，请您妥善保管您的账号及密码信息，避免您的信息泄露，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等信息。
            </div>
            <div class="content-text1">
                7.5 如出现本软件产品和服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
            </div>
            <h3 class="title-2">八、我们如何处理未成年人的个人信息</h3>
            <div class="content-text1">
                8.1.
                我们主要面向成人提供产品和服务。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权条款，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息，如果您的监护人不同意本条款的任何内容，您应当立即停止使用本软件。
            </div>
            <div class="content-text1">
                8.2. 对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
            </div>
            <div class="content-text1">
                8.3
                如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过本隐私条款第十条“如何联系我们”所述联系方式联系我们，我们会设法尽快删除相关数据。
            </div>

            <h3 class="title-2">九、条款的变更</h3>
            <div class="content-text1">
                9.1 我们的隐私保护条款可能修订，该等修订构成本条款的一部分。
            </div>
            <div class="content-text1">
                9.2 未经您明确同意，我们不会限制、剥夺您依据本隐私保护条款所应享有的权利。我们会在本软件官网专门的页面上发布针对隐私保护所做的任何修订。
            </div>
            <div class="content-text1">
                9.3
                如果，前述修订可能造成您在本条款下的权利的实质性减少，或扩大我们收集、使用信息的范围等重要规则的变更，我们将在修订生效前通过在本软件网站上显著位置提示或向您发送电子邮件或以其他方式通知您。在前述情况下，若您继续使用我们的服务，即表示您同意接受修订后的内容。
            </div>
            <h3 class="title-2">十、违约责任，如何联系我们</h3>
            <div class="content-text1" style="color: red;">
                由于企业不慎操作导致相关用户以及企业的信息泄露，对用户以及企业造成直接或者间接损失。企业将承担相关责任，针对用户以及企业的信息侵权行为将在10个工作日内改正，对用户导致相关损失将由我们承担，赔偿上限不超过用户在本企业消费金额3倍。相关分歧涉及诉讼或者仲裁，约定在苏州仲裁委员会或者苏州人民法院诉讼。
            </div>
            <div class="content-text1">
                如您对本条款或您对本软件对本隐私保护条款的实践以及操作上有任何疑问和建议，您可以联系客户（邮箱：<a
                    style="color: blueviolet;">colorx_robot@aliyun.com</a>）与我们联系，我们将尽快审核您反馈的问题，并在验证您的用户身份后的十五天内予以回复。
            </div>

            <!-- 结束 -->
        </div>

    </div>
</template>
<script>
import { cryptoAes } from '@/utils/index'
import { sendCode, getCodeApi } from '@/api/login'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import resize from '@/utils/resize'
export default {
    name: 'login',
    mixins: [resize],
    data() {
        const validatePassword = (rule, value, callback) => {

        }
        return {

        }
    },
    created() {
    },
    methods: {
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content-text1 {
    background-color: #F2F6FC;
    padding-top: 10px;
    width: 700px;
    font-size: 12px;
}</style>
  