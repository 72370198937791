<template>
  <el-dialog :title="dataForm.id ? $t('common.buttons.edit') : $t('common.buttons.createNew')"
    :close-on-click-modal="false" :visible.sync="visible" width="700px">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="120px" size="small">
      <el-form-item :label="$t('accounts.roles.roleName')" prop="roleName" class="role-name">
        <el-input v-model="dataForm.roleName" :placeholder="$t('accounts.roles.roleName')" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item :label="$t('common.table.comment')" prop="remarks">
        <el-input v-model="dataForm.remarks" :placeholder="$t('common.table.comment')" maxlength="150"
          type="textarea"></el-input>
      </el-form-item>
      <el-form-item size="mini" :label="$t('accounts.roles.permissions')">
        <el-tree :data="menuList" :props="menuListTreeProps" node-key="id" ref="menuListTree" :default-expand-all="true"
          check-strictly show-checkbox check- check-on-click-node>
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="sideClose" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" :loading="loading" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getTreeApi } from "@/api/modules/resource";
import { addOrUpdateRoleApi, getRoleApi } from "@/api/modules/role";
export default {
  data() {
    return {
      loading: false,
      dataLoading: false,
      visible: false,
      menuList: [],
      menuListTreeProps: {
        label: "translatedResourceName", // 更新label属性
        children: "childCategoryList",
      },
      dataForm: {
        id: 0,
        roleName: "",
        remarks: "",
      },
      dataRule: {
        roleName: [
          { required: true, message: this.$t('role.roleName.tips'), trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async init(id) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      const response = await getTreeApi();
      console.log('API response:', response.data); // 输出API返回的数据
      this.menuList = this.translateMenuList(response);
      console.log('Translated menu list:', this.menuList); // 输出转换后的数据
      await this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.$refs.menuListTree.setCheckedKeys([]);
      });
      if (id) {
        const data = await getRoleApi(id);
        this.dataForm = data;
        console.log('role12121212', data.resourceIdList)
        this.$refs.menuListTree.setCheckedKeys(data.resourceIdList);
      }
    },
    translateMenuList(data) {
      if (!Array.isArray(data)) {
        return [];
      }
      return data.map(item => {
        const translatedItem = {
          ...item,
          translatedResourceName: this.$t(item.resourceName) // 使用国际化翻译
        };
        if (Array.isArray(item.childCategoryList) && item.childCategoryList.length > 0) {
          translatedItem.childCategoryList = this.translateMenuList(item.childCategoryList);
        }
        return translatedItem;
      });
    },
    sideClose() {
      this.visible = false;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          this.dataForm.resourceIdList = [].concat(
            this.$refs.menuListTree.getCheckedKeys(),
            this.$refs.menuListTree.getHalfCheckedKeys()
          );
          addOrUpdateRoleApi(this.dataForm)
            .then(() => {
              this.$baseMessage(this.$t('physicalColors.prompt.action'), "success");
              this.sideClose();
              this.$emit("refreshDataList");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
