<template>
    <el-dialog
            :title="'设备授权'"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-divider></el-divider>
        <el-form :model="dataForm"  size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px"
                 v-loading="dataLoading" style="width:55%;margin:0  auto"  element-loading-text=""  element-loading-spinner="el-icon-loading"
        >

              <h3>设备信息</h3>
              <div class="div">
                  <el-form-item label="设备型号" prop="deviceModelName">
                      <el-input  style="width: 77%" v-model="dataForm.deviceModelName" placeholder="设备型号"  size="small" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="设备编号" prop="deviceNo">
                      <el-input style="width: 77%" v-model="dataForm.deviceNo" placeholder="设备编号"  size="small" disabled></el-input>
                  </el-form-item>
              </div>
            <h3>授权用户信息</h3>
            <div class="div">
                <el-form-item label="用户名" prop="description">
                    <el-select style="width: 77%" v-model="dataForm.userId" clearable placeholder="选择用户类型">
                        <el-option
                                v-for="item in userList"
                                :key="item.id"
                                :label="item.nickName ? item.nickName : item.username"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="授权期限" prop="time">
                    <template>
                        <div class="block">
                            <el-date-picker
                                    style="width: 90%"
                                    v-model="dataForm.time"
                                    type="daterange"
                                    align="right"
                                    range-separator="——"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </template>
                </el-form-item>
                <el-form-item label="授权状态" prop="state">
                    <template>
                        <el-radio v-model="dataForm.state" label="0">启用</el-radio>
                        <el-radio v-model="dataForm.state" label="1">停用</el-radio>
                    </template>
                </el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="closeSubmit()" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small"  :disabled="dataLoading">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import { addUserDeviceApi, getDeviceInfoApi} from '@/api/modules/deviceuser';
    import { queryUserApi } from "@/api/modules/user";
    export default {
        data () {
            return {
                userList:[],
                visible: false,
                dataLoading: false,
                dataForm: {
                    id: 0,
                    deviceModelName:'',
                    deviceNo:'',
                    userId:'',
                    time:[],
                    state:'0',
                }
            }
        },
        mounted() {
            this.getUserList();
        },
        methods: {
            init (id) {
                this.dataForm.id = id || 0;
                this.visible = true;
                this.$nextTick(() => {
                    this.$refs['dataForm'].resetFields();
                    if (id) {
                        getDeviceInfoApi(id).then((response) => {
                            this.dataForm.deviceModelName = response.deviceModelName;
                            this.dataForm.deviceNo = response.deviceNo;
                        });
                    }
                })
            },
            //获取用户下拉列表
            getUserList(){
                const params = {
                    pageSize: 100000
                };
                queryUserApi(params).then(({ records, totalRecords }) => {
                    if (totalRecords > 0) {
                        this.userList = records;
                    } else {
                        this.userList = [];
                    }
                });
            },
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate(async (valid) => {
                    if (valid) {
                        this.dataLoading = true;

                        var params = {
                            'deviceId': this.dataForm.id || undefined,
                            'userId': this.dataForm.userId,
                            'startDate': this.dataForm.time[0],
                            'endDate': this.dataForm.time[1],
                            'state': this.dataForm.state
                        }    

                        await addUserDeviceApi(params).then(() => {
                            this.$baseMessage("操作成功", "success");
                            this.$emit("refreshDataList");
                            this.visible = false;
                        })
                        .finally(() => {
                            this.dataLoading = false;
                        });
                    }
                })
            },
            //关闭
            closeSubmit(){
                this.dataForm.userId = '';
                this.visible = false;
            }
        }
    }
</script>
<style scoped>
    .div
    {
        /*width:400px;*/
        /*height:120px;*/
        border: 2px solid #ececec;
        box-shadow: 10px 10px 5px #888888;
        padding: 15px 0;
        margin-bottom: 50px;
    }
</style>