import request from "@/utils/request";

const url = "/c/attr";

// 获取列表
export function queryAttrApi(data) {
    if (!data) {
        data = { pageSize: 100000 }
    }
    return request({
        url: `${url}/query`,
        method: 'post',
        data
    })
}

// 获取信息
export function getAttrApi(id) {
    return request({
        url: `${url}/get/${id}`,
        method: 'get',
    })
}

// 添加
export function addOrUpdateAttrApi(data) {
    return request({
        url: `${url}/addOrUpdate`,
        method: 'put',
        data
    })
}




//类型下拉
export function getTypeDictInfoApi() {
    return request({
        url: `${url}/getAttrList`,
        method: 'get'
    })
}
