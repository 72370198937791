<template>
  <index :state="1" :addBtn="true" />
</template>

<script>
import Index from './index'
export default {
  data() {
    return {}
  },
  components: {
    Index
  }
}
</script>
