<template>
  <div class="app-container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
      <el-form-item prop="roleName">
        <el-input v-model="dataForm.roleName" :placeholder="$t('accounts.roles.roleName')" clearable
          maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()">{{ $t('common.buttons.search') }}</el-button>
        <el-button @click="reset('dataForm')" type="info">{{ $t('common.buttons.reset') }}</el-button>
        <el-button v-if="isAuth('role:add')" type="primary" @click="addOrUpdateHandle()">{{
          $t('common.buttons.createNew')
          }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" size="small" style="width: 100%">
      <el-table-column prop="roleName" header-align="center" align="center" :label="$t('accounts.roles.roleName')">
      </el-table-column>
      <el-table-column prop="remarks" header-align="center" align="center" :label="$t('common.table.comment')">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" width="180"
        :label="$t('common.table.dateCreated')">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150"
        :label="$t('common.table.action')">
        <template slot-scope="scope">
          <el-button v-if="isAuth('role:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{
            $t('common.buttons.edit') }}</el-button>
          <el-button v-if="isAuth('role:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">{{
            $t('common.buttons.remove') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="dataForm.pageNo"
      :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./components/role-add-or-update";
import { queryRoleApi, deleteRoleApi } from "@/api/modules/role";
export default {
  data() {
    return {
      dataForm: {
        roleName: "",
        pageNo: 1,
        pageSize: 10,
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  methods: {
    // 查询清空 修改  by葛海军
    reset(dataForm) {
      this.dataForm.roleName = "";
      this.restPage();
    },
    // 跳到第一页进行查询
    restPage() {
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      queryRoleApi(this.dataForm)
        .then(({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records;
            this.totalPage = totalRecords;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        })
        .finally(() => {
          this.dataListLoading = false;
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.dataForm.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('common.buttons.remove.tips'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.buttons.confirm'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        type: "warning",
      }).then(async () => {
        await deleteRoleApi(id);
        this.$baseMessage(this.$t('common.tips.deletedSuccessfully'), "success");
        this.getDataList();
      });
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>
