<template>
  <div>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="12">
        <factory-tabs @selectfactoryId="selectfactoryId" />
        <div class="info-wrapper" style="margin-top: 30px">
          <div>
            <span
              v-for="(item, index) in list0"
              :key="index"
              :style="{ width: 100 / list0.length + '%' }"
              >{{ item }}</span
            >
          </div>
          <div v-if="product">
            <span
              class="pro-color"
              :style="{ width: 100 / list0.length + '%' }"
              >{{ product.total }}</span
            >
            <span
              class="pro-color"
              :style="{ width: 100 / list0.length + '%' }"
              >{{ product.production }}</span
            >
            <span
              class="pro-color"
              :style="{ width: 100 / list0.length + '%' }"
              >{{ product.test }}</span
            >
            <span
              class="pro-color"
              :style="{ width: 100 / list0.length + '%' }"
              >{{ product.process }}</span
            >
          </div>
        </div>
        <div class="info-wrapper">
          <div>
            <template v-for="(item, index) in list2">
              <span :key="index" :style="{ width: 100 / list2.length + '%' }">{{
                item
              }}</span>
            </template>
          </div>
          <div v-if="factoryInfo">
            <template v-for="(item, index) in factoryInfo.device">
              <span :key="index" :style="{ width: 100 / list2.length + '%' }">{{
                item
              }}</span>
            </template>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="12" style="position: relative">
        <div
          style="
            width: 150px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
          "
        >
          <div>
            <el-dropdown @command="selectProductAttrId">
              <div class="dropdown" style="font-size: 16px">
                {{ productAttributeName
                }}<i class="el-icon-caret-bottom" style="float: right"></i>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="item.attributeName"
                    v-for="item in categoryList"
                    :key="item.id"
                    >{{ item.attributeName }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div>
            <img
              src="../../../../assets/switch.png"
              class="switch_img"
              @click="changetype"
            />
          </div>
        </div>
        <div class="chart-wrapper" v-if="type == '2'">
          <pie-chart
            v-if="productCategory && productCategory.length > 0"
            :chartData="productCategory"
            :chartIsLoad="chartIsLoad"
          />
          <div class="no-more" v-else>
            <img src="../../../../assets/no-more.png" />
            <span>暂无数据</span>
          </div>
        </div>
        <!-- 表格数据 -->
        <div class="chart-wrapper" v-if="type == '1'">
          <div v-if="productCategory && productCategory.length > 0">
            <template>
              <el-table
                :data="productCategory"
                stripe
                height="380"
                style="width: 100%; margin-top: 30px"
                :header-cell-style="{ background: '#f7f8fa' }"
              >
                <el-table-column
                  prop="valueName"
                  label="类别名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="valueCount" label="数量" align="center">
                </el-table-column>
                <el-table-column prop="proportion" label="占比" align="center">
                </el-table-column>
              </el-table>
            </template>
          </div>
          <div class="no-more" v-else>
            <img src="../../../../assets/no-more.png" />
            <span>暂无数据</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getFactoryOverviewApi } from '@/api/modules/factory'
import { getValueProportionApi } from '@/api/modules/product'
import { getProductAttributeListApi } from '@/api/modules/productAttribute'
export default {
  components: {
    FactoryTabs: () => import('./FactoryTabs'),
    PieChart: () => import('./PieChart')
  },
  data() {
    return {
      list0: ['产品总数', '生产产品数', '实验产品数', '过程产品数'],
      list2: ['工厂设备', '授权设备', '授权人数', '授权过期'],
      factoryId: null,
      factoryInfo: null,
      product: null,
      productCategory: null,
      chartIsLoad: false,
      type: '1',
      categoryList: [],
      productAttributeName: ''
    }
  },
  created() {
    this.selectProductAttrList()
  },
  methods: {
    async selectFactoryModular() {
      const params = {
        factoryId: this.factoryId
      }
      const response = await getFactoryOverviewApi(params)
      this.factoryInfo = response
      this.product = response.product
      this.selectProductAttrId(this.productAttributeName)
    },
    selectProductAttrList() {
      getProductAttributeListApi({ isSearch: true }).then((response) => {
        if (response && response.length > 0) {
          this.categoryList = response
          this.productAttributeName = response[0].attributeName
        } else {
          this.categoryList = []
        }
      })
    },
    selectfactoryId(e) {
      this.factoryId = e
      this.factoryInfo = null
      setTimeout(() => {
        this.selectFactoryModular()
      }, 1000)
    },
    async selectProductAttrId(productAttributeName) {
      const id = this.categoryList.find((item) => {
        if (item.attributeName == productAttributeName) {
          return item
        }
      }).id
      this.productCategory = null
      const response = await getValueProportionApi({
        factoryId: this.factoryId,
        productAttributeId: id
      })
      this.productCategory = response
    },
    changetype() {
      if (this.type == '1') {
        this.type = '2'
      } else {
        this.type = '1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  background: #f7f8fa;
  height: 104px;
  margin-bottom: 30px;
  div {
    display: flex;
    text-align: center;
    padding-top: 22px;
    &:nth-child(1) {
      span {
        color: #666;
        font-size: 14px;
      }
    }
    &:nth-child(2) {
      span {
        color: #000;
        font-size: 24px;
      }
    }
  }
}
.switch_img {
  width: 20px;
  height: 20px;
}
.chart-wrapper {
  .no-more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    span {
      font-size: 14px;
      color: #000;
      padding-top: 10px;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.chart-wrapper {
  .el-table th.is-leaf {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
}
</style>
