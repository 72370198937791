<template>
  <el-dialog :title="dataForm.id ? $t('common.buttons.edit') : $t('common.buttons.createNew')"
    :close-on-click-modal="false" :visible.sync="visible" width="700px">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="150px" size="small">
      <el-form-item :label="$t('accounts.common.username')" prop="userName">
        <el-input v-model="dataForm.userName" :placeholder="$t('accounts.common.account')" :disabled="!!dataForm.id"
          style="width: 300px" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.users.fullName')" prop="nickName">
        <el-input v-model="dataForm.nickName" :placeholder="$t('accounts.users.fullName')" style="width: 300px"
          maxlength="50"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.login.password')" prop="userPwd" v-if="!dataForm.id">
        <el-input v-model="dataForm.userPwd" type="password" :placeholder="$t('login.login.password')"
          style="width: 300px" maxlength="30" minlength="8"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.confirmPassword')" prop="comfirmPassword" v-if="!dataForm.id">
        <el-input v-model="dataForm.comfirmPassword" type="password"
          :placeholder="$t('accounts.common.confirmPassword')" style="width: 300px" maxlength="30"
          minlength="8"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.forgotPassword.email')" prop="email">
        <el-input v-model="dataForm.email" :placeholder="$t('login.forgotPassword.email')" style="width: 300px"
          maxlength="200"></el-input>
      </el-form-item>
      <el-form-item :label="$t('user.tableLabel.phoneNumber')" prop="mobile">
        <el-input v-model="dataForm.mobile" :placeholder="$t('user.tableLabel.phoneNumber')" style="width: 300px"
          maxlength="20"></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.userType == 2 || !dataForm.id" :label="$t('accounts.common.role')" size="mini"
        prop="roleIdList">
        <el-select v-model="dataForm.roleIdList" multiple :placeholder="$t('accounts.common.role')"
          style="width: 300px">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.userType == 2 || !dataForm.id" :label="$t('accounts.common.factory')"
        prop="factoryId">
        <el-select v-model="dataForm.factoryId" :placeholder="$t('accounts.common.factory')" style="width: 300px">
          <el-option v-for="factory in factoryList" :key="factory.id" :label="factory.factoryName" :value="factory.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.category')" prop="category">
        <el-input v-model="dataForm.category" :placeholder="$t('accounts.common.category')" style="width: 300px"
          maxlength="50"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.status')" size="mini" prop="isEnable">
        <el-radio-group v-model="dataForm.isEnable">
          <el-radio :label="true">{{ $t('user.tableLabel.normal') }}</el-radio>
          <el-radio :label="false">{{ $t('user.tableLabel.disabled') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="sideClose">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" :loading="loading" @click="dataFormSubmit()">{{ $t('common.buttons.confirm')
        }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from '@/utils/validate'
import { queryRoleApi } from '@/api/modules/role'
import { getFactoryListApi } from '@/api/modules/factory'
import { addOrUpdateUserApi, getUserApi } from '@/api/modules/user'
import { deepClone, cryptoAes } from '@/utils/index'
export default {
  data() {
    var validateUserName = (rule, value, callback) => {
      var regExp = /^[^@]{3,20}$/;
      if (!regExp.test(value)) {
        callback(new Error(this.$t('user.tableLabel.accountValidation')))
      } else {
        callback()
      }
    }
    var validatePassword = (rule, value, callback) => {
      if (value.length < 8 || value.length > 30) {
        callback(new Error(this.$t('login.resetPassword.passwordRequirements')))
      } else {
        callback()
      }
    }
    var validateComfirmPassword = (rule, value, callback) => {
      if (this.dataForm.userPwd !== value) {
        callback(new Error(this.$t('login.resetPassword.newPasswordDifferent')))
      } else {
        callback()
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error(this.$t('login.forgotPassword.invalidIncorrectEmail')))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error(this.$t('tips.phone.failed')))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      visible: false,
      roleList: [],
      factoryList: [],
      dataForm: {
        id: undefined,
        nickName: '',
        userName: '',
        userPwd: '',
        category: '',
        comfirmPassword: '',
        email: '',
        mobile: '',
        factoryId: '',
        roleIdList: [],
        isEnable: true
      },
      dataRule: {
        userName: [
          { required: true, message: this.$t('tips.verification.userName'), trigger: 'blur' },
          { validator: validateUserName, trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: this.$t('tips.verification.name'), trigger: 'blur' }
        ],
        userPwd: [
          { required: true, message: this.$t('login.resetPassword.passwordNotEmpty'), trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        comfirmPassword: [
          { required: true, message: this.$t('login.resetPassword.confirmPasswordNotEmpty'), trigger: 'blur' },
          { validator: validateComfirmPassword, trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('login.forgotPassword.enterEmail'), trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('tips.verification.phoneNumber'), trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ],
        roleIdList: [
          { required: true, message: this.$t('tips.verification.selectRole'), trigger: 'change' }
        ],
        factoryId: [
          { required: true, message: this.$t('colorSamplePage.ossUpload.selectFactory'), trigger: 'change' }
        ]
      }
    }
  },

  methods: {
    //获取当前登录人的工厂信息
    getDataFactory() {
      getFactoryListApi().then((response) => {
        if (response && response.length > 0) {
          this.factoryList = response
        } else {
          this.factoryList = []
        }
      })
    },
    getRoleListParams() {
      queryRoleApi({ pageSize: 10000 }).then(({ records }) => {
        if (records && records.length > 0) {
          this.roleList = records
        }
      })
    },

    async init(id) {
      this.dataForm.id = id || undefined
      this.visible = true

      await this.getRoleListParams()
      await this.getDataFactory()

      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (id) {
          getUserApi(id).then((response) => {
            this.dataForm = response
          })
        } else {
          this.dataForm = {
            id: undefined,
            nickName: '',
            userName: '',
            userPwd: '',
            comfirmPassword: '',
            email: '',
            mobile: '',
            factoryId: '',
            roleIdList: [],
            isEnable: true
          }
        }
      })
    },

    sideClose() {
      this.visible = false
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          let params = deepClone(this.dataForm)
          params.userPwd = cryptoAes(this.dataForm.userPwd)
          addOrUpdateUserApi(params)
            .then(() => {
              this.$baseMessage(this.$t('physicalColors.prompt.action'), 'success')
              this.sideClose()
              this.$emit('refreshDataList')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
