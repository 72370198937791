import request from '@/utils/request'

const url = '/c/colorCompare'

// 获取基础产品信息列表
export function colorComparisonApi(data) {
  return request({
    url: `${url}/colorComparison`,
    method: "post",
    data
  });
}
