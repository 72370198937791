<template>
  <div class="app-container">
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="state">
        <el-select v-model="dataForm.state" clearable placeholder="请选择工厂状态">
          <el-option v-for="item in states" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="brandId">
        <el-select v-model="dataForm.brandId" size="small" clearable placeholder="请选择品牌">
          <el-option v-for="item in brandList" :key="item.brandId" :label="item.brandName" :value="item.brandId"
            size="small"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="factoryName">
        <el-input v-model="dataForm.factoryName" placeholder="工厂名称" size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">筛选</el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
        <!-- <el-button v-if= "isAuth('factory:factory:save')" type="primary" @click="addOrUpdateHandle()" size="small">添加工厂</el-button> -->
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50">
      </el-table-column>
      <el-table-column prop="factoryName" header-align="center" align="center" label="工厂名称">
      </el-table-column>
      <el-table-column prop="factoryEname" header-align="center" align="center" label="名称英文缩写">
      </el-table-column>
      <el-table-column prop="brandEname" header-align="center" align="center" label="品牌英文名称">
      </el-table-column>
      <el-table-column prop="factoryLogo" header-align="center" align="center" label="工厂图片">
        <template slot-scope="scope">
          <img :src="scope.row.factoryLogo" min-width="70" height="70" />
        </template>
      </el-table-column>
      <el-table-column prop="userName" header-align="center" align="center" label="账号名称">
      </el-table-column>
      <el-table-column prop="state" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.state == '0'">正常</span>
          <span v-if="scope.row.state == '1'">冻结</span>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <!-- <el-button v-if= "isAuth('factory:factory:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button> -->
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
            @click="updatepasswordHandle(scope.row.userId)">修改密码</el-button>
          <el-button v-if="isAuth('sys:user:update')" type="text" size="small"
            @click="updatepemailHandle(scope.row.userId)">修改邮箱</el-button>
          <!-- <el-button v-if="scope.row.state=='0' && isAuth('factory:factory:update')" type="text" style="color: green" size="small" @click="updateFactoryState(scope.row.id,1)">关闭</el-button>
          <el-button v-if="scope.row.state=='1' && isAuth('factory:factory:update')" type="text" style="color: green" size="small" @click="updateFactoryState(scope.row.id,0)">开通</el-button>
          <el-button v-if= "isAuth('factory:factory:delete')" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <update-password v-if="updatePasswordVisible" ref="updatePassword" @refreshDataList="getDataList"></update-password>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <update-email v-if="updateEmailVisible" ref="updateEmail" @refreshDataList="getDataList"></update-email>
  </div>
</template>

<script>
import { getFactoryListApi, del, updateFactoryState, brandList } from '@/api/modules/factory'
import AddOrUpdate from './add-or-update'
import UpdatePassword from "./updatepassword";
import UpdateEmail from "./updateEmail"
export default {
  data() {
    return {
      dataForm: {
        factoryName: '',
        brandId: '',
        state: ''
      },
      states: [{
        value: '0',
        label: '开通'
      }, {
        value: '1',
        label: '关闭'
      }],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      updatePasswordVisible: false,
      updateEmailVisible: false,
      brandList: [],//品牌集合下拉
    }
  },
  components: {
    AddOrUpdate,
    UpdatePassword,
    UpdateEmail
  },
  created() {
    this.getDataList()
    this.getbrandList()
  },
  methods: {
    //品牌下拉
    getbrandList() {
      brandList().then(({ data }) => {
        if (data && data.code === 0) {
          this.brandList = data.list
        } else {
          this.brandList = []
        }
      })
    },
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields();
      this.restPage();

    },
    // 获取数据列表
    getDataList() {
      // this.dataListLoading = true
      var params = {
        page: this.pageIndex,
        limit: this.pageSize,
        state: this.dataForm.state,
        brandId: this.dataForm.brandId,
        factoryName: this.dataForm.factoryName
      }
      getFactoryListApi(params).then(({ data }) => {
        if (data) {
          console.log(data.page.list, "111")
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    //0启用   1禁用
    updateFactoryState(id, state) {
      this.$confirm(`确定进行${state == "0" ? "开通" : "关闭"}操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        updateFactoryState({
          id: id,
          state: state
        }).then(({ data }) => {
          console.log(data);
          if (data.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.restPage();
          } else {
            this.$message.error(data.msg);
          }
        })
      })
    },
    //修改密码
    updatepasswordHandle(id) {
      this.updatePasswordVisible = true;
      console.log(id)
      if (id == null) {
        this.$message.error("账号需要配置，请联系管理员");
        return;
      }
      this.$nextTick(() => {
        this.$refs.updatePassword.init(id)
      })
    },

    //修改邮箱
    updatepemailHandle(id) {
      this.updateEmailVisible = true;
      console.log(id)
      if (id == null) {
        this.$message.error("账号需要配置，请联系管理员");
        return;
      }
      this.$nextTick(() => {
        this.$refs.updateEmail.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`是否确认删除工厂?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    }
  }
}
</script>
