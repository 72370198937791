<template>
  <el-dialog :title="!dataForm.id ? '修改邮箱' : '修改邮箱'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="120px" v-loading="dataLoading" style="width:100%;" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="dataForm.userName" placeholder="登录帐号" disabled style="width:90%;"></el-input>
      </el-form-item>
      <el-form-item label="新邮箱" prop="email" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.email" type="input" placeholder="输入新邮箱" style="width:75%;"></el-input>
        <el-button size="small" type="primary" @click="getCode" :disabled="value" style="margin-left: 10px;">
          {{ typeof text == "number" ? text + second : text }}
        </el-button>
      </el-form-item>
      <el-form-item label="验证码" prop="code" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.code" type="input" placeholder="验证码" style="width:90%;"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userInfo, updateEmail, gainCode } from "@/api/sys";
import { isEmail } from "@/utils/validate";
export default {
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    };
    return {
      text: "获取验证码",
      value: false,
      second: "S后重试",
      visible: false,
      dataLoading: false,
      email: '',
      dataForm: {
        id: 0,
        userName: "",
        email: "",
        code: "",
      },
      dataRule: {
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" },
          { validator: validateEmail, trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    //根据用户邮箱发送验证码
    getCode() {
      this.value = true;
      this.text = 60;
      let _this = this;
      let time = setInterval(function () {
        _this.text--;
        if (_this.text == 0) {
          clearInterval(time);
          _this.value = false;
          _this.text = _this.$t('login.forgotPassword.getVerificationCode');
        }
      }, 1000);
      localStorage.setItem("email", this.dataForm.email);
      const email = this.dataForm.email;
      const params = "email=" + email;
      gainCode(params).then(({ data }) => {
        let codeData = data;
        if (codeData.code === 0) {
          this.$message({
            showClose: true,
            message: this.$t('sys.notifcations.success'),
            type: "success",
          });
          this.value = true;
          this.text = 60;
          let _this = this;
          let time = setInterval(function () {
            _this.text--;
            if (_this.text == 0) {
              clearInterval(time);
              _this.value = false;
              _this.text = _this.$t('login.forgotPassword.getVerificationCode');
            }
          }, 1000);
        } else if (codeData.msg === "验证码还在有效期内,请查看邮件信息") {
          this.$message({
            showClose: true,
            message: "Please check your email for the verificaiton code. 验证码还在有效期内,请查看邮件信息",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: this.$t('login.forgotPassword.invalidIncorrectEmail'),
            type: "error",
          });
        }
      });
    },
    init(id) {
      this.dataForm.id = id;
      this.visible = true
      this.$nextTick(() => {
        this.dataForm.userName = '';
        if (this.dataForm.id) {
          var params = {
            userId: this.dataForm.id,
          };
          userInfo(params).then((data) => {
            if (data) {
              this.dataForm.userName = data.userName;
              this.dataForm.email = '';
              this.dataForm.code = '';
              this.value = false;
              this.text = "获取验证码";
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            userId: this.dataForm.id || undefined,
            email: this.dataForm.email,
            code: this.dataForm.code,
          }
          updateEmail(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.dataLoading = false;
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false;
            }
          })
        }
      })
    }
  }
}
</script>
