<template>
  <el-dialog :title="!dataForm.id ? $t('common.buttons.createNew') : $t('common.buttons.edit')"
    :close-on-click-modal="false" :visible.sync="visible" width="600px">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="120px" v-loading="dataLoading" style="width: 100%" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item :label="$t('physicalColors.attributes.code')" prop="attrCode">
        <el-input v-model="dataForm.attrCode" :placeholder="$t('physicalColors.attributes.code')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('electronicColors.common.name')" prop="attrName">
        <el-input v-model="dataForm.attrName" :placeholder="$t('electronicColors.common.name')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('common.table.comment')" prop="remarks">
        <el-input v-model="dataForm.remarks" type="textarea" :placeholder="$t('common.table.comment')" maxlength="100"
          show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>

</template>

<script>
import { addOrUpdateAttrApi } from "@/api/modules/attr";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      dictInfoList: [],
      dataForm: {
        id: undefined,
        attrCode: "",
        attrName: "",
        attrId: "",
        remarks: "",
      },
      dataRule: {
        attrCode: [
          { required: true, message: this.$t('physicalColors.prompt.code'), trigger: "blur" },
        ],
        attrName: [
          { required: true, message: this.$t('physicalColors.prompt.name'), trigger: "blur" },
        ]
      },
    };
  },
  created() {
  },
  methods: {
    init(id) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();

      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateAttrApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage(this.$t('physicalColors.prompt.action'), "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
