<template>
  <div>
    <div class="message" v-for="(messageListItem, index) in messageList" :key="index">
      <div style="padding-left: 10px">
        <i class="el-icon-warning" v-if="messageListItem.type == 'warning'"></i>
        <i class="el-icon-success" v-else></i>
        <strong> {{ $t(messageListItem.message) }}</strong>
      </div>
      <div v-if="
        !messageListItem.seeMoreVisible && messageListItem.items.length > 0
      ">
        <el-button @click="seeMore(messageListItem)" type="text">{{
          $t('physicalColors.common.import.viewDetails')
          }}</el-button>
      </div>
      <div v-else-if="messageListItem.items.length > 0" class="details-box">
        <div @click="seeLess(messageListItem)" class="el-icon-close close-expanded-element"></div>
        <span v-for="(item, index) in messageListItem.items" :key="index">{{ item
          }}<span v-if="index != messageListItem.items.length - 1">, </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * props: messageList
 *  seeMoreVisible: boolean
 *  type: String ("success", "warning")
 *  message: String
 *  items: [String]
 */
export default {
  props: ['messageList'],
  methods: {
    seeMore(item) {
      item.seeMoreVisible = true
      this.$forceUpdate()
    },
    seeLess(item) {
      item.seeMoreVisible = false
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20px;
  width: 80%;
}

.details-box {
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  padding: 10px;
  height: 100px;
  margin: 5px;
  overflow-y: scroll;
  scrollbar-width: thin;
  word-break: normal;
  text-align: left;
  position: relative;
}

.el-icon-warning {
  color: #af5d06;
}

.el-icon-success {
  color: #00700b;
}

.close-expanded-element {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  cursor: pointer;
}
</style>