import request from "@/utils/request";

const url = "/c/role/";

/**
 * 获取角色列表信息
 * @returns 
 */
export function queryRoleApi(data) {
  return request({
    url: `${url}query`,
    method: "post",
    data
  });
}

/**
 * 获取角色信息
 * @returns 
 */
export function getRoleApi(id) {
  return request({
    url: `${url}get/${id}`,
    method: "get"
  });
}

/**
 * 创建/编辑角色信息
 * @returns 
 */
export function addOrUpdateRoleApi(data) {
  return request({
    url: `${url}${!data.id ? 'add' : 'update'}`,
    method: "put",
    data
  });
}

/**
 * 删除角色
 * @returns 
 */
export function deleteRoleApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: "delete"
  });
}
