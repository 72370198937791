<template>
  <div class="app-container">
    <el-form
      :inline="true"
      size="small"
      :model="dataForm"
      ref="dataForm"
      @keyup.enter.native="restPage()"
    >
      <el-form-item>
        <el-select
          v-model="dataForm.userId"
          filterable
          placeholder="人员名称"
          clearable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="operation">
        <el-select
          v-model="dataForm.operation"
          placeholder="匹配结果"
          clearable
        >
          <el-option
            v-for="item in stateArry"
            :key="item.stateKey"
            :label="item.stateValue"
            :value="item.stateKey"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="matchType">
        <el-select
          v-model="dataForm.matchType"
          placeholder="匹配类型"
          clearable
        >
          <el-option label="系统默认" value="1"></el-option>
          <el-option label="人工确认" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          placeholder="搜索备注"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="deltaE">
        <el-input
          v-model="dataForm.deltaE"
          placeholder="DE<1的数量"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="daterange">
        <div class="block">
          <el-date-picker
            v-model="dataForm.searchDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">查询</el-button>
        <el-button type="primary" @click="exportExcel()" size="small"
          >导出数据
        </el-button>
        <el-button @click="reset('dataForm')" size="small">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="small"
      v-loading="dataListLoading"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column
        prop="createNickName"
        header-align="center"
        align="center"
        label="人员名称"
      >
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="搜索备注"
      >
      </el-table-column>
      <el-table-column
        width="280"
        header-align="center"
        align="center"
        :render-header="renderHeader"
        label="搜索结果"
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.searchResult &&
              scope.row.searchResult &&
              scope.row.searchResult.length > 0
            "
          >
            <span v-for="(item, index) in scope.row.searchResult" :key="index"
              >{{ item.productNo }}，{{ item.e00Value }}，{{ item.glossValue
              }}<br
            /></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="结果统计">
        <template slot-scope="scope">
          <div>{{ scope.row.deltaE1 }}（ΔE*<0.5）</div>
          <div>{{ scope.row.deltaE2 }}（0.5<ΔE*<=1）</div>
          <div>{{ scope.row.deltaE3 }}（1<ΔE*<=2）</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="searchType"
        header-align="center"
        align="center"
        width="100"
        label="查色方式"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.searchType == '0'">仪器查色</span>
          <span v-if="scope.row.searchType == '1'">色号查色</span>
          <span v-if="scope.row.searchType == '2'">色板查色</span>
          <span v-if="scope.row.searchType == '3'">精准查色</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="搜索条件">
        <template slot-scope="scope">
          <span>{{
            scope.row.brandName ? scope.row.brandName + '，\n' : ''
          }}</span>
          <span>{{
            scope.row.factoryName ? scope.row.factoryName + '，\n' : ''
          }}</span>
          <span>{{
            scope.row.searchparams ? scope.row.searchparams + '，\n' : ''
          }}</span>
          <span v-if="scope.row.state == '0'">过程样板</span>
          <span v-if="scope.row.state == '1'">实验产品</span>
          <span v-if="scope.row.state == '2'">生产产品</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operation"
        header-align="center"
        align="center"
        width="100"
        label="匹配结果"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.operation == '1'">未处理</span>
          <span v-if="scope.row.operation == '2'">完全匹配</span>
          <span v-if="scope.row.operation == '3'">不匹配</span>
          <span v-if="scope.row.operation == '4'">配色可参考</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="matchType"
        header-align="center"
        align="center"
        width="100"
        label="匹配结果"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.matchType == '1'">系统默认</span>
          <span v-if="scope.row.matchType == '2'">人工确认</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        width="90"
        align="center"
        label="搜索日期"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >匹配处理</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      size="small"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import {
  querySearchHistoryApi,
  deleteSearchHistoryApi
} from '@/api/modules/searchHistory'
import { queryUserApi } from '@/api/modules/user'
import AddOrUpdate from './add-or-update'
import { getToken } from '@/utils/auth'
import { axiosDownFile } from '@/utils'
export default {
  data() {
    return {
      dataForm: {
        userId: '',
        matchType: '',
        deltaE: '',
        operation: '',
        remarks: '',
        searchDate: [],
        daterange: []
      },
      stateArry: [
        { stateKey: '2', stateValue: '完全匹配' },
        { stateKey: '3', stateValue: '不匹配' },
        { stateKey: '4', stateValue: '配色可参考' }
      ],
      dataList: [],
      userList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  created() {
    this.getUserList()
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '') {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    }
  },
  watch: {
    $route() {
      if (this.$route.name == 'searchhistoryList') {
        this.restPage()
      }
    }
  },
  methods: {
    getUserList() {
      queryUserApi({ pageSize: 10000 }).then(({ records, totalRecords }) => {
        if (totalRecords > 0) {
          this.userList = records
        } else {
          this.userList = []
        }
        this.getDataList()
      })
    },
    //导出数据
    exportExcel() {
      axiosDownFile('/api/c/searchHistory/export', this.getQueryParams())
    },
    renderHeader(h, { column }) {
      // h即为cerateElement的简写，具体可看vue官方文档
      return (
        <div>
          <span
            style="cursor:pointer"
            title="搜索结果的格式为 “产品号 , 色差值 , 光泽值”"
          >
            搜索结果<i class="el-icon-question" style="margin-left:5px;"></i>
          </span>
        </div>
      )
    },
    //状态不匹配，且数据不为空，就显示红色
    tableRowClassName({ row }) {
      if (row.operation == '3' && row.deltaE1 > 0) {
        return 'warning-row'
      }
      if (row.operation == '3' && row.deltaE2 > 0) {
        return 'warning-row'
      }
      return ''
    },

    //重新查询
    restPage() {
      this.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.dataForm.userId = ''
      this.dataForm.searchDate = []
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    getQueryParams() {
      var params = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        userId: this.dataForm.userId,
        matchType: this.dataForm.matchType,
        remarks: this.dataForm.remarks,
        deltaE: this.dataForm.deltaE,
        operation: this.dataForm.operation
      }
      if (this.dataForm.searchDate && this.dataForm.searchDate.length > 0) {
        params.createTimeFrom = this.dataForm.searchDate[0] + ' 00:00:00'
        params.createTimeTo = this.dataForm.searchDate[1] + ' 23:59:59'
      }
      return params
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true

      querySearchHistoryApi(this.getQueryParams()).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            records.forEach((record) => {
              let searchResult = []
              if (
                record.searchResult.deltaE1Result &&
                record.searchResult.deltaE1Result.length > 0
              ) {
                record.searchResult.deltaE1Result.forEach((item) => {
                  searchResult.push(item)
                })
              }
              if (
                record.searchResult.deltaE2Result &&
                record.searchResult.deltaE2Result.length > 0
              ) {
                record.searchResult.deltaE2Result.forEach((item) => {
                  searchResult.push(item)
                })
              }
              if (
                record.searchResult.deltaE3Result &&
                record.searchResult.deltaE3Result.length > 0
              ) {
                record.searchResult.deltaE3Result.forEach((item) => {
                  searchResult.push(item)
                })
              }
              if (
                record.searchResult.deltaE4Result &&
                record.searchResult.deltaE4Result.length > 0
              ) {
                record.searchResult.deltaE4Result.forEach((item) => {
                  searchResult.push(item)
                })
              }
              if (searchResult && searchResult.length > 5) {
                searchResult.splice(5)
              }
              record.searchResult = searchResult
            })
            this.dataList = records
            this.totalPage = totalRecords
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        }
      )
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定删除该条历史记录?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSearchHistoryApi(id).then(() => {
          this.$baseMessage('删除成功', 'success')
          this.getDataList()
        })
      })
    }
  }
}
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
