<template>
  <el-dialog :title="$t('common.upload.qtx.batchUploadQtxFile')" :close-on-click-modal="false" @close="closeHandle"
    style="text-align: center" :visible.sync="visible" :append-to-body="true">
    <el-form size="small" label-width="120px" v-loading="dataLoading" element-loading-spinner="el-icon-loading">
      <div style="text-align: center; width: 100%; margin-bottom: 30px">
        <span style="margin-right: 10px; color: red">*</span>
        <span style="margin-right: 20px;">{{ $t("colorSamplePage.ossUpload.factiry") }}</span>
        <el-select v-model="factoryId" size="small" :placeholder="$t('colorSamplePage.ossUpload.selectFactory')"
          :required="true" clearable>
          <el-option v-for="item in factoryList" :key="item.id" :label="item.factoryName" :value="item.id"
            size="small"></el-option>
        </el-select>
      </div>
      <el-upload drag action="#" :http-request="uploadBatchQtx" :before-upload="beforeUploadHandle" multiple
        :file-list="fileList" :show-file-list="true" :disabled="inProgressUpload" style="text-align: center">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t("common.upload.qtx.dragOrClickToUploadQtx") }}
        </div>
        <div class="el-upload__tip" slot="tip">
          {{ $t("common.upload.qtx.onlyQTXSupportedMaxSize") }}
        </div>
      </el-upload>
    </el-form>
    <span v-if="uploadComplete || inProgressUpload" class="productall-uploaded_qtx">
      <span v-if="uploadComplete"><i class="el-icon-success"></i>
        {{ this.$t('tips.uploadFile.file') }} &nbsp;</span>
      <!-- <span v-else>已存在未确认数据导入 &nbsp;</span>
      <span v-if="isProductAll">请进入产品导入记录中查看并且确认或者取消导入
        <el-button size="small" @click="jumpPage()" class="btn" type="primary"
          style="margin-left: 8px">审核导入信息</el-button></span> -->
    </span>
    <message-list :messageList="messageList" />
  </el-dialog>
</template>
<script>
import { getFactory } from "@/api/modules/product";
import { hasInProgressUpload, uploadQtxInfoList } from "@/api/modules/product";
import messageList from "../../components/messageList.vue";
export default {
  components: { messageList },
  data() {
    return {
      state: "",
      dataLoading: false,
      visible: false,
      successNum: 0,
      fileList: [],
      flagList: [],
      factoryList: [],
      factoryId: "",
      title: "",
      isProductAll: false,
      importMethod: undefined,
      inProgressUpload: false,
      uploadComplete: false,
      messageList: [],
      message: "ERROR"
    };
  },
  methods: {
    init(state) {
      this.inProgressUpload = false;
      this.uploadComplete = false;
      this.visible = true;
      this.getFactoryList();
      this.messageList = [];
      this.message = ""
      this.state = state;
    },
    checkHasInProgressUpload() {
      hasInProgressUpload().then(({ data }) => {
        if (data && data.code === 0) {
          this.inProgressUpload = data.hasInProgressUpload;
        }
      });
    },
    //获取工厂列表
    getFactoryList() {
      getFactory().then((data) => {
        if (data) {
          this.factoryList = data;

        } else {
          this.factoryList = [];
        }
      });
    },
    beforeUploadHandle(file) {
      // 确保file对象存在
      if (!file) {
        return false;
      }
      // const isQtx = file.name.endsWith(".qtx");
      // const isLt10M = file.size / 1024 / 1024 < 10;

      // // 使用早期返回减少嵌套，并对错误进行细化处理
      // if (!isQtx) {
      //   this.handleError('fileType');
      //   return false;
      // }
      // if (!isLt10M) {
      //   this.handleError('fileSize');
      //   return false;
      // }
      // // 计算所有已选择文件的总大小，包括当前文件
      // const totalSize = this.fileList.reduce((acc, f) => acc + f.size, 0) + file.size;
      // if (totalSize / 1024 / 1024 > 10) {
      //   this.handleError('totalFileSize');
      //   return false;
      // }

      // 如果文件符合要求，清空错误列表
      this.errorList = [];
      return true;
    },

    // 异常处理方法，区分文件类型和大小错误
    handleError(type) {
      let errorMessage = 'common.upload.qtx.onlyQTXSupportedMaxSize';
      // 根据类型区分错误信息，这里假设只有类型和大小两种错误，需要国际化处理
      if (type === 'fileSize' || type === 'totalFileSize') {
        errorMessage = 'common.upload.sizeLimitExceeded'; // 假设这是针对文件大小超出限制的国际化标识
      }
      this.$message.error(this.$t(errorMessage));
    },

    uploadBatchQtx(file) {
      console.log('fileList', this.fileList)
      if (!this.factoryId) {
        this.$message.error(this.$t("colorSamplePage.ossUpload.selectFactory"));
        return;
      }
      // 请求接口
      this.formData = new FormData();
      this.formData.append("file", file.file);
      this.inProgressUpload = true;
      const additionalData = {
        factoryId: this.factoryId,
        state: this.state,
      };
      // 将additionalData中的键值对添加到FormData对象中
      Object.entries(additionalData).forEach(([key, value]) => {
        this.formData.append(key, value);
      });
      uploadQtxInfoList(this.formData).then((data) => {
        if (data) {
          this.dataLoading = false
          this.uploadComplete = true
          if (data) {
            if (this.messageList[0] && this.messageList[0].items && this.messageList[0].items.length > 0) {
              this.messageList[0].items = this.messageList[0].items.concat(data[0].items)
            }
            else {
              this.messageList = data
            }

            this.messageList.forEach(
              (warningItem) => (warningItem.seeMoreVisible = false)
            );
          }
        } else {
          this.dataLoading = false
          return this.$alert(data[0].message)
        }
      });
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = [];
      this.$emit("refreshDataList");
    },
    jumpPage() {
      this.visible = false;
      this.$router.push("/productImportRecord");
    },
  },
};
</script>
<style lang="scss" scoped>
.productall-uploaded_qtx {
  font-size: 14px;
  background-color: #e1f8d8;
  border: 1px solid #bbdaab;
  padding: 12px;
  margin-top: 20px;
  color: #67c23a;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  display: inline-block;
}
</style>
