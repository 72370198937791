<template>
  <el-card class="box-card-component">
    <div style="height: 140px">
      <template v-if="data">
        <div class="combo-item">
          <span>{{ data.brandName }}</span>
          <span style="display: flex; flex: 1">
            <img :src="data.brandLogo"
          /></span>
        </div>
        <div class="combo-item">
          <span>已开通：</span>
          <span>{{ data.comboName }}</span>
        </div>
        <div class="combo-item">
          <span>到期时间：</span>
          <span>{{ data.endTime }}</span>
          <span v-if="data.remainingDay">{{ data.remainingDay }}</span>
        </div>
        <div class="combo-item"><span>套餐已用：</span></div>
      </template>
    </div>

    <div
      style="
        position: relative;
        display: flex;
        display: flex;
        flex-direction: column;
      "
      v-if="data"
    >
      <div v-if="data.product" class="progress-item">
        <span>生产产品</span>
        <el-progress
          :percentage="data.product.proportion"
          color="#63AFF8"
          :stroke-width="strokeWidth"
        />
        <span>{{ data.product.divisor }}/{{ data.product.base }}</span>
      </div>
      <div v-if="data.testProduct" class="progress-item">
        <span>实验产品</span>

        <el-progress
          :percentage="data.testProduct.proportion"
          color="#FFA958"
          :stroke-width="strokeWidth"
        />
        <span>{{ data.testProduct.divisor }}/{{ data.testProduct.base }}</span>
      </div>
      <div v-if="data.colorimeter" class="progress-item">
        <span>色差仪</span>

        <el-progress
          :percentage="data.colorimeter.proportion"
          color="#49D5C4"
          :stroke-width="strokeWidth"
        />
        <span>{{ data.colorimeter.divisor }}/{{ data.colorimeter.base }}</span>
      </div>
      <!-- <div class="progress-item">
        <span>工厂账号</span>

        <el-progress
          :percentage="
            data.sysSetMealVo.factoryAccountNumber
              ? data.sysSetMealVo.factoryAccountNumber.proportion
              : 0
          "
          color="#DE8EFF"
          :stroke-width="strokeWidth"
        />
        <span
          >{{
            data.sysSetMealVo.factoryAccountNumber
              ? data.sysSetMealVo.factoryAccountNumber.divisor
              : ''
          }}/{{
            data.sysSetMealVo.factoryAccountNumber
              ? data.sysSetMealVo.factoryAccountNumber.base
              : ''
          }}</span
        >
      </div> -->
      <div v-if="data.employeeAccount" class="progress-item">
        <span>员工账号</span>

        <el-progress
          :percentage="data.employeeAccount.proportion"
          color="#FF68B9"
          :stroke-width="strokeWidth"
        />
        <span
          >{{ data.employeeAccount.divisor }}/{{
            data.employeeAccount.base
          }}</span
        >
      </div>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { getComboInfoApi } from '@/api/modules/brand'

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        success: 'success',
        pending: 'danger'
      }
      return statusMap[status]
    }
  },
  computed: {
    percentageComputed() {
      return function (v, v1) {
        console.log(v, v1)
        return parseInt(v.split('.')[0])
      }
    }
  },
  data() {
    return {
      data: null,
      strokeWidth: 10,
      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024
      }
    }
  },
  computed: {
    ...mapGetters([
      // 'name',
      // 'avatar',
      // 'roles'
    ])
  },
  mounted() {
    this.selectBrandInfo()
  },
  methods: {
    async selectBrandInfo() {
      const response = await getComboInfoApi()
      this.data = response
      return
    }
  }
}
</script>
<style lang="scss" scoped>
.box-card-component {
  height: 277px;
  .box-card-header {
    position: relative;
    height: 220px;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.1, 1.1);
        filter: contrast(130%);
      }
    }
  }
  .mallki-text {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    font-weight: bold;
  }
  .panThumb {
    z-index: 100;
    height: 70px !important;
    width: 70px !important;
    position: absolute !important;
    top: -45px;
    left: 0px;
    border: 5px solid #ffffff;
    background-color: #fff;
    margin: auto;
    box-shadow: none !important;
    ::v-deep .pan-info {
      box-shadow: none !important;
    }
  }
  .progress-item {
    font-size: 16px;
    font-weight: bold;
    flex: 4;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    span {
      &:nth-child(1) {
        font-size: 12px;
        color: #999;
        flex: 0.6;
        text-align: left;
      }
      &:nth-child(3) {
        color: #666666;
        font-size: 12px;
        flex: 0.8;
        text-align: right;
      }
    }
  }
  @media only screen and (max-width: 1510px) {
    .mallki-text {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.box-card-component {
  .el-progress {
    padding: 0 0 5px 0;
    flex: 1.6;
  }
  .el-progress__text {
    display: none;
  }
  .el-progress-bar__inner,
  .el-progress-bar__outer {
    border-radius: 0;
  }
  .el-row {
    margin-bottom: 20px;
  }
  .combo-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    span:nth-child(1) {
      color: #666666;
      font-size: 14px;
    }
    img {
      height: 19px;
      margin-left: 10px;
    }
  }
  .combo-item:nth-child(2) {
    span {
      &:nth-child(1) {
        color: #666666;
      }
      &:nth-child(2) {
        color: #4d86f9;
        font-size: 16px;
      }
    }
  }
  .combo-item:nth-child(3) {
    span {
      &:nth-child(3) {
        width: 103px;
        height: 28px;
        background: rgba(91, 225, 140, 0.25);
        opacity: 1;
        border-radius: 15px;
        color: #08a241;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
