<template>
  <div class="app-container">
    <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
      <el-form-item prop="brandNameLike">
        <el-input v-model="dataForm.brandNameLike" :placeholder="$t('accounts.common.brand')" size="small"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="restPage()" size="small">{{ $t('common.buttons.search') }}</el-button>
        <el-button @click="reset('dataForm')" size="small">{{ $t('common.buttons.reset') }}</el-button>
        <el-button v-if="isAuth('brand:add')" type="primary" @click="addOrUpdateHandle()" size="small">{{
          $t('common.buttons.createNew')
          }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%">
      <!-- <el-table-column prop="id" header-align="center" align="center" label="id" width="150">
      </el-table-column> -->
      <el-table-column prop="brandName" header-align="center" align="center" :label="$t('accounts.common.brand')">
      </el-table-column>
      <!-- <el-table-column prop="isConvert" header-align="center" align="center" label="LAB值是否需要转换">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isConvert" type="success">是</el-tag>
          <el-tag v-else type="info">否</el-tag>
        </template>
</el-table-column> -->
      <el-table-column prop="remarks" header-align="center" align="center" :label="$t('common.table.comment')">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" :label="$t('common.table.dateCreated')">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150"
        :label="$t('common.table.action')">
        <template slot-scope="scope">
          <el-button v-if="isAuth('brand:edit')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{
            $t('common.buttons.edit')
            }}</el-button>
          <el-button v-if="isAuth('brand:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">{{
            $t('common.buttons.remove')
            }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
      :current-page="dataForm.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize"
      :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import {
  queryColorBookBrandApi,
  deleteColorBookBrandApi
} from '@/api/modules/colorbookbrand'
import AddOrUpdate from './add-or-update'
export default {
  data() {
    return {
      dataForm: {
        brandNameLike: '',
        pageNo: 1,
        pageSize: 10
      },
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    //重新查询
    restPage() {
      this.dataForm.pageIndex = 1
      this.getDataList()
    },
    reset(dataForm) {
      this.$refs.dataForm.resetFields()
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      queryColorBookBrandApi(this.dataForm).then(
        ({ records, totalRecords }) => {
          if (totalRecords > 0) {
            this.dataList = records
            this.totalPage = totalRecords
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        }
      )
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('common.buttons.remove.tips'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.buttons.confirm'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        type: 'warning'
      }).then(() => {
        deleteColorBookBrandApi(id).then(() => {
          this.$baseMessage(this.$t('common.tips.deletedSuccessfully'), 'success')
          this.getDataList()
        })
      })
    }
  }
}
</script>
