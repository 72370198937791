<template>
  <div class="app-container">
    <el-card class="box-pro">
      <div slot="header" class="clearfix">
        <span>{{ $t('common.table.filter') }}</span>
      </div>
      <el-row :gutter="20">
        <el-col :span="collapseState ? 19 : 24">
          <!-- 名称 -->
          <el-form :inline="true" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item :label="$t('electronicColors.common.name')" prop="colorSampleName">
              <el-input v-model="dataForm.colorSampleName" :placeholder="$t('electronicColors.common.name')"
                size="small" clearable maxlength="30"></el-input>
            </el-form-item>
            <!-- 配方代号 -->
            <el-form-item :label="$t('attributes.recipeNo')" prop="recipeNo" v-if="!pageFlag">
              <el-input v-model="dataForm.recipeNo" :placeholder="$t('attributes.recipeNo')" size="small" clearable
                maxlength="30"></el-input>
            </el-form-item>
            <!-- 打色单号 -->
            <el-form-item :label="$t('attributes.colorMatchNumber')" prop="colorMatchNumber" v-if="!pageFlag">
              <el-input v-model="dataForm.colorMatchNumber" :placeholder="$t('attributes.colorMatchNumber')"
                size="small" maxlength="30"></el-input>
            </el-form-item>

            <!-- 大货样专属属性 下-->

            <el-form-item :label="$t('attributes.orderNumber')" prop="orderNumber" v-if="pageFlag">
              <el-input v-model="dataForm.orderNumber" :placeholder="$t('attributes.orderNumber')" size="small"
                maxlength="30"></el-input>
            </el-form-item>

            <el-form-item :label="$t('electronicColors.common.colorCode')" prop="colorCode" v-if="pageFlag">
              <el-input v-model="dataForm.colorCode" :placeholder="$t('electronicColors.common.colorCode')" size="small"
                maxlength="30"></el-input>
            </el-form-item>

            <el-form-item :label="$t('attributes.prdCode')" prop="productNo" v-if="pageFlag">
              <el-input v-model="dataForm.productNo" :placeholder="$t('attributes.prdCode')" size="small"
                maxlength="30"></el-input>
            </el-form-item>

            <!-- 成份 -->
            <el-form-item :label="$t('attributes.fullNameIn')">
              <el-select size="mini" v-model="dataForm.composition" clearable filterable allow-create remote
                reserve-keyword :placeholder="$t('attributes.fullNameIn')" :remote-method="selectCompositionList"
                :loading="loading">
                <el-option v-for=" item in compositionList " :key="item.attrValueCode" :label="item.attrValueName"
                  :value="item.attrValueCode" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <!-- 大货样专属属性 下-->
            <!-- 库存 -->
            <el-form-item :label="$t('common.colorDetails.inventory')" v-if="pageFlag">
              <el-select v-model="dataForm.hasStock" size="mini" filterable remote reserve-keyword
                :placeholder="$t('common.colorDetails.inventory')">
                <el-option :label="$t('comon.colorDetails.inventory.all')" value="-1"></el-option>
                <el-option :label="$t('common.colorDetails.inventory.inStock')" value="1"></el-option>
                <el-option :label="$t('common.colorDetails.inventory.outOfStock')" value="0"></el-option>
              </el-select>
            </el-form-item>
            <!-- 纱线形态 -->
            <el-form-item :label="$t('attributes.yarnType')" class="yarn-pattern">
              <el-select size="mini" v-model="dataForm.yarnType" clearable filterable remote reserve-keyword
                :placeholder="$t('attributes.yarnType')" :remote-method="selectYarnPatternList" :loading="loading"
                @change="selectYarnChange">
                <el-option v-for=" item in yarnTypeList " :key="item.attrValueCode" :label="item.attrValueName"
                  :value="item.attrValueCode" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <!-- 纱线规格 -->
            <el-form-item :label="$t('attributes.prdSpec')" class="yarn-specification">
              <el-select size="mini" v-model="dataForm.yarnSpecification" clearable filterable remote reserve-keyword
                :placeholder="$t('attributes.prdSpec')" :remote-method="selectYarnSpecificationList" :loading="loading"
                @change="selectYarnSpecificationChange">
                <el-option v-for=" item in yarnSpecificationList " :key="item.attrValueCode" :label="item.attrValueName"
                  :value="item.attrValueCode" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <!-- 打色样品形态 -->
            <el-form-item :label="$t('attributes.colorMatchType')" v-if="!pageFlag">
              <el-select size="mini" v-model="dataForm.colorMatchType" clearable filterable remote reserve-keyword
                :placeholder="$t('attributes.colorMatchType')" :remote-method="selectColorMatchTypeList"
                :loading="loading" @change="selectColorMatchTypeChange">
                <el-option v-for=" item in colorMatchTypeList " :key="item.attrValueCode" :label="item.attrValueName"
                  :value="item.attrValueCode" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <!-- 大货样专属属性 下-->
            <!-- 产品类别 -->
            <el-form-item :label="$t('attributes.prdSort')" v-if="pageFlag">
              <el-select v-model="dataForm.productCategory" size="mini" clearable filterable remote reserve-keyword
                :placeholder="$t('attributes.prdSort')" :remote-method="selectProductCategoryList" :loading="loading"
                @change="selectProductCategoryChange">
                <el-option v-for=" item in productCategoryList " :key="item.id" :label="item.attrValueName"
                  :value="item.attrValueName" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <!-- 大货样专属属性 下-->
            <!-- 生产工序 -->
            <el-form-item :label="$t('attributes.productionProcess')" v-if="pageFlag">
              <el-select v-model="dataForm.productionProcess" size="mini" clearable filterable remote reserve-keyword
                :placeholder="$t('attributes.productionProcess')" :remote-method="selectProductionProcessList"
                :loading="loading" @change="selectProductionProcessChange">
                <el-option v-for=" item in productionProcessList " :key="item.attrValueCode" :label="item.attrValueName"
                  :value="item.attrValueCode" size="mini"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('tips.display.visibility')">
              <el-select v-model="dataForm.isDisplay" size="mini" filterable remote reserve-keyword
                :placeholder="$t('tips.display.visibility')">
                <el-option :label="$t('comon.colorDetails.inventory.all')" value="-1"></el-option>
                <el-option :label="$t('tips.display.display')" value="0"></el-option>
                <el-option :label="$t('tips.display.visible')" value="1"></el-option>
              </el-select>
            </el-form-item>
            <br v-if="brandState && factoryState" />
            <template v-if="!collapseState">
              <div style="
                  text-align: right;
                  min-width: 205px;
                  right: 0;
                  bottom: 20px;
                ">
                <el-button @click="restPage()" type="primary" size="small">{{ $t('common.buttons.search') }}</el-button>
                <el-button @click="reset('dataForm')" size="small" type="info">{{ $t('common.buttons.reset')
                  }}</el-button>
                <el-button v-if="pageFlag && isAuth('upload_plab:file')" @click="uploadHandle()" size="small"
                  type="success">{{
                    $t('common.samples.import')
                  }}</el-button>
                <el-button v-if="!pageFlag && isAuth('upload_elab:file')" @click="uploadHandle()" size="small"
                  type="success">{{
                    $t('common.samples.import')
                  }}</el-button>
                <el-button v-if="pageFlag && isAuth('synchronize_inventory_status')"
                  @click="synchronizeInventoryStatus()" size="small" type="primary" :disabled="stncStatus">{{
                    this.$t('button.product.synchronizeInventoryStatus') }}</el-button>
                <el-button @click="handleCollapse" type="text">{{ collapseState ? $t('physicalColors.common.expand') :
                  $t('physicalColors.common.hide')
                  }}<i :class="[
                    collapseState ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                    'el-icon--right'
                  ]"></i></el-button>
              </div>
            </template>
          </el-form>
        </el-col>
        <template v-if="collapseState">
          <el-col :span="5">
            <div style="text-align: right; min-width: 205px">
              <el-button @click="restPage()" size="small">{{ $t('common.buttons.search') }}</el-button>
              <el-button @click="reset('dataForm')" size="small" type="info">{{ $t('common.buttons.reset')
                }}</el-button>
              <el-button @click="handleCollapse" type="text">{{ collapseState ? $t('physicalColors.common.expand') :
                $t('physicalColors.common.hide')
                }}<i :class="[
                  collapseState ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                  'el-icon--right'
                ]"></i></el-button>
            </div>
          </el-col>
        </template>
      </el-row>
    </el-card>

    <el-card style="margin-top: 30px">
      <div slot="header" class="clearfix">
        <span>{{ $t('physicalColors.common.samples') }}</span>
      </div>
      <!-- 大货样列表 -->
      <el-table :data="dataList" v-if="pageFlag" border v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="colorSampleName" header-align="center" align="center" min-width="200"
          :label="$t('electronicColors.common.name')">
        </el-table-column>
        <el-table-column prop="picUrl" header-align="center" align="center" width="100"
          :label="$t('common.table.image')">
          <!-- 图片的显示 -->
          <template slot-scope="scope">
            <div :style="{
              backgroundColor: scope.row.hex || scope.row.Hex,
              height: '70px',
              minWidth: '70px'
            }"></div>
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" header-align="center" align="center" width="150"
          :label="$t('attributes.orderNumber')">
        </el-table-column>
        <el-table-column prop="productNo" header-align="center" align="center" width="150"
          :label="$t('attributes.prdCode')">
        </el-table-column>
        <el-table-column prop="colorCode" header-align="center" align="center" width="150"
          :label="$t('electronicColors.common.colorCode')">
        </el-table-column>
        <el-table-column prop="productCategory" header-align="center" align="center" width="150"
          :label="$t('attributes.prdSort')">
        </el-table-column>
        <el-table-column prop="yarnType" header-align="center" align="center" width="150"
          :label="$t('attributes.yarnType')">
        </el-table-column>
        <el-table-column prop="yarnSpecification" header-align="center" align="center" width="150"
          :label="$t('attributes.prdSpec')">
        </el-table-column>
        <!-- <el-table-column prop="composition" header-align="center" align="center" label="成份" width="250">
        </el-table-column> -->
        <el-table-column prop="productionProcess" header-align="center" align="center" width="155"
          :label="$t('attributes.productionProcess')">
        </el-table-column>
        <el-table-column prop="colorMatchNumber" header-align="center" align="center" width="230"
          :label="$t('attributes.colorMatchNumber')">
        </el-table-column>
        <el-table-column prop="recipeNo" header-align="center" align="center" width="150"
          :label="$t('attributes.recipeNo')">
        </el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" width="155"
          :label="$t('common.table.dateCreated')">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="155"
          :label="$t('common.table.action')">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$t('tips.display')" placement="top-start">
              <el-button type="text" size="small" @click="controlShowHide(scope.row)">{{
                getButtonText(scope.row.display)
                }}</el-button>
            </el-tooltip>
            <el-button type="text" size="small" @click="viewdata(scope.row.id)">{{
              $t('common.buttons.view') }}</el-button>
            <el-button v-if="isAuth('product_plab:delete')" type="text" size="small" style="color: red"
              @click="deleteDataHandle(scope.row.id)">{{ $t('common.buttons.remove') }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 打色样列表 -->
      <el-table :data="dataList" v-if="!pageFlag" border v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="colorSampleName" header-align="center" align="center" min-width="200"
          :label="$t('electronicColors.common.name')">
        </el-table-column>
        <el-table-column prop="picUrl" header-align="center" align="center" width="100"
          :label="$t('common.table.image')">
          <!-- 图片的显示 -->
          <template slot-scope="scope">
            <div :style="{
              backgroundColor: scope.row.hex || scope.row.Hex,
              height: '70px',
              minWidth: '70px'
            }"></div>
          </template>
        </el-table-column>
        <el-table-column prop="yarnType" header-align="center" align="center" width="150"
          :label="$t('attributes.yarnType')">
        </el-table-column>
        <el-table-column prop="yarnSpecification" header-align="center" align="center" width="150"
          :label="$t('attributes.prdSpec')">
        </el-table-column>
        <!-- <el-table-column prop="composition" header-align="center" align="center" label="成份" width="250">
        </el-table-column> -->

        <el-table-column prop="colorMatchType" header-align="center" align="center" width="150"
          :label="$t('attributes.colorMatchType')">
        </el-table-column>
        <el-table-column prop="colorMatchNumber" header-align="center" align="center" width="230"
          :label="$t('attributes.colorMatchNumber')">
        </el-table-column>
        <el-table-column prop="recipeNo" header-align="center" align="center" width="150"
          :label="$t('attributes.recipeNo')">
        </el-table-column>
        <el-table-column prop="createTime" header-align="center" align="center" width="155"
          :label="$t('common.table.dateCreated')">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="155"
          :label="$t('common.table.action')">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$t('tips.display')" placement="top-start">
              <el-button type="text" size="small" @click="controlShowHide(scope.row)">{{
                getButtonText(scope.row.display)
                }}</el-button>
            </el-tooltip>
            <el-button type="text" size="small" @click="viewdata(scope.row.id)">{{
              $t('common.buttons.view') }}</el-button>
            <el-button v-if="isAuth('product_elab:delete')" type="text" size="small" style="color: red"
              @click="deleteDataHandle(scope.row.id)">{{ $t('common.buttons.remove') }}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="dataForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize"
        :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <el-dialog title="" class="loading_dialog" :visible.sync="exportloading" :close-on-click-modal="false"
      :show-close="true" @close="closeimport" width="500px" center>
      <div>
        <div style="
            color: #000000;
            margin-bottom: 30px;
            margin-top: -30px;
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-font-weight: blod;
          ">
          {{ importTitle }}
        </div>
        <div style="
            color: #999999;
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
          " v-if="progress != 100 && importTitle != '导出失败！' && hideProgress">
          关闭弹窗后导出任务将会终止！
        </div>
        <el-progress v-if="hideProgress" :text-inside="true" :percentage="progress" :stroke-width="20"
          :indeterminate="true" :color="importTitle == '导出失败！' ? 'red' : '#5A5FFF'"></el-progress>
        <div style="
            margin-top: 30px;
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
            color: #337ab7;
          " v-if="progress == 100">
          <span style="color: #000000">点击链接下载：</span><a :href="exportUrl">{{ exportUrl }}</a>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <view-data v-if="viewdataVisible" ref="viewdata"></view-data>
    <plab-view-data v-if="plabViewdataVisible" ref="PlabViewData"></plab-view-data>
    <print-preview v-if="printPreviewVisible" ref="printPreview" @refreshDataList="getDataList"></print-preview>
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
  </div>
</template>

<script>
import {
  exportProductApi,
  getExportProgressApi, getErpDataApi
} from '@/api/modules/product'
import { queryAttrValueBykeyApi } from "@/api/modules/attrValue";
import { searchColorSampleApi, deleteColorSampleApi, controlShowHideApi } from '@/api/modules/colorSample'
import { selectChildAttr } from '@/api/modules/productAttributeValue'
import AddOrUpdate from './add-or-update'
import ViewData from './view-data'
import PlabViewData from './plab-view-data'
import PrintPreview from './printingPreview'
import Upload from './oss-upload'
import { getToken } from '@/utils/auth'
import SearchForm from '@/components/form.vue'
import { setDeviceState } from '@/api/modules/device'
import VueQr from 'vue-qr'
export default {
  data() {
    return {
      stncStatus: false,
      pageFlag: true,
      hideProgress: true, //控制导出进度条是否展示
      taskName: '',
      progress: 0,
      exportUrl: '',
      importTitle: '正在导出中...',
      websock: null,
      collapseState: true,
      cloudLoading: false,
      isdisabled: false,
      exportloading: false,
      yarnPatternList: [],
      compositionList: [],
      colorSampleShapeList: [],
      productionProcessList: [],
      colorDifferenceFormulaList: [],
      yarnSpecificationList: [],

      device: {
        deviceId: ''
      },
      deviceBootShow: false,
      deviceList: [],
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timer_: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      is_query: false,
      dataForm: {
        pageNo: 1,
        pageSize: 10,
        composition: undefined,
        recipeNo: undefined,
        colorMatchNumber: undefined,
        colorSampleName: undefined,
        isIntegratingSphere: undefined,
        productNo: undefined,
        hasStock: "-1",
        yarnType: undefined,
        yarnSpecification: undefined,
        colorMatchType: undefined,
        productCategory: undefined,
        productionProcess: undefined,
        state: '0',
        createTimeFrom: undefined,
        createTimeTo: undefined,
        isDisplay: "-1",
        orderNumber: undefined,
        colorCode: undefined

      },
      dataInfo: {
        name: '',
        info: '',
        alias: '',
        type: ''
      },
      searchForm: [],
      searchData: {},
      notIncluded: [],
      tableCols: [],
      createDates: [],
      resultList: [],
      industryList: [],
      dataList: [],
      totalPage: 0,
      dataListLoading: false,
      loading: false,
      loading2: false,
      addOrUpdateVisible: false,
      uploadVisible: false,
      printPreviewVisible: false,
      yarnTypeList: [],
      colorMatchTypeList: [],
      productCategoryList: [],
      brandState: false,
      factoryState: false,
      viewdataVisible: false,
      plabViewdataVisible: false
    }
  },
  components: {
    AddOrUpdate,
    Upload,
    PrintPreview,
    ViewData,
    PlabViewData,
    VueQr,
    DeviceBoot: () => import('@/components/DeviceBoot')
  },
  props: {
    addBtn: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  created() {
    console.log('pageFlag', this.pageFlag);
    this.device.deviceId = this.$store.getters.userDevice.id
    // this.getAttrTableCols()
    this.selectYarnPatternList()
    this.selectYarnSpecificationList()
    this.selectCompositionList()
    this.selectColorMatchTypeList()
    this.selectProductionProcessList()
    this.selectProductCategoryList()
  },
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
  },
  //写个过滤器，保留小数点后两位
  filters: {
    numFilter(value) {
      let realVal = ''
      if (!isNaN(value) && value !== '' && value !== null) {
        // 截取当前数据到小数点后两位
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    }
  },

  methods: {
    getButtonText(display) {
      return display === '1' ? this.$t('common.buttons.hide') : this.$t('common.buttons.show');
    },
    change(e) {
      console.log(e);
    },
    // 纱线形态
    selectYarnPatternList(query) {
      // 当输入框内容变化时，实时调用后台接口
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'yarnType',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.yarnTypeList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    selectYarnChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectYarnPatternList('');
      }
    },
    // 纱线规格

    selectYarnSpecificationList(query) {
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'yarnSpecification',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.yarnSpecificationList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    selectYarnSpecificationChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectYarnSpecificationList('');
      }
    },
    // 成份
    selectCompositionList(query) {

      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'composition',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.compositionList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    selectCompositionChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectCompositionList('');
      }
    },
    // 打色样品形态
    selectColorMatchTypeList(query) {

      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'colorMatchType',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.colorMatchTypeList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    selectColorMatchTypeChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectColorMatchTypeList('');
      }
    },
    //  产品类别
    selectProductCategoryList(query) {

      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'productCategory',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.productCategoryList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });

    },
    selectProductCategoryChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectProductCategoryList('');
      }
    },

    controlShowHide(item) {
      const data = {
        id: item.id,
        display: item.display
      }
      controlShowHideApi(data).then(() => {
        this.$message({
          message: this.$t('physicalColors.prompt.action'),
          type: 'success',
        });
        this.getAttrTableCols()
      })

    },

    //  生产工序

    selectProductionProcessList(query) {

      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: 'productionProcess',
      };
      queryAttrValueBykeyApi(data).then((res) => {
        this.productionProcessList = res;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    selectProductionProcessChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectProductionProcessList('');
      }
    },
    // 搜索条件展开收起事件
    handleCollapse() {
      const $dom = document.getElementsByClassName('el-card__body')[0]
      if (this.collapseState) {
        $dom.style.height = 'auto'
        $dom.style.overflow = ''
        this.collapseState = false
      } else {
        $dom.style.height = '70px'
        $dom.style.overflow = 'hidden'
        this.collapseState = true
      }
    },
    //重新查询
    restPage() {
      this.dataForm.pageNo = 1
      this.getAttrTableCols()
    },
    reset() {
      this.$refs.dataForm.resetFields()
      this.dataForm.yarnType = '';
      this.dataForm.yarnSpecification = '';
      this.dataForm.composition = '';
      this.dataForm.colorMatchType = '';
      this.dataForm.productCategory = '';
      this.dataForm.productionProcess = '';
      this.dataForm.hasStock = '-1';
      this.createDates = []
      if (this.searchData) {
        for (var i in this.searchData) {
          this.$set(this.searchData, i, {})
        }
        this.searchData = {}
      }
      this.notIncluded = []
      this.restPage()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.dataList = []
      searchColorSampleApi(this.dataForm)
        .then(({ records, totalRecords }) => {
          if (totalRecords > 0) {
            records.forEach((item) => {
              if (item.searchData) {
                Object.keys(item.searchData).forEach((key) => {
                  item[key] = item.searchData[key]
                })
              }
            })
            this.dataList = records
            this.totalPage = totalRecords
          } else {
            this.dataList = []
            this.totalPage = 0
          }

        })
        .finally(() => {
          this.dataListLoading = false
        })
    },

    // 获取列表数据
    getAttrTableCols: function () {
      this.getDataList()
    },
    clearTimer() {
      //清除定时器
      window.clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    getprogress() {
      var params = {
        taskName: this.taskName
      }
      getExportProgressApi(params).then((response) => {
        this.progress = response.speedOofProgress
        if (response.isSuccess) {
          this.exportUrl = response.url
          this.importTitle = '导出成功！'
          window.clearInterval(this.timer_)
          this.timer_ = null
        } else if (response.isSuccess) {
          this.importTitle = '导出失败！' + response.errorMessage
          window.clearInterval(this.timer_)
          this.timer_ = null
        }
      })
    },
    closeimport() {
      window.clearInterval(this.timer_)
      this.timer_ = null
      this.exportloading = false
        ; (this.progress = 0),
          setTimeout(() => {
            this.importTitle = '正在导出中...'
          }, 500)
    },
    //导出产品数据到excle
    exportExcelProduct() {
      this.hideProgress = true
      this.setSearchData()
      exportProductApi(this.dataForm).then((response) => {
        this.taskName = response
        this.progress = 0
        this.exportloading = true
        this.timer_ = setInterval(this.getprogress, 3000)
      })
    },
    //导出excel表格模板
    exportExcelHandle() {
      var url =
        `${process.env.VUE_APP_BASE_API}/product/product/exportExcel` +
        `?token=${getToken()}`
      // 模拟创建元素实现
      var alink = document.createElement('a')
      alink.href = url
      alink.style = 'display:none'
      alink.click()
    },
    // 上传文件
    uploadHandle() {
      this.uploadVisible = true
      this.$nextTick(() => {
        this.$refs.upload.init(this.dataForm.state)
      })
    },
    synchronizeInventoryStatus() {
      this.stncStatus = true
      getErpDataApi().then((data) => {
        if (data) {
          this.stncStatus = false
          this.$notify({
            title: this.$t('sys.notifcations.success'),
            message: this.$t('tips.message.successfullu'),
            type: 'success'
          });
        } else {
          this.stncStatus = false
          this.$notify({
            title: this.$t('sys.notifcations.failure'),
            message: this.$t('tips.message.failed'),
            type: 'error'
          });
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageNo = 1
      this.getAttrTableCols()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getAttrTableCols()
    },
    // 新增 / 修改
    addOrUpdateHandle(id, isCopy) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, isCopy, this.state)
      })
    },
    viewdata(id) {
      if (this.dataForm.state == '1') {
        this.viewdataVisible = true
        this.$nextTick(() => {
          this.$refs.viewdata.init({
            id,
            illuminant: '10 deg',
            observerAngle: 'D65'
          })
        })
      } else {
        this.plabViewdataVisible = true
        this.$nextTick(() => {
          this.$refs.PlabViewData.init({
            id,
            illuminant: '10 deg',
            observerAngle: 'D65'
          })
        })
      }

    },
    cpdetailsHandle(item) {
      const { id, brandId } = item
      if (this.tabsActiveName === 'first') {
        this.cpdetailsVisible = true
        this.$nextTick(() => {
          this.$refs.cpdetails.init({
            id,
            illuminant: this.illuminant,
            observerAngle: this.observerAngle,
            labL: this.Lvaule,
            labA: this.Avaule,
            labB: this.Bvaule,
            colorDifferenceFormula: this.colorDifferenceFormula,

          });
        });
      } else {
        this.sampleDetailsVisible = true
        // seyang
        this.$nextTick(() => {
          this.$refs.sampleDetails.init({
            id,
            illuminant: this.illuminant,
            observerAngle: this.observerAngle,
            labL: this.Lvaule,
            labA: this.Avaule,
            labB: this.Bvaule,
            colorDifferenceFormula: this.colorDifferenceFormula,
          });
        });
      }

    },
    // 打印
    printPreviewHandle(row) {
      this.printPreviewVisible = true
      this.$nextTick(() => {
        this.$refs.printPreview.init(row)
      })
    },
    selectChildAttr() {
      let params = {
        attrValueId: this.dataForm.industryId
      }
      selectChildAttr(params).then(({ data }) => {
        if (data && data.code === 0) {
          this.industryList_child = data.list
        } else {
          this.industryList_child = []
        }
        this.dataForm.industryId_child = ''
      })
    },

    setDeviceState() {
      let params = {
        deviceId: this.$store.getters.userDevice.id
      }
      setDeviceState(params).then(({ data }) => { })
    },
    // 删除
    deleteDataHandle(id) {
      this.$confirm(this.$t('common.buttons.remove.tips'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.buttons.confirm'),
        cancelButtonText: this.$t('common.buttons.cancel'),
        type: 'warning'
      }).then(() => {
        deleteColorSampleApi(id).then(() => {
          this.$baseMessage(this.$t('common.tips.deletedSuccessfully'), "success");
          this.getAttrTableCols()
        })
      })
    },
  },
  watch: {
    $route: {
      handler(newValue) {

        const { name } = newValue
        if (name === 'product/product') {
          this.pageFlag = true
          this.dataForm.state = '0'
          console.log(this.dataForm.state, "0")

        } else {

          this.pageFlag = false
          this.dataForm.state = '1'
          console.log(this.dataForm.state, "1")
        }
        this.getDataList()
      },
      immediate: true

    }
  },
  mounted() {

    const params = this.$route.query.params
    if (params === 'proAdd') {
      setTimeout(() => {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(undefined)
        })
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  background: #eaf1fc;
}

.w100 {
  width: 100px;
}

.mr2 {
  margin-right: 2px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.el-date-editor--daterange.el-input__inner {
  width: 250px;
}

.loading_dialog {
  margin-top: 200px;
}
</style>
<style lang="scss">
.box-pro {
  .el-card__body {
    position: relative;
    //height: max-content;
    overflow: hidden;
    height: 70px;
  }

  .el-form-item__content {
    width: 130px;
  }

  .el-form-item.lab {
    .el-form-item__content {
      width: auto !important;
    }
  }

  .el-form-item__label {
    font-weight: 400 !important;
    min-width: 120px !important;
    width: auto !important;
  }

  .el-form--inline .el-form-item {
    margin-right: 10px;
  }
}
</style>
