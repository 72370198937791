import request from '@/utils/request'

// 获取列表
export function list(params) {
  return request({
    url: '/sample/sampleorder/list',
    method: 'get',
    params: params
  })
}

export function UserList(params) {
  return request({
    url: '/sample/sampleorder/getUser',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info(params) {
  return request({
    url: '/sample/sampleorder/info',
    method: 'get',
    params: params
  })
}
// 获取信息
export function getPicUrl(params) {
  return request({
    url: '/sample/sampleorder/getPicUrl',
    method: 'get',
    params: params
  })
}

// 获取信息
export function infoDetail(params) {
  return request({
    url: '/sample/sampleorder/infoDetail',
    method: 'get',
    params: params
  })
}

// 添加
export function add(params) {
  return request({
    url: '/sample/sampleorder/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update(params) {
  return request({
    url: '/sample/sampleorder/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del(params) {
  return request({
    url: '/sample/sampleorder/delete',
    method: 'post',
    data: params
  })
}
// 获取列表
export function sampleorderlist(params) {
  return request({
    url: '/sample/sampleorder/list',
    method: 'get',
    params: params
  })
}

// 通过产品号获取产品具体信息
export function productInfo(params) {
  return request({
    url: '/sample/sampleorder/productInfo',
    method: 'get',
    params: params
  })
}

//获取信息
export function getSampleInfo(params) {
  return request({
    url: '/sample/sampleorder/getSampleInfo',
    method: 'get',
    params: params
  })
}

export function getProductPerformanceS(params) {
  return request({
    url: '/sample/sampleorder/getProductPerformance',
    method: 'get',
    params: params
  })
}

export function orderLogisticsInfo(params) {
  return request({
    url: '/sample/sampleorder/orderLogisticsInfo',
    method: 'get',
    params: params
  })
}
//查色功能
export function checkColor(params) {
  return request({
    url: '/sample/sampleorder/checkColor',
    method: 'get',
    params: params
  })
}

export function syncLogistics(params) {
  return request({
    url: '/sample/sampleorder/syncLogistics',
    method: 'post',
    data: params
  })
}

export function getSampleUserInfo(params) {
  return request({
    url: '/sample/sampleorder/getSampleUser',
    method: 'get',
    params: params
  })
}
export function updateSampleUser(params) {
  return request({
    url: '/sample/sampleorder/updateSampleUser',
    method: 'post',
    data: params
  })
}

export function querySampleCount(params) {
  return request({
    url: '/sample/sampleorder/querySampleCount',
    method: 'get',
    params: params
  })
}

export function getMakeOrderCount(params) {
  return request({
    url: '/sample/sampleorder/getMakeOrderCount',
    method: 'get',
    params: params
  })
}
// export function exportSampleOrder(form){
//   return request({ // 用axios发送post请求
//       method: 'post',
//       // url: '/sample/sampleorder/exportSampleOrder', // 请求地址
//       url: '/sample/sampleorder/exportSampleOrderInfo', // 请求地址
//       data: form, // 参数
//       responseType: 'blob', // 表明返回服务器返回的数据类型
//       headers: {
//           'Content-Type': 'application/json'
//       }
//   })
// }

export function exportSampleOrder(params) {
  return request({
    url: '/sample/sampleorder/exportSampleRecipes',
    method: 'post',
    data: params
  })
}

export function importExcel(params) {
  return request({
    url: '/sample/sampleorder/importSampleOrderExcel',
    method: 'post',
    data: params
  })
}
