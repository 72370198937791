<template>
  <el-dialog
    :title="'打印产品信息'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="500px"
    @close="close()"
  >
    <div id="printarea" ref="print">
      <table
        border="1"
        width="90%"
        frame="void"
        rules="none"
        style="margin: 5%"
      >
        <tr>
          <td style="width: 40%">
            <vue-qr :text="dataForm.productNo" :size="210" />
          </td>
          <td style="width: 60%">
            <span style="margin-left: 5px; font-size: 30px">{{
              "No:" + dataForm.productNo
            }}</span
            ><br />
            <span style="margin-left: 5px; font-size: 25px">{{
              "L*:" + dataForm.l
            }}</span
            ><br />
            <span style="margin-left: 5px; font-size: 25px">{{
              "a*:" + dataForm.a
            }}</span
            ><br />
            <span style="margin-left: 5px; font-size: 25px">{{
              "b*:" + dataForm.b
            }}</span>
          </td>
        </tr>
      </table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="printSub()"
        size="small"
        :disabled="dataLoading"
        >打印</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import VueQr from "vue-qr";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      showBtn: false,
      dataForm: {
        id: 0,
        productNo: "",
        productName: "",
        qrUrl: "",
        lAve: "",
        aAve: "",
        bAve: "",
      },
    };
  },
  components: {
    VueQr,
  },
  methods: {
    close() {
      this.visible = false;
    },
    init(row) {
      this.dataForm = row;
      this.visible = true;
    },
    printSub() {
      document.body.innerHTML = document.getElementById("printarea").innerHTML;
      window.print();
      setTimeout("window.close();", 0);
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.productDirs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  .left {
    margin-left: 30px;
  }
  .right {
    margin-left: 5px;
    margin-right: 30px;
  }
}
.fontNormal {
  font-weight: normal;
}
</style>
