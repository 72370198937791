import request from '@/utils/request'

const url = 'c/userDevice/'
const deviceUrl = 'c/device/'

// 获取用户授权的设备列表
export function getUserDeviceListApi() {
  return request({
    url: `${url}getDeviceList`,
    method: 'get'
  })
}

// 获取列表
export function queryDeviceUserApi(data) {
  return request({
    url: `${url}query`,
    method: 'post',
    data: data
  })
}

// 添加
export function addUserDeviceApi(data) {
  return request({
    url: `${url}add`,
    method: 'put',
    data: data
  })
}

//设备授权的设备信息
export function getDeviceInfoApi(id) {
  return request({
    url: `${deviceUrl}getInfo/${id}`,
    method: 'get'
  })
}

//设备详细信息
export function getDeviceFactoryInfoApi(id) {
  return request({
    url: `${deviceUrl}getFactoryInfo/${id}`,
    method: 'get'
  })
}

//撤销授权
export function deleteUserDeviceApi(id) {
  return request({
    url: `${url}delete/${id}`,
    method: 'delete'
  })
}

export function getDeviceOverviewApi() {
  return request({
    url: `${url}getDeviceOverview`,
    method: 'get'
  })
}
