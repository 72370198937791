<template>
  <el-dialog :title="!dataForm.id ? $t('common.buttons.createNew') : $t('common.buttons.edit')"
    :close-on-click-modal="false" :visible.sync="visible" width="500px">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="155px" v-loading="dataLoading" style="width: 100%" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item class="attr-value-1" :label="$t('physicalColors.attributes.attr')" prop="attrName">
        <el-select v-model="dataForm.attrId" :placeholder="$t('physicalColors.prompt.selectAttr')">
          <el-option v-for="item in attrList" :key="item.id" :label="item.attrName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="attr-value" :label="$t('physicalColors.attributes.code')" prop="attrValueCode">
        <el-input v-model="dataForm.attrValueCode" :placeholder="$t('physicalColors.attributes.code')"></el-input>
      </el-form-item>
      <el-form-item class="attr-value" :label="$t('electronicColors.common.name')" prop="attrValueName">
        <el-input v-model="dataForm.attrValueName" :placeholder="$t('electronicColors.common.name')"></el-input>
      </el-form-item>

      <el-form-item class="attr-value" :label="$t('common.table.comment')" prop="remarks">
        <el-input v-model="dataForm.remarks" type="textarea" :placeholder="$t('common.table.comment')" maxlength="100"
          show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getTypeDictInfoApi } from "@/api/modules/attr";
import { addOrUpdateAttrValueApi, getAttrValueApi } from "@/api/modules/attrValue";
export default {
  data() {
    return {
      visible: false,
      dataLoading: false,
      attrList: [],
      dataForm: {
        id: undefined,
        attrValueCode: "",
        attrName: "",
        attrValueName: "",
        attrId: "",
        remarks: "",
      },
      dataRule: {
        attrId: [
          { required: true, message: this.$t('physicalColors.prompt.associatedAttributes'), trigger: "blur" },
        ],
        attrValueCode: [
          { required: true, message: this.$t('physicalColors.prompt.code'), trigger: "blur" },
        ],
        attrValueName: [
          { required: true, message: this.$t('physicalColors.prompt.name'), trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getDictInfo();
  },
  methods: {

    getDictInfo() {
      getTypeDictInfoApi().then((res) => {
        this.attrList = res
      });
    },
    init(id, attrName) {
      this.dataForm.id = id || undefined;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          getAttrValueApi(id).then((response) => {
            this.dataForm = response;
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          addOrUpdateAttrValueApi(this.dataForm)
            .then(() => {
              this.visible = false;
              this.$emit("refreshDataList");
              this.$baseMessage("操作成功", "success");
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
