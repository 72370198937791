import request from '@/utils/request'

const url = '/c/factory/'

/**
 * 获取当前登录人的工厂信息
 * @returns
 */
export function getFactoryListApi() {
  return request({
    url: `${url}getList`,
    method: 'get'
  })
}

export function getFactoryOverviewApi(params) {
  return request({
    url: `${url}getOverview`,
    method: 'get',
    params
  })
}
