import request from '@/utils/request'

const url = '/c/device'

const deviceModelUrl = 'c/deviceModel'

/**
 * 获取用户列表信息::
 * @returns
 */
export function queryDeviceApi(data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

//型号下拉列表
export function getModelListApi() {
  return request({
    url: `${deviceModelUrl}/modelList`,
    method: 'get'
  })
}

/**
 * 检查设备状态
 * @param {*} id
 * @returns
 */
export function syncDeviceApi(id) {
  return request({
    url: `${url}/syncDevice/${id}`,
    method: 'post'
  })
}

/**
 * 检查设备状态
 * @param {*} id
 * @returns
 */
export function checkDeviceStateApi(id) {
  return request({
    url: `${url}/checkDeviceState/${id}`,
    method: 'post'
  })
}

/**
 * 检查设备状态
 * @param {*} id
 * @returns
 */
export function confirmSelectDeviceApi(id) {
  return request({
    url: `${url}/confirmSelectDevice/${id}`,
    method: 'post'
  })
}

/**
 * 断开连接
 * @param {*} params
 * @returns
 */
export function breakConnectDeviceApi(id) {
  return request({
    url: `${url}/breakConnectDevice/${id}`,
    method: 'post'
  })
}
