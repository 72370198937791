<template>
  <el-dialog :title="$t('accounts.common.changePassword.title')" :close-on-click-modal="false" :visible.sync="visible"
    width="700px">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="150px" v-loading="dataLoading" style="width: 100%" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item :label="$t('accounts.common.username')" prop="userName">
        <el-input v-model="dataForm.userName" :placeholder="$t('accounts.common.username')" disabled
          style="width: 95%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.changePassword.currentPassword')" prop="userPwd">
        <el-input v-model="dataForm.userPwd" type="password" :placeholder="$t('accounts.common.password')"
          maxlength="30" style="width: 95%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('login.resetPassword.newPassword')" prop="newPassword">
        <el-input v-model="dataForm.newPassword" type="password" :placeholder="$t('login.resetPassword.newPassword')"
          maxlength="30" style="width: 95%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.confirmPassword')" prop="comfirmPassword">
        <el-input v-model="dataForm.comfirmPassword" type="password"
          :placeholder="$t('accounts.common.confirmPassword')" maxlength="30" style="width: 95%"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateUserPwdApi } from "@/api/modules/user";
import { cryptoAes } from "@/utils/index";

export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 30) {
        callback(new Error(this.$t('login.resetPassword.passwordRequirements')));
      } else {
        callback();
      }
    };
    var validateNewPassword = (rule, value, callback) => {
      if (value.length < 6 || value.length > 30) {
        callback(new Error(this.$t('login.resetPassword.newPasswordRequirements')));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !/\S/.test(value)) {
        callback(new Error(this.$t('login.resetPassword.confirmPasswordNotEmpty')));
      } else if (this.dataForm.newPassword !== value) {
        callback(new Error(this.$t('login.resetPassword.newPasswordDifferent')));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataLoading: false,
      dataForm: {
        id: undefined,
        userName: "",
        userPwd: "",
        newPassword: "",
        comfirmPassword: "",
      },
      dataRule: {
        userPwd: [
          { required: true, message: this.$t('login.resetPassword.passwordNotEmpty'), trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: this.$t('lgin.resetPassword.newPasswordNotEmpty'), trigger: "blur" },
          { validator: validateNewPassword, required: true, trigger: "blur" },
        ],
        comfirmPassword: [
          { required: true, message: this.$t('login.resetPassword.confirmPasswordNotEmpty'), trigger: "blur" },
          {
            validator: validateComfirmPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    init(row) {
      this.dataForm.id = row.id;
      this.visible = true;
      this.$nextTick(() => {
        console.log(this.$refs["dataForm"])
        this.$refs["dataForm"].resetFields();
        this.dataForm.id = row.id;
        this.dataForm.userName = row.userName;
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          let params = {
            id: this.dataForm.id,
            userPwd: cryptoAes(this.dataForm.userPwd),
            newUserPwd: cryptoAes(this.dataForm.comfirmPassword),
          };
          updateUserPwdApi(params)
            .then(() => {
              this.$baseMessage(this.$t('sys.notifcations.success'), "success");
              this.visible = false;
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },
  },
};
</script>
