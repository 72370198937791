<template>
    <div class="app-container">
        <el-form :inline="true" size="small" :model="dataForm" ref="dataForm" @keyup.enter.native="restPage()">
            <el-form-item prop="attrCode" :label="$t('physicalColors.attributes.code')">
                <el-input v-model="dataForm.attrCode" :placeholder="$t('physicalColors.attributes.code')" size="small"
                    clearable></el-input>
            </el-form-item>
            <el-form-item prop="remarks" :label="$t('common.table.comment')">
                <el-input v-model="dataForm.remarks" :placeholder="$t('common.table.comment')" size="small"
                    clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="restPage()" size="small">{{ $t('common.buttons.search') }}</el-button>
                <el-button @click="reset('dataForm')" size="small">{{ $t('common.buttons.reset') }}</el-button>
                <el-button type="primary" @click="addOrUpdateHandle()" size="small">{{ $t('common.buttons.createNew')
                    }}</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border size="small" v-loading="dataListLoading"
            @selection-change="selectionChangeHandle" style="width: 100%">
            <!-- <el-table-column prop="id" header-align="center" align="center" label="id" width="150">
        </el-table-column> -->
            <el-table-column prop="attrCode" header-align="center" align="center"
                :label="$t('physicalColors.attributes.code')">
            </el-table-column>
            <el-table-column prop="attrName" header-align="center" align="center"
                :label="$t('electronicColors.common.name')">
            </el-table-column>
            <el-table-column prop="remarks" header-align="center" align="center" :label="$t('common.table.comment')">
            </el-table-column>
            <!-- <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
                </template>
</el-table-column> -->
        </el-table>
        <el-pagination size="small" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
            :current-page="dataForm.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize"
            :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
import { queryAttrApi } from "@/api/modules/attr";
import AddOrUpdate from "./add-or-update";
export default {
    data() {
        return {
            dataForm: {
                attrCode: "",
                remarks: "",
                pageNo: 1,
                pageSize: 10,
            },
            dataList: [],
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
        };
    },
    components: {
        AddOrUpdate
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        //重新查询
        restPage() {
            this.dataForm.pageNo = 1;
            this.getDataList();
        },
        reset(dataForm) {
            this.$refs.dataForm.resetFields();
            this.restPage();
        },

        // 每页数
        sizeChangeHandle(val) {
            this.dataForm.pageSize = val;
            this.dataForm.pageNo = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.dataForm.pageNo = val;
            this.getDataList();
        },

        getDataList() {
            this.dataListLoading = true;
            queryAttrApi(this.dataForm).then(
                ({ records, totalRecords }) => {
                    if (totalRecords > 0) {
                        this.dataList = records;
                        this.totalPage = totalRecords;
                    } else {
                        this.dataList = [];
                        this.totalPage = 0;
                    }
                    this.dataListLoading = false;
                }
            );
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            });
        },
        // 删除

    },
};
</script>